import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private exportXml = new BehaviorSubject<string>('');
  public exportXml$ = this.exportXml.asObservable();

  private importXml = new BehaviorSubject<string>('');
  public importXml$ = this.importXml.asObservable();

  constructor() { }

  import(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.updateImportXml(reader.result.toString());
    }
    reader.readAsText(file);
  }

  export() {
    this.exportXml$
      .pipe(
        first()
      )
      .subscribe(xml => {
        let input_value = document.getElementById('input_value') as HTMLInputElement
        let example_name = input_value.value || input_value.placeholder
        const blob = new Blob([xml], { type: 'text/plain' });
        saveAs(blob, `${example_name}.xml`);
      });
  }

  updateExportXml(xml: string) {
    this.exportXml.next(xml);
  }

  updateImportXml(xml: string) {
    this.importXml.next(xml);
  }
}
