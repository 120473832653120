import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { js } from 'js-beautify';
import { Example } from './code.const';
import { LogService } from '../log/log.service';

@Injectable({
  providedIn: 'root'
})
export class CodeService {
  private code = new BehaviorSubject<string>('');
  code$ = this.code.asObservable();

  private runCode = new BehaviorSubject<boolean>(false);
  runCode$ = this.runCode.asObservable();

  private onGoing = new BehaviorSubject<boolean>(false);
  onGoing$ = this.onGoing.asObservable();

  private syntaxError = new BehaviorSubject<string>('');
  syntaxError$ = this.syntaxError.asObservable();

  private example = new Subject<Example>();
  example$ = this.example.asObservable();

  private modal = new BehaviorSubject<boolean>(false);
  modal$ = this.modal.asObservable()

  constructor(
    private logService: LogService
  ) {
    this.changeCode('');
  }

  changeCode(code: string) {
    this.logService.clearLog();
    const options = {
      indent_size: '2',
    };
    this.code.next(js(code, options));
  }

  async executeCode(code?: string) {
    code = code ?? this.code.value;
    this.changeRunCode(true);
    try {
      await eval(`(async () => {${code}}) ()`);
    } catch (error) {
      this.logService.pushLog(error.message, 'ERROR');
      this.stopExecution();
      throw error;
    }
    this.changeRunCode(false);
  }

  changeSyntaxError(syntaxError: string) {
    this.syntaxError.next(syntaxError);
  }

  changeRunCode(runCode: boolean) {
    if (this.runCode.value !== runCode) {
      this.runCode.next(runCode);
    }
  }

  changeOnGoing(onGoing: boolean) {
    this.onGoing.next(onGoing);
  }

  changeExample(example: Example) {
    this.example.next(example);
  }

  stopExecution() {
    this.changeRunCode(false);
    this.changeOnGoing(false);
  }
}
