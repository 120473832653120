import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from './dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    public dialog: MatDialog,
  ) { }

  showError(error) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '300px',
      data: {
        loremipsum: error,
        actions: {
          ok: true,
        },
      },
    });
  }
}
