import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { first } from 'rxjs/operators';


@Injectable({
    providedIn:'root'
})
export class userInfo{
    // userInfo = 
    constructor(){}
}