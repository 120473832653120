import { outdent } from 'outdent';

export const FaceapiImage: string = outdent`
<xml xmlns="https://developers.google.com/blockly/xml">
   <block type="faceapi_load_face_detector" id="ZXyX7.jp%ImVKBti_M,b" x="132" y="13">
      <mutation xmlns="http://www.w3.org/1999/xhtml" face_detector="ssdMobilenetv1"></mutation>
      <field name="FACE_DETECTOR">ssdMobilenetv1</field>
      <field name="maxResults">100</field>
      <field name="minConfidence">0.5</field>
   </block>
   <block type="faceapi_load_net" id="Hj{Eg/Wj-x[}vxTnOqYx" x="130" y="104">
      <field name="ageGenderNet">FALSE</field>
      <field name="faceExpressionNet">FALSE</field>
      <field name="faceLandmark68Net">FALSE</field>
      <field name="faceLandmark68TinyNet">FALSE</field>
      <field name="faceRecognitionNet">FALSE</field>
      <next>
         <block type="interaction_input" id="zN?,)_^t=+4~huSA[IU{">
            <mutation xmlns="http://www.w3.org/1999/xhtml" image_uri="true"></mutation>
            <field name="SOURCE">image</field>
            <field name="image_uri">https://vignette.wikia.nocookie.net/harrypotter/images/7/70/Harry_Potter_and_the_Order_of_the_Phoenix.jpg</field>
            <statement name="DO">
               <block type="faceapi_detect_face" id="vwa|%Y/@ceHzvVx^3V*E">
                  <field name="DETECT_TYPE">detectAllFaces</field>
                  <field name="faceLandmarks">FALSE</field>
                  <field name="faceExpressions">FALSE</field>
                  <field name="ageAndGender">FALSE</field>
                  <next>
                     <block type="faceapi_draw_detection" id="u([8wGsE}-0%~RgB2FSf">
                        <field name="faceLandmarks">FALSE</field>
                        <field name="faceExpressions">FALSE</field>
                        <field name="ageAndGender">FALSE</field>
                     </block>
                  </next>
               </block>
            </statement>
         </block>
      </next>
   </block>
</xml>
`;