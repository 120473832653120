import * as Blockly from 'blockly/core';
import { CustomBlock } from '../custom-block';
import outdent from 'outdent';

export class PoseCountBlock implements CustomBlock {
  type = 'pose_count';

  defineBlock(block) {
    const blockJson = {
      style: 'pose_detection_blocks',
      message0: '%{BKY_POSE_DETECTION_POSE_COUNT_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'DETECTION',
          variable: 'poseDetection',
        },
      ],
      output: 'Number',
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const poseDetection = block.getField('DETECTION').getVariable().name;
    const code = `${poseDetection}.length`;
    return [code, Blockly.JavaScript.ORDER_NONE];
  }
}
