import { CustomBlock } from '../custom-block';
import { Block } from 'blockly';
import { outdent } from 'outdent';

declare var window: any;

export const TrainKnnBlockType = 'tfjs_train_knn';

export class TrainKnnBlock implements CustomBlock {
  type = TrainKnnBlockType;

  defineBlock(block) {
    let options = window.customDatasets?.filter(({ title, key }) => title && key)
      .map(({ title, key }) => ([title, key]));

    if (!options || options.length === 0) {
      options = [['None', 'None']];
    }

    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_DATASET_TRAIN_KNN_TITLE}',
      args0: [
        {
          type: 'field_dropdown',
          name: 'KEY',
          options: options
        }
      ],

      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Block) {
    const datasetKey = block.getFieldValue('KEY');
    return outdent`let knn = knnModule.create();
    let mobilenet = await mobilenetModule.load();
    const infer = (image) => mobilenet.infer(image, 'conv_preds');
    const dataset = customDatasets.find(set => set.key == '${datasetKey}');

    for (let {imageType, imageData, imageURL, label} of dataset.data) {
      if ((imageType == ai.DatumImageType.URL && imageURL) || (imageType != ai.DatumImageType.URL && imageData)) {
        const img = await new Promise(resolve => {
          const img = document.createElement('IMG');
          img.src = (imageType == ai.DatumImageType.URL) ? imageURL : imageData;
          img.onload = () => {
            resolve(img);
          };
        });
        const pixels = tf.browser.fromPixels(img);
        const logits = infer(pixels);
        knn.addExample(logits, label);
        pixels.dispose();
        logits.dispose();
      }
    }
    `;
  }
}
