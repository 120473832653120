/* This file is based off face-api/input.ts */

import { CustomBlock } from '../custom-block';
import outdent from 'outdent';

import * as Blockly from 'blockly/core';

export class ObjectDetectionBlock implements CustomBlock {
  type = 'object_detection';

  defineBlock(block) {
    const blockJson = {
      style: 'object_detection_blocks',
      message0: '%{BKY_OBJECT_DETECTION_DETECT_OBJECT_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'DETECTION',
          variable: 'objectDetection'
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };
    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const detection = block.getField('DETECTION').getVariable().name;
    return `
      ${detection} = await ai.object.detect(model, input);
    `;
  }
}
