import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule, TranslateLoader, MissingTranslationHandlerParams, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FormsModule } from '@angular/forms';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCheck, faAngleRight, faAngleLeft, faAsterisk, faFileUpload, faGlobe, faPen, faCamera, faTrash, faChartBar, faEraser, faStop, faCopy, faExpand, faCompress, faPlay, faStopCircle, faExchangeAlt, faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons';

import { HighlightModule } from 'ngx-highlightjs';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';

import { CodeService } from './code/code.service';
import { StorageService } from './storage/storage.service';
import { BlocklyService } from './blockly/blockly.service';
import { DatasetService } from './dataset.service';

import { DialogComponent } from './dialog/dialog.component';
import { CanvasComponent } from './canvas/canvas.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }
}

@NgModule({
  declarations: [DialogComponent, CanvasComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    FontAwesomeModule,
    HighlightModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([]),
    HttpClientModule,
    TranslateModule.forRoot({
      useDefaultLang: false,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatMenuModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatTableModule,
    MatListModule,
    MatTabsModule,
    MatSliderModule,
    MatSortModule,

    HammerModule,
  ],
  providers: [
    CodeService,
    StorageService,
    BlocklyService,
    DatasetService,
  ],
  exports: [
    FlexLayoutModule,
    FormsModule,
    FontAwesomeModule,
    HighlightModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    TranslateModule,

    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatMenuModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatTableModule,
    MatListModule,
    MatTabsModule,
    MatSliderModule,
    MatSortModule,

    CanvasComponent,
  ],
  entryComponents: [DialogComponent,],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    const icons = [
      faCheck,
      faAngleRight,
      faAngleLeft,
      faAsterisk,
      faFileUpload,
      faGlobe,
      faPen,
      faCamera,
      faTrash,
      faChartBar,
      faEraser,
      faStop,
      faCopy,
      faExpand,
      faCompress,
      faPlay,
      faStopCircle,
      faExchangeAlt,
      faSearchPlus,
      faSearchMinus
    ];
    icons.map(icon => library.addIcons(icon));
  }
}
