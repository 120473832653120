import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum LogType {
  OUTPUT,
  INPUT,
  PROBABILITY,
}
interface LogEntry {
  message: any;
  isJSON?: boolean;
  level?: string;
  type: LogType;
}

const MAX_LOG_ENTRY = 30;

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private log = new BehaviorSubject<LogEntry[]>([]);
  log$ = this.log.asObservable();

  constructor() { }

  pushLog(message: any, level: string = 'LOG', isInput: boolean = false) {
    this.log.next(
      this.log.getValue()
      .concat([{
        message,
        level,
        type: isInput ? LogType.INPUT : LogType.OUTPUT,
        isJSON: typeof message === 'object',
      }])
      .slice(- MAX_LOG_ENTRY)
    );

    console.log(this.log.getValue());
  }

  pushLogProbability({array, labelKey, probKey}) {
    this.log.next(
      this.log.getValue()
        .concat([{
          message: array.map(item => ({
            label: item[labelKey],
            value: item[probKey],
          })),
          type: LogType.PROBABILITY
        }])
        .slice(- MAX_LOG_ENTRY)
    );
  }

  clearLog() {
    this.log.next([]);
  }
}
