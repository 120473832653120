import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CodeService } from '../../shared';

declare var window: any;

@Component({
  selector: 'app-input-upload',
  templateUrl: './input-upload.component.html',
  styleUrls: ['./input-upload.component.scss']
})
export class InputUploadComponent implements OnInit {

  filename = '';
  @ViewChild('file', {static: true}) fileInput: ElementRef;
  @ViewChild('preview', {static: true}) previewImg: ElementRef;

  constructor(
    private codeService: CodeService,
  ) { }

  ngOnInit(): void {
  }

  onChange() {
    if (this.fileInput.nativeElement.files.length > 0) {
      this.filename = this.fileInput.nativeElement.files[0].name;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.previewImg.nativeElement.src = e.target.result;
      }
      reader.readAsDataURL(this.fileInput.nativeElement.files[0]);
    }
  }

  onClick() {
    this.fileInput.nativeElement.click();
  }

  onDetectClick() {
    if (window.detection) {
      this.codeService.changeOnGoing(true);
      window.detection().then(() => {
        this.codeService.changeOnGoing(false);
      });
    }
  }

}
