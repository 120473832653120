import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly';
import { outdent } from 'outdent';

export class LoadIrisBlock implements CustomBlock {
  type = 'tfjs_load_iris';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_DATASET_LOAD_IRIS_MESSAGE_0}',
      message1: '%{BKY_TFJS_DATASET_LOAD_IRIS_MESSAGE_1}',
      args1: [
        {
          type: 'field_variable',
          name: 'FEATURES',
          variable: 'irisFeatures'
        },
      ],
      message2: '%{BKY_TFJS_DATASET_LOAD_IRIS_MESSAGE_2}',
      args2: [
        {
          type: 'field_variable',
          name: 'CLASS',
          variable: 'irisClass'
        },
      ],
      message3: '%{BKY_TFJS_DATASET_LOAD_IRIS_MESSAGE_3}',
      args3: [
        {
          type: 'field_variable',
          name: 'TEST_FEATURES',
          variable: 'irisFeaturesTest'
        },
      ],
      message4: '%{BKY_TFJS_DATASET_LOAD_IRIS_MESSAGE_4}',
      args4: [
        {
          type: 'field_variable',
          name: 'TEST_CLASS',
          variable: 'irisClassTest'
        },
      ],
      message5: '%{BKY_TFJS_DATASET_LOAD_IRIS_MESSAGE_5}',
      args5: [
        {
          type: 'field_variable',
          name: 'CONSTANT_CLASS',
          variable: 'irisConstantClass'
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    return outdent`
    [${block.getField('FEATURES').getText()}, ${block.getField('CLASS').getText()}, ${block.getField('TEST_FEATURES').getText()}, ${block.getField('TEST_CLASS').getText()}] = IrisData.getIrisData();
    ${block.getField('CONSTANT_CLASS').getText()} = IrisData.IRIS_CLASSES;
    `;
  }
}
