import { CustomBlock } from '../custom-block';
import outdent from 'outdent';

import * as Blockly from 'blockly/core';

export class InteractionInputBlock implements CustomBlock {
  type = 'interaction_input';
  
  defineBlock(block) {
    const sourceMixin = {
      mutationToDom() {
        const container = document.createElement('mutation');
        container.setAttribute('source', this.getFieldValue('SOURCE'));
        return container;
      },

      domToMutation(xml) {
        const source = xml.getAttribute('source');
        this.updateShape(source);
      },

      updateShape(source) {
        const sourceBlock = this;
        const defaultUri = 'https://vignette.wikia.nocookie.net/harrypotter/images/7/70/Harry_Potter_and_the_Order_of_the_Phoenix.jpg';

        if (sourceBlock.getInput('image_uri')) {
          sourceBlock.removeInput('image_uri');
        }
        if (sourceBlock.getInput('input_text')) {
          sourceBlock.removeInput('input_text');
        }

        if (source == 'image') {
          sourceBlock.appendDummyInput('image_uri')
            .appendField('URI: ')
            .appendField(new Blockly.FieldTextInput(defaultUri), 'image_uri');
          sourceBlock.moveInputBefore('image_uri', 'DO');
        } else if (source == 'text') {
          sourceBlock.appendDummyInput('input_text')
            .appendField(Blockly.Msg.TFJS_INTERACTION_INPUT_INPUT)
            .appendField(new Blockly.FieldVariable('input'), 'input_text');
          sourceBlock.moveInputBefore('input_text', 'DO');
        }
      }
    };

    const sourceExtension = function() {
      this.getField('SOURCE').setValidator(function(source) {
        this.sourceBlock_.updateShape(source);
      });
    };

    // This hack is to explore the protected field ALL_ in Extenstions
    // Need to refactor to avoid the direct access
    if (!('interaction_source_mutator' in Blockly.Extensions.ALL_)) {
      Blockly.Extensions.registerMutator(
        'interaction_source_mutator',
        sourceMixin,
        sourceExtension
      );
    }

    const blockJson = {
      style: 'tfjs_blocks',
      mutator: 'interaction_source_mutator',
      message0: '%{BKY_TFJS_INTERACTION_INPUT_TITLE}',
      args0: [
        {
          type: 'field_dropdown',
          name: 'SOURCE',
          options: [
            ['%{BKY_TFJS_INTERACTION_INPUT_IMAGE_UPLOAD}', 'uploadImage'],
            ['%{BKY_TFJS_INTERACTION_INPUT_IMAGE_URL}', 'image'],
            ['%{BKY_TFJS_INTERACTION_INPUT_WEBCAM}', 'webcam'],
            ['%{BKY_TFJS_INTERACTION_INPUT_CANVAS}', 'canvas'],
            ['%{BKY_TFJS_INTERACTION_INPUT_TEXT}', 'text']
          ]
        }
      ],
      message1: '%{BKY_TFJS_INTERACTION_INPUT_MESSAGE_1}',
      args1: [{
        type: 'input_statement',
        name: 'DO'
      }],
      previousStatement: null,
      nextStatement: null,
    };
    console.log('blockJson',blockJson)
    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    let imageUri = '';
    if (block.getField('image_uri')) {
      imageUri = block.getFieldValue('image_uri');
    }
    const statementCode = Blockly.JavaScript.statementToCode(block, 'DO');

    const inputImage = outdent({ trimTrailingNewline: false })`
    const input = await ai.io.enableImage('${imageUri}');
    ${statementCode}
    `;

    const inputWebcam = outdent({ trimTrailingNewline: false })`
    const input = await ai.io.enableVideo();
    window.detection = async () => {
      ${statementCode}
      requestAnimationFrame(window.detection);
    };
    window.detection();
    `;

    const inputCanvas = outdent({ trimTrailingNewline: false })`
    window.detection = async () => {
      const input = window.getInputCanvasCropped();
      document.getElementById('inputImage').src = input.toDataURL();
      ${statementCode}
    };
    `;

    const inputUploadImage = outdent({ trimTrailingNewline: false})`
      ai.io.showInput('upload');
      window.detection = async () => {
        const input = await ai.io.enableUploadImage();
        ${statementCode}
      }
    `;


    let input = '';
    if (block.getField('input_text')) {
      input = block.getField('input_text').getText();
    }
    const inputText = outdent({ trimTrailingNewline: false})`
      window.detection = async () => {
        ${input} = ai.io.consoleTextInput();
        if (${input}) {
          ${statementCode}
        }
      }
    `;

    if (block.getFieldValue('SOURCE') === 'image') {
      return inputImage;
    } else if (block.getFieldValue('SOURCE') === 'webcam') {
      return inputWebcam;
    } else if (block.getFieldValue('SOURCE') === 'canvas') {
      return inputCanvas;
    } else if (block.getFieldValue('SOURCE') === 'uploadImage') {
      return inputUploadImage;
    } else if (block.getFieldValue('SOURCE') === 'text') {
      return inputText;
    }

    return '';
  }
}
