import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSplitModule } from 'angular-split';
import { SharedModule } from '../shared';
import { BlocklyComponent } from './blockly.component';
import { CardResultComponent } from './card-result/card-result.component';
import { CardCodeComponent } from './card-code/card-code.component';
import { CardBlocklyComponent } from './card-blockly/card-blockly.component';
import { WorkspaceComponent } from './workspace/workspace.component';
import { CardInputsComponent } from './card-inputs/card-inputs.component';
import { CardConsoleComponent } from './card-console/card-console.component';
import { InputUploadComponent } from './input-upload/input-upload.component';

@NgModule({
  declarations: [
    BlocklyComponent,
    CardResultComponent,
    CardCodeComponent,
    CardBlocklyComponent,
    WorkspaceComponent,
    CardInputsComponent,
    CardConsoleComponent,
    InputUploadComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AngularSplitModule.forRoot(),
  ],
  exports: [
    BlocklyComponent,
  ]
})
export class BlocklyModule { }
