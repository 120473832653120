import * as En from 'blockly/msg/en';
import * as ZhHans from 'blockly/msg/zh-hans';
import * as ZhHant from 'blockly/msg/zh-hant';


export class BlocklyLocale {
  EnCategory = {
    LOGIC_TITLE: 'Logic',
    CONTROLS_TITLE: 'Control',
    MATH_TITLE: 'Math',
    TEXT_TITLE: 'Text',
    LISTS_TITLE: 'List',
    OBJECTS_TITLE: 'Objects',
    COLOUR_TITLE: 'Colour',
    TIME_TITLE: 'Time',
    VARIABLES_TITLE: 'Variables',
    REQUESTS_TITLE: 'Requests',
    OUTPUT_TITLE: 'Output',
    FUNCTIONS_TITLE: 'Functions',
    TENSORFLOW_TITLE: 'Tensorflow',
    TENSORS_TITLE: 'Tensors',
    DATASETS_TITLE: 'Datasets',
    LAYERS_TITLE: 'Layers',
    MODELS_TITLE: 'Models',
    INTERACTIONS_TITLE: 'Interactions',
    UTILITIES_TITLE: 'Utilities',
    LIBRARIES_TITLE: 'Libraries',
    FACE_DETECTION_TITLE: 'Face Detection',
    HAND_DETECTION_TITLE: 'Hand Detection',
    OBJECT_DETECTION_TITLE: 'Object Detection',
    POSE_DETECTION_TITLE: 'Pose Detection',
    TEACHABLE_MACHINE_TITLE: 'Teachable Machine'
  }

  ZhHantCategory = {
    LOGIC_TITLE: '邏輯',
    CONTROLS_TITLE: '循環',
    MATH_TITLE: '數學',
    TEXT_TITLE: '文字',
    LISTS_TITLE: '列表',
    OBJECTS_TITLE: '物件',
    COLOUR_TITLE: '顔色',
    TIME_TITLE: '時間',
    VARIABLES_TITLE: '變量',
    REQUESTS_TITLE: '數據請求',
    OUTPUT_TITLE: '輸出',
    FUNCTIONS_TITLE: '函數',
    TENSORFLOW_TITLE: 'Tensorflow',
    TENSORS_TITLE: '張量',
    MODELS_TITLE: '模型',
    DATASETS_TITLE: '數據集',
    LAYERS_TITLE: '中間層',
    INTERACTIONS_TITLE: '用戶交互',
    UTILITIES_TITLE: '數據處理',
    LIBRARIES_TITLE: '模組',
    FACE_DETECTION_TITLE: '臉部識別',
    HAND_DETECTION_TITLE: '手部識別',
    OBJECT_DETECTION_TITLE: '物件識別',
    POSE_DETECTION_TITLE: '姿勢識別',
    TEACHABLE_MACHINE_TITLE: '自學機器'
  }

  ZhHansCategory = {
    LOGIC_TITLE: '逻辑',
    CONTROLS_TITLE: '循环',
    MATH_TITLE: '数学',
    TEXT_TITLE: '文字',
    LISTS_TITLE: '列表',
    OBJECTS_TITLE: '物件',
    COLOUR_TITLE: '顔色',
    TIME_TITLE: '时间',
    VARIABLES_TITLE: '变量',
    REQUESTS_TITLE: '数据请求',
    OUTPUT_TITLE: '输出',
    FUNCTIONS_TITLE: '函数',
    TENSORFLOW_TITLE: 'Tensorflow',
    TENSORS_TITLE: '张量',
    MODELS_TITLE: '模型',
    DATASETS_TITLE: '数据集',
    LAYERS_TITLE: '中间层',
    INTERACTIONS_TITLE: '用户交互',
    UTILITIES_TITLE: '数据处理',
    LIBRARIES_TITLE: '模组',
    FACE_DETECTION_TITLE: '脸部识别',
    HAND_DETECTION_TITLE: '手部识别',
    OBJECT_DETECTION_TITLE: '物件识别',
    POSE_DETECTION_TITLE: '姿势识别',
    TEACHABLE_MACHINE_TITLE: '自学机器'
  }

  EnFaceDetection = {
    FACE_DETECTION_FACE_LANDMARKS: 'Face landmarks',
    FACE_DETECTION_FACE_EXPRESSIONS: 'Face expressions',

    FACE_DETECTION_AGE: 'Age',
    FACE_DETECTION_AGE_AND_GENDER: 'Age and gender',
    FACE_DETECTION_BASIC_INFORMATION: 'Basic information',
    FACE_DETECTION_GENDER: 'Gender',

    FACE_DETECTION_DETECT_FACE_TITLE: 'Detect %1 and save result to %2',
    FACE_DETECTION_DETECT_FACE_ALL_FACES: 'all faces',
    FACE_DETECTION_DETECT_FACE_SINGLE_FACE: 'single face',
    FACE_DETECTION_DRAW_DETECTION_TITLE: 'Draw face detection from %1 with',
    FACE_DETECTION_EXPORT_TITLE: 'Save %1 as',
    FACE_DETECTION_LOAD_DETECTOR_TITLE: 'Load face detector %1',
    FACE_DETECTION_LOAD_DETECTOR_MAX_RESULTS: 'maxResults',
    FACE_DETECTION_LOAD_DETECTOR_MIN_CONFIDENCE: 'minConfidence',
    FACE_DETECTION_LOAD_DETECTOR_INPUT_SIZE: 'inputSize',
    FACE_DETECTION_LOAD_DETECTOR_SCORE_THRESHOLD: 'scoreThreshold',
    FACE_DETECTION_LOAD_DETECTOR_SCALE_FACTOR: 'scaleFactor',

    FACE_DETECTION_LOAD_NET_TITLE: 'Load neural network'
  }

  ZhHantFaceDetection = {
    FACE_DETECTION_FACE_LANDMARKS: '臉部特徵',
    FACE_DETECTION_FACE_EXPRESSIONS: '臉部表情',

    FACE_DETECTION_AGE: '年齡',
    FACE_DETECTION_AGE_AND_GENDER: '年齡與性別',
    FACE_DETECTION_BASIC_INFORMATION: '基本資訊',
    FACE_DETECTION_GENDER: '性別',

    FACE_DETECTION_DETECT_FACE_TITLE: '識別 %1 並儲存到 %2',
    FACE_DETECTION_DETECT_FACE_ALL_FACES: '全部臉部',
    FACE_DETECTION_DETECT_FACE_SINGLE_FACE: '單一臉部',
    FACE_DETECTION_DRAW_DETECTION_TITLE: '輸出臉部識別結果 %1',
    FACE_DETECTION_EXPORT_TITLE: '儲存 %1',
    FACE_DETECTION_LOAD_DETECTOR_TITLE: '加載臉部識別器 %1',
    FACE_DETECTION_LOAD_DETECTOR_MAX_RESULTS: '最多結果',
    FACE_DETECTION_LOAD_DETECTOR_MIN_CONFIDENCE: '最低信賴',
    FACE_DETECTION_LOAD_DETECTOR_INPUT_SIZE: '輸入大小',
    FACE_DETECTION_LOAD_DETECTOR_SCORE_THRESHOLD: '分數閾值',
    FACE_DETECTION_LOAD_DETECTOR_SCALE_FACTOR: '比例因數',

    FACE_DETECTION_LOAD_NET_TITLE: '加載神經網絡中間層'
  }

  ZhHansFaceDetection = {
    FACE_DETECTION_FACE_LANDMARKS: '脸部特徵',
    FACE_DETECTION_FACE_EXPRESSIONS: '脸部表情',

    FACE_DETECTION_AGE: '年龄',
    FACE_DETECTION_AGE_AND_GENDER: '年龄与性别',
    FACE_DETECTION_BASIC_INFORMATION: '基本资讯',
    FACE_DETECTION_GENDER: '性别',

    FACE_DETECTION_DETECT_FACE_TITLE: '识别 %1 并储存到 %2',
    FACE_DETECTION_DETECT_FACE_ALL_FACES: '全部脸部',
    FACE_DETECTION_DETECT_FACE_SINGLE_FACE: '单一脸部',
    FACE_DETECTION_DRAW_DETECTION_TITLE: '输出脸部识别结果 %1',
    FACE_DETECTION_EXPORT_TITLE: '储存 %1',
    FACE_DETECTION_LOAD_DETECTOR_TITLE: '加载脸部识别器 %1',
    FACE_DETECTION_LOAD_DETECTOR_MAX_RESULTS: '最多结果',
    FACE_DETECTION_LOAD_DETECTOR_MIN_CONFIDENCE: '最低信赖',
    FACE_DETECTION_LOAD_DETECTOR_INPUT_SIZE: '输入大小',
    FACE_DETECTION_LOAD_DETECTOR_SCORE_THRESHOLD: '分数阈值',
    FACE_DETECTION_LOAD_DETECTOR_SCALE_FACTOR: '比例因数',

    FACE_DETECTION_LOAD_NET_TITLE: '加载神经网络中间层'
  }

  EnHandDetection = {
    HAND_DETECTION_COUNT_TITLE: 'number of detections in %1',
    HAND_DETECTION_DETECT_HAND_TITLE: 'Detect hands and save result to %1',
    HAND_DETECTION_DRAW_DETECTION_TITLE: 'Draw hand detection from %1',
    HAND_DETECTION_LOAD_MODEL_TITLE: 'Load hand detection model',
    HAND_DETECTION_SCORE_TITLE: '%1 confidence score',
    HAND_DETECTION_X_CENTER_TITLE: '%1 horizontal center position',
    HAND_DETECTION_Y_CENTER_TITLE: '%1 vertical center position',
  }

  ZhHantHandDetection = {
    HAND_DETECTION_COUNT_TITLE: '%1中的識別數量',
    HAND_DETECTION_DETECT_HAND_TITLE: '識別手部並儲存結果到 %1',
    HAND_DETECTION_DRAW_DETECTION_TITLE: '輸出手部結果 %1',
    HAND_DETECTION_LOAD_MODEL_TITLE: '加載手部識別模型',
    HAND_DETECTION_SCORE_TITLE: '%1 信賴分數',
    HAND_DETECTION_X_CENTER_TITLE: '%1 水平中心',
    HAND_DETECTION_Y_CENTER_TITLE: '%1 垂直中心',
  }

  ZhHansHandDetection = {
    HAND_DETECTION_COUNT_TITLE: '%1中的识别数量',
    HAND_DETECTION_DETECT_HAND_TITLE: '识别手部并储存结果到 %1',
    HAND_DETECTION_DRAW_DETECTION_TITLE: '输出手部结果 %1',
    HAND_DETECTION_LOAD_MODEL_TITLE: '加载手部识别模型',
    HAND_DETECTION_SCORE_TITLE: '%1 信赖分数',
    HAND_DETECTION_X_CENTER_TITLE: '%1 水平中心',
    HAND_DETECTION_Y_CENTER_TITLE: '%1 垂直中心',
  }

  EnObjectDetection = {
    OBJECT_DETECTION_COUNT_TITLE: 'number of detections in %1',
    OBJECT_DETECTION_DETECT_OBJECT_TITLE: 'Detect object and save result to %1',
    OBJECT_DETECTION_DRAW_DETECTION_TITLE: 'Draw object detection from %1',
    OBJECT_DETECTION_LOAD_MODEL_TITLE: 'Load object detection model',
    OBJECT_DETECTION_SCORE_TITLE: '%1 confidence score',
    OBJECT_DETECTION_X_CENTER_TITLE: '%1 horizontal center position',
    OBJECT_DETECTION_Y_CENTER_TITLE: '%1 vertical center position',
    OBJECT_DETECTION_CLASSIFICATION_TITLE: '%1 classification'
  }

  ZhHantObjectDetection = {
    OBJECT_DETECTION_COUNT_TITLE: '%1中的識別數量',
    OBJECT_DETECTION_DETECT_OBJECT_TITLE: '識別物件並儲存結果到 %1',
    OBJECT_DETECTION_DRAW_DETECTION_TITLE: '輸出物件識別結果 %1',
    OBJECT_DETECTION_LOAD_MODEL_TITLE: '加載物件識別模型',
    OBJECT_DETECTION_SCORE_TITLE: '%1 信賴分數',
    OBJECT_DETECTION_X_CENTER_TITLE: '%1 水平中心',
    OBJECT_DETECTION_Y_CENTER_TITLE: '%1 垂直中心',
    OBJECT_DETECTION_CLASSIFICATION_TITLE: '%1 分類'
  }

  ZhHansObjectDetection = {
    OBJECT_DETECTION_COUNT_TITLE: '%1中的识别数量',
    OBJECT_DETECTION_DETECT_OBJECT_TITLE: '识别物件并储存结果到 %1',
    OBJECT_DETECTION_DRAW_DETECTION_TITLE: '输出物件识别结果 %1',
    OBJECT_DETECTION_LOAD_MODEL_TITLE: '加载物件识别模型',
    OBJECT_DETECTION_SCORE_TITLE: '%1 信赖分数',
    OBJECT_DETECTION_X_CENTER_TITLE: '%1 水平中心',
    OBJECT_DETECTION_Y_CENTER_TITLE: '%1 垂直中心',
    OBJECT_DETECTION_CLASSIFICATION_TITLE: '%1 分类'
  }

  EnPoseDetection = {
    POSE_DETECTION_DRAW_DETECTION_TITLE: 'Draw pose detection %1',
    POSE_DETECTION_LOAD_MODEL_TITLE: 'Load pose detection model',
    POSE_DETECTION_POSE_COUNT_TITLE: 'Pose Count in %1',
    POSE_DETECTION_POSE_DETECTION_TITLE: 'Detect pose %1',
    POSE_DETECTION_X_CENTER_TITLE: 'X position for %1 in %2',
    POSE_DETECTION_Y_CENTER_TITLE: 'Y position for %1 in %2',
    POSE_DETECTION_NOSE: 'nose',
    POSE_DETECTION_LEFT_EYE: 'left eye',
    POSE_DETECTION_RIGHT_EYE: 'right eye',
    POSE_DETECTION_LEFT_EAR: 'left ear',
    POSE_DETECTION_RIGHT_EAR: 'right ear',
    POSE_DETECTION_LEFT_SHOULDER: 'left shoulder',
    POSE_DETECTION_RIGHT_SHOULDER: 'right shoulder',
    POSE_DETECTION_LEFT_ELBOW: 'left elbow',
    POSE_DETECTION_RIGHT_ELBOW: 'right elbow',
    POSE_DETECTION_LEFT_WRIST: 'left wrist',
    POSE_DETECTION_RIGHT_WRIST: 'right wrist',
    POSE_DETECTION_LEFT_HIP: 'left hip',
    POSE_DETECTION_RIGHT_HIP: 'right hip',
    POSE_DETECTION_LEFT_KNEE: 'left knee',
    POSE_DETECTION_RIGHT_KNEE: 'right knee',
    POSE_DETECTION_LEFT_ANKLE: 'left ankle',
    POSE_DETECTION_RIGHT_ANKLE: 'right ankle',
  };

  ZhHantPoseDetection = {
    POSE_DETECTION_DRAW_DETECTION_TITLE: '輸出姿勢識別結果 %1',
    POSE_DETECTION_LOAD_MODEL_TITLE: '加載姿勢識別模型',
    POSE_DETECTION_POSE_COUNT_TITLE: '姿勢數量 %1',
    POSE_DETECTION_POSE_DETECTION_TITLE: '識別姿勢 %1',
    POSE_DETECTION_X_CENTER_TITLE: '%1 在 %2 的水平位置',
    POSE_DETECTION_Y_CENTER_TITLE: '%1 在 %2 的垂直位置',
    POSE_DETECTION_NOSE: '鼻子',
    POSE_DETECTION_LEFT_EYE: '左眼',
    POSE_DETECTION_RIGHT_EYE: '右眼',
    POSE_DETECTION_LEFT_EAR: '左耳',
    POSE_DETECTION_RIGHT_EAR: '右耳',
    POSE_DETECTION_LEFT_SHOULDER: '左肩',
    POSE_DETECTION_RIGHT_SHOULDER: '右肩',
    POSE_DETECTION_LEFT_ELBOW: '左肘',
    POSE_DETECTION_RIGHT_ELBOW: '右肘',
    POSE_DETECTION_LEFT_WRIST: '左腕',
    POSE_DETECTION_RIGHT_WRIST: '右腕',
    POSE_DETECTION_LEFT_HIP: '左臀',
    POSE_DETECTION_RIGHT_HIP: '右臀',
    POSE_DETECTION_LEFT_KNEE: '左膝',
    POSE_DETECTION_RIGHT_KNEE: '右膝',
    POSE_DETECTION_LEFT_ANKLE: '左踝',
    POSE_DETECTION_RIGHT_ANKLE: '右踝',
  };

  ZhHansPoseDetection = {
    POSE_DETECTION_DRAW_DETECTION_TITLE: '输出姿势识别结果 %1',
    POSE_DETECTION_LOAD_MODEL_TITLE: '加载姿势识别模型',
    POSE_DETECTION_POSE_COUNT_TITLE: '姿势数量 %1',
    POSE_DETECTION_POSE_DETECTION_TITLE: '识别姿势 %1',
    POSE_DETECTION_X_CENTER_TITLE: '%1 在 %2 的水平位置',
    POSE_DETECTION_Y_CENTER_TITLE: '%1 在 %2 的垂直位置',
    POSE_DETECTION_NOSE: '鼻子',
    POSE_DETECTION_LEFT_EYE: '左眼',
    POSE_DETECTION_RIGHT_EYE: '右眼',
    POSE_DETECTION_LEFT_EAR: '左耳',
    POSE_DETECTION_RIGHT_EAR: '右耳',
    POSE_DETECTION_LEFT_SHOULDER: '左肩',
    POSE_DETECTION_RIGHT_SHOULDER: '右肩',
    POSE_DETECTION_LEFT_ELBOW: '左肘',
    POSE_DETECTION_RIGHT_ELBOW: '右肘',
    POSE_DETECTION_LEFT_WRIST: '左腕',
    POSE_DETECTION_RIGHT_WRIST: '右腕',
    POSE_DETECTION_LEFT_HIP: '左臀',
    POSE_DETECTION_RIGHT_HIP: '右臀',
    POSE_DETECTION_LEFT_KNEE: '左膝',
    POSE_DETECTION_RIGHT_KNEE: '右膝',
    POSE_DETECTION_LEFT_ANKLE: '左踝',
    POSE_DETECTION_RIGHT_ANKLE: '右踝',
  };

  EnTeachable = {
    TEACHABLE_CREATE_MODEL_MESSAGE_0: 'Train image recognition model %1 with data set %2',
    TEACHABLE_CREATE_MODEL_MESSAGE_1: 'Epochs %1',
    TEACHABLE_CREATE_MODEL_MESSAGE_2: 'Batch size %1',
    TEACHABLE_DETECT_MESSAGE_0: 'Detect with model %1',
    TEACHABLE_DETECT_MESSAGE_1: 'Top %1 predictions',
    TEACHABLE_DETECT_MESSAGE_2: 'Save result to %1'
  }

  ZhHantTeachable = {
    TEACHABLE_CREATE_MODEL_MESSAGE_0: '用數據集 %2 訓練圖像識別模型 %1',
    TEACHABLE_CREATE_MODEL_MESSAGE_1: '時期 %1',
    TEACHABLE_CREATE_MODEL_MESSAGE_2: '批次大小 %1',
    TEACHABLE_DETECT_MESSAGE_0: '用模型 %1 識別圖像',
    TEACHABLE_DETECT_MESSAGE_1: '最多 %1 預測',
    TEACHABLE_DETECT_MESSAGE_2: '儲存結果到 %1'
  }

  ZhHansTeachable = {
    TEACHABLE_CREATE_MODEL_MESSAGE_0: '用数据集 %2 训练图像识别模型 %1',
    TEACHABLE_CREATE_MODEL_MESSAGE_1: '时期 %1',
    TEACHABLE_CREATE_MODEL_MESSAGE_2: '批次大小 %1',
    TEACHABLE_DETECT_MESSAGE_0: '用模型 %1 识别图像',
    TEACHABLE_DETECT_MESSAGE_1: '最多 %1 预测',
    TEACHABLE_DETECT_MESSAGE_2: '储存结果到 %1'
  }

  EnTfjsTensor = {
    TFJS_CREATE_TENSOR_TITLE: 'Create tensor %1 from data %2 with label %3',
    TFJS_CREATE_TENSOR_2_TITLE: 'Create tensor %1 from array data %2',
    TFJS_CREATE_TENSOR_CANVAS_MESSAGE_0: 'Create tensor %1 from canvas input',
    TFJS_CREATE_TENSOR_CANVAS_MESSAGE_1: 'Resize to width %1, height %2',
    TFJS_TENSOR_NORMAL_MESSAGE_0: 'Create normalized tensor %1',
    TFJS_TENSOR_NORMAL_MESSAGE_1: 'From tensor %1',
    TFJS_TENSOR_NORMAL_MESSAGE_2: 'Minimum %1',
    TFJS_TENSOR_NORMAL_MESSAGE_3: 'Maximum %1',
    TFJS_TENSOR_UNNORMAL_MESSAGE_0: 'Create unnormalized tensor %1',
    TFJS_TENSOR_UNNORMAL_MESSAGE_1: 'From normalized tensor %1',
    TFJS_TENSOR_UNNORMAL_MESSAGE_2: 'Minimum %1',
    TFJS_TENSOR_UNNORMAL_MESSAGE_3: 'Maximum %1',
    TFJS_MNIST_DATA_BATCH_MESSAGE_0: 'Create tensor %1, %2 from MNIST data %3',
    TFJS_MNIST_DATA_BATCH_MESSAGE_1: 'Type %1',
    TFJS_MNIST_DATA_BATCH_MESSAGE_2: 'Batch size %1',
    TFJS_MNIST_DATA_BATCH_TEST: 'Test',
    TFJS_MNIST_DATA_BATCH_TRAIN: 'Train',
  }

  ZhHantTfjsTensor = {
    TFJS_CREATE_TENSOR_TITLE: '由 %2 創建張量 %1 並標簽為 %3',
    TFJS_CREATE_TENSOR_2_TITLE: '由數組 %2 創建張量 %1',
    TFJS_CREATE_TENSOR_CANVAS_MESSAGE_0: '由畫布 %1 創建張量',
    TFJS_CREATE_TENSOR_CANVAS_MESSAGE_1: '寬度為 %1 高度為 %2',
    TFJS_TENSOR_NORMAL_MESSAGE_0: '創建歸一化張量 %1',
    TFJS_TENSOR_NORMAL_MESSAGE_1: '由張量 %1',
    TFJS_TENSOR_NORMAL_MESSAGE_2: '最大值為 %1',
    TFJS_TENSOR_NORMAL_MESSAGE_3: '最小值為 %1',
    TFJS_TENSOR_UNNORMAL_MESSAGE_0: '創建去歸一化張量 %1',
    TFJS_TENSOR_UNNORMAL_MESSAGE_1: '由歸一化張量 %1',
    TFJS_TENSOR_UNNORMAL_MESSAGE_2: '最大值為 %1',
    TFJS_TENSOR_UNNORMAL_MESSAGE_3: '最小值為 %1',
    TFJS_MNIST_DATA_BATCH_MESSAGE_0: '由MNIST數據集 %3 創建張量 %1, %2',
    TFJS_MNIST_DATA_BATCH_MESSAGE_1: '操作類型 %1',
    TFJS_MNIST_DATA_BATCH_MESSAGE_2: '批次大小 %1',
    TFJS_MNIST_DATA_BATCH_TEST: '測試',
    TFJS_MNIST_DATA_BATCH_TRAIN: '訓練',
  }

  ZhHansTfjsTensor = {
    TFJS_CREATE_TENSOR_TITLE: '由 %2 创建张量 %1 并标签为 %3',
    TFJS_CREATE_TENSOR_2_TITLE: '由数组 %2 创建张量 %1',
    TFJS_CREATE_TENSOR_CANVAS_MESSAGE_0: '由画布 %1 创建张量',
    TFJS_CREATE_TENSOR_CANVAS_MESSAGE_1: '宽度为 %1 高度为 %2',
    TFJS_TENSOR_NORMAL_MESSAGE_0: '创建归一化张量 %1',
    TFJS_TENSOR_NORMAL_MESSAGE_1: '由张量 %1',
    TFJS_TENSOR_NORMAL_MESSAGE_2: '最大值为 %1',
    TFJS_TENSOR_NORMAL_MESSAGE_3: '最小值为 %1',
    TFJS_TENSOR_UNNORMAL_MESSAGE_0: '创建去归一化张量 %1',
    TFJS_TENSOR_UNNORMAL_MESSAGE_1: '由归一化张量 %1',
    TFJS_TENSOR_UNNORMAL_MESSAGE_2: '最大值为 %1',
    TFJS_TENSOR_UNNORMAL_MESSAGE_3: '最小值为 %1',
    TFJS_MNIST_DATA_BATCH_MESSAGE_0: '由MNIST数据集 %3 创建张量 %1, %2',
    TFJS_MNIST_DATA_BATCH_MESSAGE_1: '操作类型 %1',
    TFJS_MNIST_DATA_BATCH_MESSAGE_2: '批次大小 %1',
    TFJS_MNIST_DATA_BATCH_TEST: '测试',
    TFJS_MNIST_DATA_BATCH_TRAIN: '训练',
  }

  EnTfjsDataset = {
    TFJS_DATASET_LOAD_JSON_TITLE: 'Load %1 as Json from %2',
    TFJS_DATASET_LOAD_MNIST_TITLE: 'Load %1 from MNIST (handwriting database)',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_0: 'Load from Iris Flower Dataset',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_1: 'Training Set: Iris Flower Features %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_2: 'Training Set: Iris Flower Class %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_3: 'Validation Set: Iris Flower Features %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_4: 'Validation Set: Iris Flower Class %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_5: 'All Classes of Iris Flower %1',
    TFJS_DATASET_TRAIN_KNN_TITLE: 'Train kNN classifier from data set %1',
    TFJS_DATASET_DETECT_KNN_TITLE: 'Detect with kNN classifier and save result to %1',
    TFJS_DATASET_LOAD_IRIS1_MESSAGE_0:'Copy %1 to %2',TFJS_DATASET_LOAD_IRIS_COPY_DATA_MESSAGE_0:' Data from %1 %2 %3 ',
    TFJS_DATASET_LOAD_IRIS_COPY_DATA_LINE_MESSAGE_0:'Line',
    TFJS_DATASET_LOAD_IRIS_COPY_DATA_COL_MESSAGE_0:'Column',
    TFJS_DATASET_LOAD_IRIS_DS_MESSAGE_0:'Iris data set',
    TFJS_DATASET_LOAD_IRIS_INITIALIZE_MESSAGE_0:' Initialize %1 neural network hidden layer structure %2 value',
    TFJS_DATASET_LOAD_IRIS_MODEL_MESSAGE_0:' Iris classification model ',
    TFJS_DATASET_LOAD_IRIS_TRAIT_MESSAGE_0:' Iris training data feature %1 value',
    TFJS_DATASET_LOAD_IRIS_DATA_TYPE_MESSAGE_0:' Iris training data classification %1 value',
    TFJS_DATASET_LOAD_IRIS_LONG_MESSAGE_0:' Calyx length ',
    TFJS_DATASET_LOAD_IRIS_WIDE_MESSAGE_0:' Calyx width ',
    TFJS_DATASET_LOAD_IRIS_SPECIES_MESSAGE_0:' Sepal species ',
    TFJS_DATASET_LOAD_IRIS_CYCLE_MESSAGE_0:' After using the training data, want to spread the training and save as %1',
    TFJS_DATASET_LOAD_IRIS_ATTRIBUTE_MESSAGE_0:'%1',
    TFJS_DATASET_LOAD_CLICK_MESSAGE_0:'是否上传鸢尾花数据集',
    TFJS_DATASET_LOAD_CLICK_MESSAGE_1:'Yes',
    TFJS_DATASET_LOAD_CLICK_MESSAGE_2:'No',
  }

  ZhHantTfjsDataset = {
    TFJS_DATASET_LOAD_JSON_TITLE: '由 %2 加載Json數據到 %1',
    TFJS_DATASET_LOAD_MNIST_TITLE: '由MNIST（手寫數字數據庫）加載數據到 %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_0: '加載鳶尾花數據庫',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_1: '訓練集：鳶尾花特徵 %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_2: '訓練集：鳶尾花屬種 %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_3: '驗證集：鳶尾花特徵 %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_4: '驗證集：鳶尾花屬種 %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_5: '鳶尾花屬種列組 %1',
    TFJS_DATASET_TRAIN_KNN_TITLE: '由數據集 %1 訓練kNN分類器',
    TFJS_DATASET_DETECT_KNN_TITLE: '用kNN分類器識別結果並儲存到 %1',
    TFJS_DATASET_LOAD_IRIS1_MESSAGE_0:'複製 %1 到 %2',
    TFJS_DATASET_LOAD_IRIS_COPY_DATA_MESSAGE_0:'從 %1 第 %2 %3的數據',
    TFJS_DATASET_LOAD_IRIS_COPY_DATA_LINE_MESSAGE_0:'行',
    TFJS_DATASET_LOAD_IRIS_COPY_DATA_COL_MESSAGE_0:'列',
    TFJS_DATASET_LOAD_IRIS_DS_MESSAGE_0:'鳶尾花數據集',
    TFJS_DATASET_LOAD_IRIS_INITIALIZE_MESSAGE_0:'初始化 %1 神經網絡隱含層結構 %2 值',
    TFJS_DATASET_LOAD_IRIS_MODEL_MESSAGE_0:'鳶尾花分類模型',
    TFJS_DATASET_LOAD_IRIS_TRAIT_MESSAGE_0:'鳶尾花訓練數據特徵 %1 值',
    TFJS_DATASET_LOAD_IRIS_DATA_TYPE_MESSAGE_0:'鳶尾花訓練數據分類 %1 值',
    TFJS_DATASET_LOAD_IRIS_LONG_MESSAGE_0:'花萼長',
    TFJS_DATASET_LOAD_IRIS_WIDE_MESSAGE_0:'花萼寬',
    TFJS_DATASET_LOAD_IRIS_SPECIES_MESSAGE_0:'花萼種類',
    TFJS_DATASET_LOAD_IRIS_CYCLE_MESSAGE_0:'對訓練數據使用後想傳播訓練並存爲 %1',
    TFJS_DATASET_LOAD_IRIS_ATTRIBUTE_MESSAGE_0:'%1',
    TFJS_DATASET_LOAD_CLICK_MESSAGE_0:'是否上传鸢尾花数据集',
    TFJS_DATASET_LOAD_CLICK_MESSAGE_1:'是',
    TFJS_DATASET_LOAD_CLICK_MESSAGE_2:'否',
  }

  ZhHansTfjsDataset = {
    TFJS_DATASET_LOAD_JSON_TITLE: '由 %2 加载Json数据到 %1',
    TFJS_DATASET_LOAD_MNIST_TITLE: '由MNIST（手写数字数据库）加载数据到 %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_0: '加载鸢尾花数据库',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_1: '训练集：鸢尾花特徵 %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_2: '训练集：鸢尾花属种 %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_3: '验证集：鸢尾花特徵 %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_4: '验证集：鸢尾花属种 %1',
    TFJS_DATASET_LOAD_IRIS_MESSAGE_5: '鸢尾花属种列组 %1',
    TFJS_DATASET_TRAIN_KNN_TITLE: '由数据集 %1 训练kNN分类器',
    TFJS_DATASET_DETECT_KNN_TITLE: '用kNN分类器识别结果并储存到 %1',
    TFJS_DATASET_LOAD_IRIS1_MESSAGE_0:'复制 %1 到 %2',
    TFJS_DATASET_LOAD_IRIS_COPY_DATA_MESSAGE_0:'从 %1 第 %2 %3的数据',
    TFJS_DATASET_LOAD_IRIS_COPY_DATA_LINE_MESSAGE_0:'行',
    TFJS_DATASET_LOAD_IRIS_COPY_DATA_COL_MESSAGE_0:'列',
    TFJS_DATASET_LOAD_IRIS_DS_MESSAGE_0:'鸢尾花数据集',
    TFJS_DATASET_LOAD_IRIS_INITIALIZE_MESSAGE_0:'初始化 %1 神经网络隐含层结构 %2 值',
    TFJS_DATASET_LOAD_IRIS_MODEL_MESSAGE_0:'鸢尾花分类模型',
    TFJS_DATASET_LOAD_IRIS_TRAIT_MESSAGE_0:'鸢尾花训练数据特征 %1 值',
    TFJS_DATASET_LOAD_IRIS_DATA_TYPE_MESSAGE_0:'鸢尾花训练数据分类 %1 值',
    TFJS_DATASET_LOAD_IRIS_LONG_MESSAGE_0:'花萼长',
    TFJS_DATASET_LOAD_IRIS_WIDE_MESSAGE_0:'花萼宽',
    TFJS_DATASET_LOAD_IRIS_SPECIES_MESSAGE_0:'花萼种类',
    TFJS_DATASET_LOAD_IRIS_CYCLE_MESSAGE_0:'对训练数据使用后想传播训练并存为 %1',
    TFJS_DATASET_LOAD_IRIS_ATTRIBUTE_MESSAGE_0:'%1',
    TFJS_DATASET_LOAD_CLICK_MESSAGE_0:'是否上传鸢尾花数据集',
    TFJS_DATASET_LOAD_CLICK_MESSAGE_1:'是',
    TFJS_DATASET_LOAD_CLICK_MESSAGE_2:'否',

  }

  EnTfjsLayer = {
    TFJS_LAYER_ADD_INPUT_LAYER_TITLE: 'Add input layer to model %1 with dimension %2 with unit %3',
    TFJS_LAYER_ADD_DENSE_LAYER_TITLE: 'Add dense layer to model %1 with options %2',
    TFJS_LAYER_ADD_CONV_2D_LAYER_TITLE: 'Add Conv 2D layer to model %1 with options %2',
    TFJS_LAYER_ADD_MAX_POOL_2D_LAYER_TITLE: 'Add MaxPool 2D layer to model %1 with options %2',
    TFJS_LAYER_ADD_FLATTEN_LAYER_TITLE: 'Add flatten layer to model %1',
  }

  ZhHantTfjsLayer = {
    TFJS_LAYER_ADD_INPUT_LAYER_TITLE: '添加輸入層到模型 %1 而形狀 %2 節點 %3',
    TFJS_LAYER_ADD_DENSE_LAYER_TITLE: '添加全連接層到模型 %1 而選項 %2',
    TFJS_LAYER_ADD_CONV_2D_LAYER_TITLE: '添加2D卷積層到模型 %1 而選項 %2',
    TFJS_LAYER_ADD_MAX_POOL_2D_LAYER_TITLE: '添加2D池化層到模型 %1 而選項 %2',
    TFJS_LAYER_ADD_FLATTEN_LAYER_TITLE: '添加扁平層到模型 %1',
  }

  ZhHansTfjsLayer = {
    TFJS_LAYER_ADD_INPUT_LAYER_TITLE: '添加输入层到模型 %1 而形状 %2 节点 %3',
    TFJS_LAYER_ADD_DENSE_LAYER_TITLE: '添加全连接层到模型 %1 而选项 %2',
    TFJS_LAYER_ADD_CONV_2D_LAYER_TITLE: '添加2D卷积层到模型 %1 而选项 %2',
    TFJS_LAYER_ADD_MAX_POOL_2D_LAYER_TITLE: '添加2D池化层到模型 %1 而选项 %2',
    TFJS_LAYER_ADD_FLATTEN_LAYER_TITLE: '添加扁平层到模型 %1',
  }

  EnTfjsModel = {
    TFJS_MODEL_CREATE_SEQUENTIAL_MODEL_TITLE: 'Create sequential model as %1',
    TFJS_MODEL_COMPILE_MODEL_MESSAGE_0: 'Compile model %1',
    TFJS_MODEL_COMPILE_MODEL_MESSAGE_1: 'Optimizer %1, learning rate %2',
    TFJS_MODEL_COMPILE_MODEL_MESSAGE_2: 'Loss function %1',
    TFJS_MODEL_COMPILE_MODEL_MESSAGE_3: 'Metric %1',
    TFJS_MODEL_COMPILE_MODEL_MEAN_SQUARED_ERROR: 'Mean Squared Error',
    TFJS_MODEL_COMPILE_MODEL_MEAN_ABSOLUTE_ERROR: 'Mean Absolute Error',
    TFJS_MODEL_COMPILE_MODEL_CATEGORICAL_CROSSENTROPY: 'Categorical Crossentropy',
    TFJS_MODEL_FIT_MODEL_MESSAGE_0: 'Fit model %1 with input %2 and output %3',
    TFJS_MODEL_FIT_MODEL_MESSAGE_1: 'Options %1',
    TFJS_MODEL_FIT_MODEL_MESSAGE_2: 'Metric %1',
    TFJS_MODEL_PREDICT_OP_MESSAGE_0: 'Predict model %1 with tensor %2',
    TFJS_MODEL_PREDICT_OP_MESSAGE_1: 'Store result tensor as %1, results as %2',
    TFJS_MODEL_SAVE_MODEL_TITLE: 'Save model %1 to localStorage with name %2',
    TFJS_MODEL_LOAD_MODEL_TITLE: 'Load from localStorage with name %1 as model %2',
  }

  ZhHantTfjsModel = {
    TFJS_MODEL_CREATE_SEQUENTIAL_MODEL_TITLE: '創建順序模型 %1',
    TFJS_MODEL_COMPILE_MODEL_MESSAGE_0: '組合模型 %1',
    TFJS_MODEL_COMPILE_MODEL_MESSAGE_1: '優化器 %1, 學習速率 %2',
    TFJS_MODEL_COMPILE_MODEL_MESSAGE_2: '損失函數 %1',
    TFJS_MODEL_COMPILE_MODEL_MESSAGE_3: '度量 %1',
    TFJS_MODEL_COMPILE_MODEL_MEAN_SQUARED_ERROR: '均方誤差',
    TFJS_MODEL_COMPILE_MODEL_MEAN_ABSOLUTE_ERROR: '平均絕對誤差',
    TFJS_MODEL_COMPILE_MODEL_CATEGORICAL_CROSSENTROPY: '分類交叉熵',
    TFJS_MODEL_FIT_MODEL_MESSAGE_0: '訓練模型 %1 而輸入為 %2 和輸出為 %3',
    TFJS_MODEL_FIT_MODEL_MESSAGE_1: '選項 %1',
    TFJS_MODEL_FIT_MODEL_MESSAGE_2: '度量 %1',
    TFJS_MODEL_PREDICT_OP_MESSAGE_0: '用模型 %1 來預測張量 %2 ',
    TFJS_MODEL_PREDICT_OP_MESSAGE_1: '儲存預測張量為 %1，預測結果為 %2',
    TFJS_MODEL_SAVE_MODEL_TITLE: '儲存模型 %1 到localStorage並命名為 %2',
    TFJS_MODEL_LOAD_MODEL_TITLE: '由localStorage加載名稱為 %1 的模型 %2',
  }

  ZhHansTfjsModel = {
    TFJS_MODEL_CREATE_SEQUENTIAL_MODEL_TITLE: '创建顺序模型 %1',
    TFJS_MODEL_COMPILE_MODEL_MESSAGE_0: '组合模型 %1',
    TFJS_MODEL_COMPILE_MODEL_MESSAGE_1: '优化器 %1, 学习速率 %2',
    TFJS_MODEL_COMPILE_MODEL_MESSAGE_2: '损失函数 %1',
    TFJS_MODEL_COMPILE_MODEL_MESSAGE_3: '度量 %1',
    TFJS_MODEL_COMPILE_MODEL_MEAN_SQUARED_ERROR: '均方误差',
    TFJS_MODEL_COMPILE_MODEL_MEAN_ABSOLUTE_ERROR: '平均绝对误差',
    TFJS_MODEL_COMPILE_MODEL_CATEGORICAL_CROSSENTROPY: '分类交叉熵',
    TFJS_MODEL_FIT_MODEL_MESSAGE_0: '训练模型 %1 而输入为 %2 和输出为 %3',
    TFJS_MODEL_FIT_MODEL_MESSAGE_1: '选项 %1',
    TFJS_MODEL_FIT_MODEL_MESSAGE_2: '度量 %1',
    TFJS_MODEL_PREDICT_OP_MESSAGE_0: '用模型 %1 来预测张量 %2 ',
    TFJS_MODEL_PREDICT_OP_MESSAGE_1: '储存预测张量为 %1，预测结果为 %2',
    TFJS_MODEL_SAVE_MODEL_TITLE: '储存模型 %1 到localStorage并命名为 %2',
    TFJS_MODEL_LOAD_MODEL_TITLE: '由localStorage加载名称为 %1 的模型 %2',
  }

  EnTfjsInteraction = {
    TFJS_INTERACTION_CANVAS_TITLE: 'Show canvas input',
    TFJS_INTERACTION_INPUT_TITLE: 'Input as %1',
    TFJS_INTERACTION_INPUT_MESSAGE_1: 'Do %1',
    TFJS_INTERACTION_INPUT_IMAGE_UPLOAD: 'image (upload)',
    TFJS_INTERACTION_INPUT_IMAGE_URL: 'image (url)',
    TFJS_INTERACTION_INPUT_WEBCAM: 'webcam',
    TFJS_INTERACTION_INPUT_CANVAS: 'canvas',
    TFJS_INTERACTION_INPUT_TEXT: 'text',
    TFJS_INTERACTION_INPUT_INPUT: 'Input: '
  }

  ZhHantTfjsInteraction = {
    TFJS_INTERACTION_CANVAS_TITLE: '顯示畫布輸入',
    TFJS_INTERACTION_INPUT_TITLE: '輸入來源為 %1',
    TFJS_INTERACTION_INPUT_MESSAGE_1: '執行 %1',
    TFJS_INTERACTION_INPUT_IMAGE_UPLOAD: '圖片（上載）',
    TFJS_INTERACTION_INPUT_IMAGE_URL: '圖片（URI）',
    TFJS_INTERACTION_INPUT_WEBCAM: '視像機',
    TFJS_INTERACTION_INPUT_CANVAS: '畫布',
    TFJS_INTERACTION_INPUT_TEXT: '文字',
    TFJS_INTERACTION_INPUT_INPUT: '輸入：'
  }

  ZhHansTfjsInteraction = {
    TFJS_INTERACTION_CANVAS_TITLE: '显示画布输入',
    TFJS_INTERACTION_INPUT_TITLE: '输入来源为 %1',
    TFJS_INTERACTION_INPUT_MESSAGE_1: '执行 %1',
    TFJS_INTERACTION_INPUT_IMAGE_UPLOAD: '图片（上载）',
    TFJS_INTERACTION_INPUT_IMAGE_URL: '图片（URI）',
    TFJS_INTERACTION_INPUT_WEBCAM: '视像机',
    TFJS_INTERACTION_INPUT_CANVAS: '画布',
    TFJS_INTERACTION_INPUT_TEXT: '文字',
    TFJS_INTERACTION_INPUT_INPUT: '输入：'
  }

  EnTfjsUtilities = {
    TFJS_UTILITIES_TENSOR_MIN_TITLE: '%1 minimum',
    TFJS_UTILITIES_TENSOR_MAX_TITLE: '%1 maximum',
    TFJS_UTILITIES_DATASYNC_TITLE: 'get data from %1',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_0: 'Draw scatter plot',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_1: 'Title %1',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_2: 'Plot data %1',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_3: 'x-axis label %1',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_4: 'y-axis label %1',
    TFJS_UTILITIES_DRAW_MODEL_SUMMARY_TITLE: 'Draw model summary of %1',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_0: 'Draw confusion matrix for Iris flower test data',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_1: 'Model %1',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_2: 'Title %1',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_3: 'Features %1',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_4: 'Classes %1',
    TFJS_UTILITIES_TIDY_TITLE: 'Prevent Memory Leak',
  }

  ZhHantTfjsUtilities = {
    TFJS_UTILITIES_TENSOR_MIN_TITLE: '%1 最小值',
    TFJS_UTILITIES_TENSOR_MAX_TITLE: '%1 最大值',
    TFJS_UTILITIES_DATASYNC_TITLE: '從張量 %1 提取數據',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_0: '繪製散佈圖',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_1: '標題 %1',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_2: '數據 %1',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_3: '橫軸標題 %1',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_4: '縱軸標題 %1',
    TFJS_UTILITIES_DRAW_MODEL_SUMMARY_TITLE: '繪製模型 %1 總結',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_0: '繪製鳶尾花分類的混淆矩陣',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_1: '模型 %1',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_2: '標題 %1',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_3: '特徵 %1',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_4: '分類 %1',
    TFJS_UTILITIES_TIDY_TITLE: '避免記憶體泄漏',
  }

  ZhHansTfjsUtilities = {
    TFJS_UTILITIES_TENSOR_MIN_TITLE: '%1 最小值',
    TFJS_UTILITIES_TENSOR_MAX_TITLE: '%1 最大值',
    TFJS_UTILITIES_DATASYNC_TITLE: '从张量 %1 提取数据',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_0: '绘製散佈图',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_1: '标题 %1',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_2: '数据 %1',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_3: '横轴标题 %1',
    TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_4: '纵轴标题 %1',
    TFJS_UTILITIES_DRAW_MODEL_SUMMARY_TITLE: '绘製模型 %1 总结',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_0: '绘製鸢尾花分类的混淆矩阵',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_1: '模型 %1',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_2: '标题 %1',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_3: '特徵 %1',
    TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_4: '分类 %1',
    TFJS_UTILITIES_TIDY_TITLE: '避免内存泄漏',
  }

  EnCommon = {
    OBJECT_CREATE_EMPTY_OBJECT: 'create empty object',
    OBJECT_CREATE_OBJECT_WITH: 'create object with',
    OBJECT_JSON_TITLE: '%1 as JSON',
    TIME_DELAY_TITLE: 'Delay %1 millisecond',
    TIME_INTERVAL_MESSAGE_0: 'Every %1 millisecond',
    TIME_INTERVAL_MESSAGE_1: 'Do %1',
    OUTPUT_TEXT_TITLE: 'print %1',
    OUTPUT_BAR_MESSAGE_0: 'print probabilities %1 in graphical representation',
    OUTPUT_BAR_MESSAGE_1: 'label key: %1, probability key: %2',
  }

  ZhHantCommon = {
    OBJECT_CREATE_EMPTY_OBJECT: '創建空物件',
    OBJECT_CREATE_OBJECT_WITH: '從創建物件',
    OBJECT_JSON_TITLE: '以Json格式匯出 %1',
    TIME_DELAY_TITLE: '延遲 %1 毫秒',
    TIME_INTERVAL_MESSAGE_0: '每 %1 毫秒',
    TIME_INTERVAL_MESSAGE_1: '執行 %1',
    OUTPUT_TEXT_TITLE: '輸出 %1',
    OUTPUT_BAR_MESSAGE_0: '以長條圖輸出機率分佈 %1',
    OUTPUT_BAR_MESSAGE_1: '標簽鍵：%1, 機率鍵：%2',
  }

  ZhHansCommon = {
    OBJECT_CREATE_EMPTY_OBJECT: '创建空物件',
    OBJECT_CREATE_OBJECT_WITH: '从创建物件',
    OBJECT_JSON_TITLE: '以Json格式汇出 %1',
    TIME_DELAY_TITLE: '延迟 %1 毫秒',
    TIME_INTERVAL_MESSAGE_0: '每 %1 毫秒',
    TIME_INTERVAL_MESSAGE_1: '执行 %1',
    OUTPUT_TEXT_TITLE: '输出 %1',
    OUTPUT_BAR_MESSAGE_0: '以长条图输出机率分佈 %1',
    OUTPUT_BAR_MESSAGE_1: '标签键：%1, 机率键：%2',
  }

  EnRequest = {
    REQUEST_GET_MESSAGE_1: 'Get event data and store to %1',
    REQUEST_GET_MESSAGE_2: 'API Key %1',
    REQUEST_POST_MESSAGE_1: 'Post event data',
    REQUEST_POST_MESSAGE_2: 'API Key %1',
    REQUEST_POST_MESSAGE_3: 'Data %1',
    REQUEST_GET_2_MESSAGE_0: 'Get request and store to %1',
    REQUEST_GET_2_MESSAGE_1: 'URL %1',
    REQUEST_GET_2_MESSAGE_2: 'Query Parameters %1',
    REQUEST_POST_2_MESSAGE_0: 'Post request and store to %1',
    REQUEST_POST_2_MESSAGE_1: 'URL %1',
    REQUEST_POST_2_MESSAGE_2: 'Query Parameters %1',
    REQUEST_POST_2_MESSAGE_3: 'Headers %1',
    REQUEST_POST_2_MESSAGE_4: 'Data %1',
  }

  ZhHantRequest = {
    REQUEST_GET_MESSAGE_1: '接收數據並儲存到 %1',
    REQUEST_GET_MESSAGE_2: 'API鑰匙 %1',
    REQUEST_POST_MESSAGE_1: '上載數據',
    REQUEST_POST_MESSAGE_2: 'API鑰匙 %1',
    REQUEST_POST_MESSAGE_3: '數據 %1',
    REQUEST_GET_2_MESSAGE_0: '接收數據並儲存到 %1',
    REQUEST_GET_2_MESSAGE_1: 'URL %1',
    REQUEST_GET_2_MESSAGE_2: '參數 %1',
    REQUEST_POST_2_MESSAGE_0: '上載數據並儲存到 %1',
    REQUEST_POST_2_MESSAGE_1: 'URL %1',
    REQUEST_POST_2_MESSAGE_2: '參數 %1',
    REQUEST_POST_2_MESSAGE_3: '頭欄位 %1',
    REQUEST_POST_2_MESSAGE_4: '數據 %1',
  }

  ZhHansRequest = {
    REQUEST_GET_MESSAGE_1: '接收数据并储存到 %1',
    REQUEST_GET_MESSAGE_2: 'API钥匙 %1',
    REQUEST_POST_MESSAGE_1: '上载数据',
    REQUEST_POST_MESSAGE_2: 'API钥匙 %1',
    REQUEST_POST_MESSAGE_3: '数据 %1',
    REQUEST_GET_2_MESSAGE_0: '接收数据并储存到 %1',
    REQUEST_GET_2_MESSAGE_1: 'URL %1',
    REQUEST_GET_2_MESSAGE_2: '参数 %1',
    REQUEST_POST_2_MESSAGE_0: '上载数据并储存到 %1',
    REQUEST_POST_2_MESSAGE_1: 'URL %1',
    REQUEST_POST_2_MESSAGE_2: '参数 %1',
    REQUEST_POST_2_MESSAGE_3: '头栏位 %1',
    REQUEST_POST_2_MESSAGE_4: '数据 %1',
  }

  En = {
    ...En,
    ...this.EnCategory,
    ...this.EnFaceDetection,
    ...this.EnHandDetection,
    ...this.EnObjectDetection,
    ...this.EnPoseDetection,
    ...this.EnTeachable,
    ...this.EnTfjsTensor,
    ...this.EnTfjsDataset,
    ...this.EnTfjsLayer,
    ...this.EnTfjsModel,
    ...this.EnTfjsInteraction,
    ...this.EnTfjsUtilities,
    ...this.EnCommon,
    ...this.EnRequest
  };

  ZhHant = {
    ...ZhHant,
    ...this.ZhHantCategory,
    ...this.ZhHantFaceDetection,
    ...this.ZhHantHandDetection,
    ...this.ZhHantObjectDetection,
    ...this.ZhHantPoseDetection,
    ...this.ZhHantTeachable,
    ...this.ZhHantTfjsTensor,
    ...this.ZhHantTfjsDataset,
    ...this.ZhHantTfjsLayer,
    ...this.ZhHantTfjsModel,
    ...this.ZhHantTfjsInteraction,
    ...this.ZhHantTfjsUtilities,
    ...this.ZhHantCommon,
    ...this.ZhHantRequest,
  };

  ZhHans = {
    ...ZhHans,
    ...this.ZhHansCategory,
    ...this.ZhHansFaceDetection,
    ...this.ZhHansHandDetection,
    ...this.ZhHansObjectDetection,
    ...this.ZhHansPoseDetection,
    ...this.ZhHansTeachable,
    ...this.ZhHansTfjsTensor,
    ...this.ZhHansTfjsDataset,
    ...this.ZhHansTfjsLayer,
    ...this.ZhHansTfjsModel,
    ...this.ZhHansTfjsInteraction,
    ...this.ZhHansTfjsUtilities,
    ...this.ZhHansCommon,
    ...this.ZhHansRequest,
  };

  blocklyLocales = {
    'en': this.En,
    'zh-hant': this.ZhHant,
    'zh-hans': this.ZhHans,
  }
}
