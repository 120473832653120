<div class="card-container" fxLayout="row">
  <app-card-blockly [disabled]="disabled"
                            [fxFlex]="flexBlockly()" 
                            [mode]="mode" 
                            [ngClass]="{'px-0': mode != Mode.BLOCKLY && mode != Mode.NORMAL}"
                            (zoom)="zoom(Mode.BLOCKLY)">
  </app-card-blockly>
  <div [fxFlex]="flexRight()">
    <as-split unit="percent" direction="vertical" [useTransition]="true">
      <as-split-area [size]="flexCode()">
        <app-card-console [disabled]="disabled" [mode]="mode"
          (zoom)="zoom(Mode.CODE)">
        </app-card-console>
      </as-split-area>
      <as-split-area [size]="flexInputs()">
        <app-card-inputs [disabled]="disabled" [mode]="mode"
          (zoom)="zoom(Mode.INPUTS)">
        </app-card-inputs>
      </as-split-area>
      <as-split-area [size]="flexResult()">
        <app-card-result [mode]="mode"
          (zoom)="zoom(Mode.RESULT)">
        </app-card-result>
      </as-split-area>
    </as-split>
  </div>
</div>
