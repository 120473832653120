import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class KernelInitializerBlock implements CustomBlock {
  type = 'tfjs_kernel_initializer';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%1',
      args0: [
        {
          type: 'field_dropdown',
          name: 'KERNEL',
          options: [
            ['varianceScaling', 'varianceScaling'],
          ]
        }
      ],
      output: 'string',
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const kernel = block.getFieldValue('KERNEL');
    return [`'${kernel}'`, Blockly.JavaScript.ORDER_NONE];
  }
}
