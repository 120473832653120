import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { CodeService } from '../../shared';

@Component({
  selector: 'app-card-code',
  templateUrl: './card-code.component.html',
  styleUrls: ['./card-code.component.scss']
})

export class CardCodeComponent implements OnInit, OnDestroy {

  @ViewChild('code', { static: true }) code: HTMLElement;
  @Input() disabled: boolean = false;

  subscription: Subscription[] = [];
  sourceCode: string = '';
  
  constructor(
    private codeService: CodeService,
  ) { }

  ngOnInit() {
    this.subscription.push(
      this.codeService.code$.subscribe(code => this.sourceCode = code),
    );
  }

  ngOnDestroy() {
    this.subscription.map(sub => sub.unsubscribe());
  }

  copyCode() {
    navigator.clipboard.writeText(this.sourceCode);
  }
}
