import { CustomBlock } from '../custom-block';
import { ApiEndpoint } from './const';

import * as Blockly from 'blockly/core';

export class FetchPostBlock implements CustomBlock {
  type = 'request_fetch_post';
  
  defineBlock(block) {
    const blockJson = {
      style: 'request_blocks',
      message0: '%1',
      args0: [
        {
          type: 'field_image',
          src: 'http://cocorobo.hk/cocoblockly/blockly/media/blockly-easymode_cococloud.png',
          width: 180,
          height: 40,
          alt: '*'
        }
      ],
      message1: '%{BKY_REQUEST_POST_MESSAGE_1}',
      message2: '%{BKY_REQUEST_POST_MESSAGE_2}',
      args2: [{
        type: 'field_input',
        name: 'API_KEY',
      }],
      message3: '%{BKY_REQUEST_POST_MESSAGE_3}',
      args3: [{
        type: 'input_value',
        name: 'DATA',
      }],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const apiKey = block.getFieldValue('API_KEY');
    const data = Blockly.JavaScript.valueToCode(block, 'DATA', Blockly.JavaScript.ORDER_NONE) || '{}';
    return `
    ai.fetch.postCocorobo('${apiKey}', ${data});
    `;
  }
}
