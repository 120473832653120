import { CustomBlock } from '../custom-block';
import outdent from 'outdent';
import * as Blockly from 'blockly/core';

export class LoadIrisCopyData implements CustomBlock {
    type = 'tfjs_load_copy_data';

    defineBlock(block) {
        let blockJson = {
            style: 'tfjs_blocks',
            message0: '%{BKY_TFJS_DATASET_LOAD_IRIS_COPY_DATA_MESSAGE_0}',
            args0: [
                {
                    type: 'field_dropdown',
                    name: 'IRIS',
                    variable: '',
                    options: [
                        ['%{BKY_TFJS_DATASET_LOAD_IRIS_DS_MESSAGE_0}', '1'],
                        // ['%{BKY_TFJS_DATASET_LOAD_IRIS_COPY_DATA_COL_MESSAGE_0}', 'column'],
                    ],
                },
                {
                    type: 'field_input',
                    name: 'DATA',
                    variable: 'labels',
                    spellcheck: false
                },
                {
                    type: 'field_dropdown',
                    name: 'TYPE',
                    options: [
                        ['%{BKY_TFJS_DATASET_LOAD_IRIS_COPY_DATA_LINE_MESSAGE_0}', 'line'],
                        ['%{BKY_TFJS_DATASET_LOAD_IRIS_COPY_DATA_COL_MESSAGE_0}', 'column'],
                    ],
                },
            ],
            output: 'tensor_min',
        };

        block.jsonInit(blockJson);
    }

    toJavaScriptCode(block: Blockly.Block) {
        const iris = (<any>window).irisList;
        const data = (block.getField('DATA').getText()||0)==0?0:block.getField('DATA').getText()-1;
        const type = block.getField('TYPE').getText();
        return [`${iris[data]}`, Blockly.JavaScript.ORDER_NONE];
    }
}
