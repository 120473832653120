import { CustomBlock } from '../custom-block';
import { MODEL_URL } from './const';
import * as changecase from 'change-case';
import outdent from 'outdent';


import * as Blockly from 'blockly/core';

export class LoadFaceDetectorBlock implements CustomBlock {
  type = 'faceapi_load_face_detector';

  fields = {
    ssdMobilenetv1: ['maxResults', 'minConfidence'],
    tinyFaceDetector: ['inputSize', 'scoreThreshold'],
    mtcnn: ['scaleFactor']
  };

  defineBlock(block) {
    const faceDetectorMixin = {
      mutationToDom() {
        const container = document.createElement('mutation');
        container.setAttribute('face_detector', this.getFieldValue('FACE_DETECTOR'));
        return container;
      },

      domToMutation(xml) {
        const detector = xml.getAttribute('face_detector');
        this.updateShape(detector);
      },

      updateShape(detector) {
        const sourceBlock = this;

        const fields = {
          ssdMobilenetv1: ['maxResults', 'minConfidence'],
          tinyFaceDetector: ['inputSize', 'scoreThreshold'],
          mtcnn: ['scaleFactor']
        };

        Object.keys(fields).map(detector => {
          fields[detector].map(input => {
            if (sourceBlock.getInput(input)) {
              sourceBlock.removeInput(input);
            }
          });
        });

        if (detector === 'ssdMobilenetv1') {
          sourceBlock.appendDummyInput('maxResults')
            .appendField(Blockly.Msg.FACE_DETECTION_LOAD_DETECTOR_MAX_RESULTS)
            .appendField(new Blockly.FieldNumber(100, 0, 100, 10), 'maxResults');
          sourceBlock.appendDummyInput('minConfidence')
            .appendField(Blockly.Msg.FACE_DETECTION_LOAD_DETECTOR_MIN_CONFIDENCE)
            .appendField(new Blockly.FieldNumber(0.5, 0, 1, 0.1), 'minConfidence');
        } else if (detector === 'tinyFaceDetector') {
          sourceBlock.appendDummyInput('inputSize')
            .appendField(Blockly.Msg.FACE_DETECTION_LOAD_DETECTOR_INPUT_SIZE)
            .appendField(new Blockly.FieldDropdown([
              ['128', '128'],
              ['160', '160'],
              ['224', '224'],
              ['320', '320'],
              ['416', '416'],
              ['512', '512'],
              ['608', '608'],
            ]), 'inputSize');
          sourceBlock.appendDummyInput('scoreThreshold')
            .appendField(Blockly.Msg.FACE_DETECTION_LOAD_DETECTOR_SCORE_THRESHOLD)
            .appendField(new Blockly.FieldNumber(0.5, 0, 1, 0.1), 'scoreThreshold');
          sourceBlock.setFieldValue('512', 'inputSize');
        } else if (detector === 'mtcnn') {
          sourceBlock.appendDummyInput('scaleFactor')
            .appendField(Blockly.Msg.FACE_DETECTION_LOAD_DETECTOR_SCALE_FACTOR)
            .appendField(new Blockly.FieldNumber(0.5, 0, 1, 0.1), 'scaleFactor');
        }
      }
    };

    const faceDetectorExtension = function () {
      this.getField('FACE_DETECTOR').setValidator(function (detector) {
        this.sourceBlock_.updateShape(detector);
      });
    };

    // This hack is to explore the protected field ALL_ in Extenstions
    // Need to refactor to avoid the direct access
    if (!('faceapi_face_detector_mutator' in Blockly.Extensions.ALL_)) {
      Blockly.Extensions.registerMutator(
        'faceapi_face_detector_mutator',
        faceDetectorMixin,
        faceDetectorExtension
      );
    }

    const blockJson = {
      style: 'faceapi_blocks',
      mutator: 'faceapi_face_detector_mutator',
      message0: '%{BKY_FACE_DETECTION_LOAD_DETECTOR_TITLE}',
      args0: [{
        type: 'field_dropdown',
        name: 'FACE_DETECTOR',
        options: [
          ['ssdMobilenetv1', 'ssdMobilenetv1'],
          ['tinyFaceDetector', 'tinyFaceDetector'],
          ['mtcnn', 'mtcnn'],
        ]
      }]
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const faceDetector: string = block.getFieldValue('FACE_DETECTOR');
    let code = outdent({ trimTrailingNewline: false })`
      if (!faceapi.nets.${faceDetector}.params) {
        await faceapi.nets.${faceDetector}.loadFromUri('${MODEL_URL}')
      };
      `;
    const options = this.fields[faceDetector].filter(field => block.getField(field))
      .reduce((options, field) => {
        options[field] = parseFloat(block.getFieldValue(field));
        return options;
      },
        {});
    const optionName = changecase.pascalCase(faceDetector) + 'Options';
    code += `const options = new faceapi.${optionName}(${JSON.stringify(options)});`;
    return code;
  }
}
