import { CustomBlock } from '../custom-block';

import * as Blockly from 'blockly/core';

export class SetTimeIntervalBlock implements CustomBlock {
  type = 'time_interval';
  
  defineBlock(block) {
    let blockJson = {
      style: 'time_blocks',
      message0: '%{BKY_TIME_INTERVAL_MESSAGE_0}',
      args0: [{
        type: 'field_number',
        name: 'MILLISECOND',
        min: 0
      }],
      message1: '%{BKY_TIME_INTERVAL_MESSAGE_1}',
      args1: [{
          type: 'input_statement',
          name: 'DO'
      }],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const millisecond = block.getFieldValue('MILLISECOND');
    const statementCode = Blockly.JavaScript.statementToCode(block, 'DO');
    return `
    setInterval(() => { ${statementCode} }, ${millisecond});
    `;
  }
}