import { CustomBlock } from '../custom-block';

export class DelayBlock implements CustomBlock {
  type = 'time_delay';
  
  defineBlock(block) {
    let blockJson = {
      style: 'time_blocks',
      message0: '%{BKY_TIME_DELAY_TITLE}',
      args0: [{
        type: 'field_number',
        name: 'MILLISECOND',
        min: 0
      }],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const millisecond = block.getFieldValue('MILLISECOND');
    return `
    const delay = millisecond => new Promise(resolve =>
      setTimeout(resolve, millisecond)
    );
    await delay(${millisecond});
    `;
  }
}