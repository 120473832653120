import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { Mode } from '../blockly.models';
import { CodeService } from '../../shared';
import { CanvasComponent } from '../../shared/canvas/canvas.component';
import { InputUploadComponent } from '../input-upload/input-upload.component';

declare var window;

@Component({
  selector: 'app-card-inputs',
  templateUrl: './card-inputs.component.html',
  styleUrls: ['./card-inputs.component.scss']
})
export class CardInputsComponent implements OnInit, OnDestroy {

  @Input() mode: Mode;
  @Input() disabled: boolean = false;
  @Output() zoom: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(CanvasComponent) inputCanvas: CanvasComponent;
  @ViewChild(InputUploadComponent) inputUpload: InputUploadComponent;
  selectedTab = 0;

  Mode = Mode;

  @HostListener('window:showInput', ['$event'])
  windowShowInput(event) {
    switch (event.detail.type) {
      case 'upload':
        this.selectedTab = 0;
        break;
      case 'canvas':
        this.selectedTab = 1;
        break;
    }
  }

  constructor() { }

  ngOnInit() {
    if (!window.getInputCanvasCropped) {
      window.getInputCanvasCropped = () => {
        return this.inputCanvas.cropCanvas();
      };
    }
  }

  ngOnDestroy() {
  }

  onZoomClick() {
    this.zoom.emit();
  }

  onCanvasDetect() {
    this.inputCanvas.onDetectClick();
  }

  onCanvasClear() {
    this.inputCanvas.onClearClick();
  }

  onUploadDetect() {
    this.inputUpload.onDetectClick();
  }
}
