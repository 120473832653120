import { NgModule } from '@angular/core';

import { SharedModule } from './shared';
import { BlocklyModule } from './blockly';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DatasetDialogComponent } from './dataset-dialog/dataset-dialog.component';
import { DatasetDetailDialogComponent } from './dataset-detail-dialog/dataset-detail-dialog.component';
import { DatasetCanvasDialogComponent } from './dataset-canvas-dialog/dataset-canvas-dialog.component';
import { DatasetWebcamDialogComponent } from './dataset-webcam-dialog/dataset-webcam-dialog.component';
import { DatasetWebcamMultipleDialogComponent } from './dataset-webcam-multiple-dialog/dataset-webcam-multiple-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DatasetDialogComponent,
    DatasetDetailDialogComponent,
    DatasetCanvasDialogComponent,
    DatasetWebcamDialogComponent,
    DatasetWebcamMultipleDialogComponent,
  ],
  imports: [
    SharedModule,
    BlocklyModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    DatasetDialogComponent,
    DatasetDetailDialogComponent,
    DatasetCanvasDialogComponent,
    DatasetWebcamDialogComponent,
    DatasetWebcamMultipleDialogComponent,
  ]
})
export class AppModule { }
