import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dataset-webcam-dialog',
  templateUrl: './dataset-webcam-dialog.component.html',
  styleUrls: ['./dataset-webcam-dialog.component.scss']
})
export class DatasetWebcamDialogComponent implements OnInit, OnDestroy {

  @ViewChild('video') video: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<DatasetWebcamDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const stream = navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: 'user'
        }
      });
    stream.then((source) => {
      this.video.nativeElement.srcObject = source;
    })
    this.video.nativeElement.onloadedmetadata = () => {
      this.video.nativeElement.play();
    }
  }

  ngOnDestroy() {
    (this.video.nativeElement.srcObject as MediaStream)?.getTracks().map(track => track.stop());
    this.video.nativeElement.srcObject = null;
  }

  onSave() {
    const vid = this.video.nativeElement;
    const temp = document.createElement('canvas');
    const tCtx = temp.getContext('2d');
    temp.width = 227;
    temp.height = 227;
    tCtx.drawImage(vid, 0, 0, 227, 227);
    this.dialogRef.close(temp.toDataURL('image/jpeg'));
  }

}
