import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly';
import { outdent } from 'outdent';

export class LoadMnistBlock implements CustomBlock {
  type = 'tfjs_load_mnist';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_DATASET_LOAD_MNIST_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'DATA',
          variable: 'data'
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const data = block.getField('DATA').getText();
    return outdent`
    ${data} = new MnistData();
    await ${data}.load();

    const surface = tfvis.visor().surface({ name: 'Input Data Examples', tab: 'Input Data'});  
    const examples = ${data}.nextTestBatch(20);
    const numExamples = examples.xs.shape[0];
    
    for (let i = 0; i < numExamples; i++) {
      const imageTensor = tf.tidy(() => {
        return examples.xs
          .slice([i, 0], [1, examples.xs.shape[1]])
          .reshape([28, 28, 1]);
      });
      
      const canvas = document.createElement('canvas');
      canvas.width = 28;
      canvas.height = 28;
      canvas.style = 'margin: 4px;';
      await tf.browser.toPixels(imageTensor, canvas);
      surface.drawArea.appendChild(canvas);
  
      imageTensor.dispose();
    }
    `;
  }
}
