import * as Blockly from 'blockly/core';
import { Block } from 'blockly';
import { CustomBlock } from '../custom-block';

import outdent from 'outdent';

export class DrawObjectDetectionBlock implements CustomBlock {
  type = 'object_draw_detection';

  defineBlock(block) {
    const blockJson = {
      style: 'object_detection_blocks',
      message0: '%{BKY_OBJECT_DETECTION_DRAW_DETECTION_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'DETECTION',
          variable: 'objectDetection',
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };
    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const detection = block.getField('DETECTION').getVariable().name;
    const code = outdent({ trimTrailingNewline: false })`
      ai.object.renderPredictions(objectDetection, input);
    `;
    return code;
  }
}
