import { CustomBlock } from '../custom-block';
import outdent from 'outdent';
import * as Blockly from 'blockly/core';

export class IrisAttribute implements CustomBlock {
  type = 'iris_attribute';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_DATASET_LOAD_IRIS_ATTRIBUTE_MESSAGE_0}',
      args0: [
        {
            type: 'field_dropdown',
            name: 'ATTRIBUTE',
            variable: '',
            options: [
              ['%{BKY_TFJS_DATASET_LOAD_IRIS_LONG_MESSAGE_0}', '%{BKY_TFJS_DATASET_LOAD_IRIS_LONG_MESSAGE_0}'],
              ['%{BKY_TFJS_DATASET_LOAD_IRIS_WIDE_MESSAGE_0}', '2'],
              ['%{BKY_TFJS_DATASET_LOAD_IRIS_SPECIES_MESSAGE_0}', '3'],
            ],
        },
      ],
      output: 'tensor_max',
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const tensor = block.getField('ATTRIBUTE').getText();
    return [`${tensor}`, Blockly.JavaScript.ORDER_NONE];
  }
}
