import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

// overpowered prediction block
export class PredictOPBlock implements CustomBlock {
  type = 'tfjs_predict_OP';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      inputsInline: false,
      message0: '%{BKY_TFJS_MODEL_PREDICT_OP_MESSAGE_0}',
      args0: [
        {
          type: 'field_variable',
          name: 'MODEL',
          variable: 'model',
        },
        {
          type: 'field_variable',
          name: 'TENSOR',
          variable: 'tensor',
        },
      ],
      message1: '%{BKY_TFJS_MODEL_PREDICT_OP_MESSAGE_1}',
      args1: [
        {
          type: 'field_variable',
          name: 'RESULT_TENSOR',
          variable: 'resultTensor',
        },
        {
          type: 'field_variable',
          name: 'RESULT',
          variable: 'result',
        }
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const model = block.getField('MODEL').getText();
    const tensor = block.getField('TENSOR').getText();
    const resultTensor = block.getField('RESULT_TENSOR').getText();
    const result = block.getField('RESULT').getText();
    
    return outdent`
      ${resultTensor} = ${model}.predict(${tensor});
      ${result} = Array.from(${resultTensor}.dataSync());
    `;
  }
}
