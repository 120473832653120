import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly';
import { outdent } from 'outdent';

export class LoadIrisType implements CustomBlock {
  type = 'tfjs_load_data_type';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_DATASET_LOAD_IRIS_DATA_TYPE_MESSAGE_0}',
      args0: [
        {
          type: 'input_value',
          name: 'INITIALIZE',
          variable: '',
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    return outdent`
    [${block.getField('INITIALIZE').getText()}];
    `;
  }
}
