import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class AddDenseLayerBlock implements CustomBlock {
  type = 'tfjs_add_dense_layer';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_LAYER_ADD_DENSE_LAYER_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'MODEL',
          variable: 'model'
        },
        {
          type: 'input_value',
          name: 'OPTIONS',
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const model = block.getField('MODEL').getText();
    const options = Blockly.JavaScript.valueToCode(block, 'OPTIONS', Blockly.JavaScript.ORDER_NONE) || `''`;
    return outdent`
    ${model}.add(tf.layers.dense(${options}));
    `;

  }
}
