import { CustomBlock } from '../custom-block';
import { Block } from 'blockly';
import { outdent } from 'outdent';

declare var window: any;

export class CreateTeachableModelBlock implements CustomBlock {
  type = 'create_teachable_model';

  defineBlock(block) {
    let options = window.customDatasets?.filter(({ title, key }) => title && key)
      .map(({ title, key }) => ([title, key]));

    if (!options || options.length === 0) {
      options = [['None', 'None']];
    }

    let blockJson = {
      style: 'teachable_blocks',
      message0: '%{BKY_TEACHABLE_CREATE_MODEL_MESSAGE_0}',
      args0: [
        {
          type: 'field_variable',
          name: 'MODEL',
          variable: 'model',
        },
        {
          type: 'field_dropdown',
          name: 'KEY',
          options: options
        }
      ],
      message1: '%{BKY_TEACHABLE_CREATE_MODEL_MESSAGE_1}',
      args1: [
        {
          type: 'field_number',
          name: 'EPOCH',
          value: 50,
        },
      ],
      message2: '%{BKY_TEACHABLE_CREATE_MODEL_MESSAGE_2}',
      args2: [
        {
          type: 'field_number',
          name: 'BATCH',
          value: 10,
        },
      ],

      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Block) {
    const model = block.getField('MODEL').getText();
    const datasetKey = block.getFieldValue('KEY');
    const epoch = block.getFieldValue('EPOCH');
    const batch = block.getFieldValue('BATCH');
    return outdent`
    const dataset = customDatasets.find(set => set.key == '${datasetKey}');
    const labels = [...new Set(dataset.data.map(datum => datum.label))];
    ${model} = await teachableMachine.create();
    ${model}.setLabels(labels);

    for (let {imageType, imageData, imageURL, label} of dataset.data) {
      if ((imageType == ai.DatumImageType.URL && imageURL) || (imageType != ai.DatumImageType.URL && imageData)) {
        const img = await new Promise(resolve => {
          const img = document.createElement('IMG');
          img.width = 224;
          img.height = 224;
          img.src = (imageType == ai.DatumImageType.URL) ? imageURL : imageData;
          img.onload = () => {
            resolve(img);
          };
        });
        ${model}.addExample(labels.indexOf(label), img);
      }
    }
    await ${model}.train({
      denseUnits: 100,
      epochs: ${epoch},
      batchSize: ${batch},
    }, tfvis.show.fitCallbacks({
      name: 'Training Preformance'
    }, ['loss', 'mse'], {
      height: 200,
      callbacks: ['onEpochEnd']
    }));
    `;
  }
}
