import * as Blockly from 'blockly/core';
import { Block } from 'blockly';
import { CustomBlock } from '../custom-block';

import outdent from 'outdent';

export class DrawHandDetectionBlock implements CustomBlock {
  type = 'handtrack_draw_detection';

  defineBlock(block) {
    const blockJson = {
      style: 'handtrack_blocks',
      message0: '%{BKY_HAND_DETECTION_DRAW_DETECTION_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'DETECTION',
          variable: 'handtrackDetection',
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };
    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const detection = block.getField('DETECTION').getVariable().name;
    const code = outdent({ trimTrailingNewline: false })`
      const output = document.getElementById('output');
      model.renderPredictions(${detection}, output, output.getContext('2d'), input);
    `;
    return code;
  }
}
