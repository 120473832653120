<h1 mat-dialog-title>{{'Custom Dataset' | translate}}</h1>
<div mat-dialog-content>
  <mat-action-list *ngIf="datasets$ | async as datasets">
    <button mat-list-item *ngFor="let dataset of datasets"
      (click)="onClick(dataset.key)">
      <ng-container *ngIf="dataset.title; else untitled">
        {{dataset.title}}
      </ng-container>
      <ng-template #untitled>
        <span class="untitled">{{'Untitled' | translate}}</span>
      </ng-template>
      <div class="spacer"></div>
      <span>{{dataset.data.length}} {{'item' | translate}}<ng-container *ngIf="currentLang == 'en' && dataset.data.length > 1">s</ng-container></span>
      <button mat-button class="icon-button" (click)="onTrash(dataset.key, $event)" [matTooltip]="'Remove' | translate"><fa-icon icon="trash"></fa-icon></button>
    </button>
  </mat-action-list>  
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onAdd()">{{'Add new Dataset' | translate}}</button>
  <input type="file" #file (change)="onImportChange(file)"/>
  <button mat-button (click)="onImport(file)">{{'Import' | translate}}</button>
  <div class="spacer"></div>
  <button mat-button mat-dialog-close>{{'Close' | translate}}</button>
</div>
