import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { StorageService, BlocklyService, DatasetService } from './shared';
import { TranslateService } from '@ngx-translate/core';
import * as Bowser from 'bowser';
import { LocaleService } from './shared/locale/locale.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'angular-blockly';
  currentLocale: string;

  constructor(
    private snackbar: MatSnackBar,
    private storage: StorageService,
    private blocklyService: BlocklyService,
    private datasetService: DatasetService,
    private translateService: TranslateService,
    private localeService: LocaleService,
  ) {
    this.localeService.locale$.subscribe(locale => {
      this.currentLocale = locale;
    })
   }

  ngOnInit() {
    const val = location.search.match(new RegExp('[?&]lang=([^&]+)'));
    let language = val ? decodeURIComponent(val[1].replace(/\+/g, '%20')) : '';
    if (language) {
      localStorage.setItem('locale', language)
      this.localeService.updateLocale(language);
    } else {
      this.localeService.updateLocale(navigator.language == "zh-CN"?"zh-hans":navigator.language == "zh-HK"?"zh-hant":"en");
    }
    // const browser = Bowser.getParser(window.navigator.userAgent);
    // const isChrome = browser.getResult().engine.name == 'Blink';
    // let message;
    // if (isChrome) 
    //   message = 'This is an experimental tool, please make sure you are running on a computer with high performance.';
    // else
    //   message = 'This is an experimental tool, please make sure you are running on a computer with high performance. This is best optimized for Google Chrome based browsers.';

    // this.translateService.get(message).subscribe((resource: string) => {
    //   this.snackbar.open(resource, 'OK', { horizontalPosition: 'left' });
    // })

    if (window.location.hostname.indexOf("beta") != -1) {
      document.getElementById("sign").innerHTML = "Coco AI Blockly (beta)";
    }

    window.onbeforeunload = () => this.ngOnDestroy();
    if (localStorage.getItem('BLOCKLY_XML'))
      setTimeout(() => this.storage.updateImportXml(localStorage.getItem('BLOCKLY_XML')));
  }

  ngOnDestroy() {
    this.storage.exportXml$.pipe(
      first()
    ).subscribe(xml => localStorage.setItem('BLOCKLY_XML', xml));
  }
}
