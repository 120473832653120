import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import {
  DialogComponent, 
  Datum, DatumImageType, Dataset, DatasetService
} from '../shared';
import { DatasetCanvasDialogComponent } from '../dataset-canvas-dialog/dataset-canvas-dialog.component';
import { DatasetWebcamDialogComponent } from '../dataset-webcam-dialog/dataset-webcam-dialog.component';
import { DatasetWebcamMultipleDialogComponent } from '../dataset-webcam-multiple-dialog/dataset-webcam-multiple-dialog.component';

@Component({
  selector: 'app-dataset-detail-dialog',
  templateUrl: './dataset-detail-dialog.component.html',
  styleUrls: ['./dataset-detail-dialog.component.scss']
})
export class DatasetDetailDialogComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  datumImageTypes = DatumImageType;
  datasets$;
  dataset: Dataset;
  dataSource: MatTableDataSource<Datum>;
  displayedColumns = ['action', 'image', 'label', 'trash'];
  @ViewChild(MatTable) table;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private datasetService: DatasetService,
    @Inject(MAT_DIALOG_DATA) public data: { datasetKey: string },
    private dialogRef: MatDialogRef<DatasetDetailDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.subscription = this.datasetService.datasets$.pipe(
      tap(datasets => {
        this.dataset = JSON.parse(JSON.stringify(datasets.find(s => s.key == this.data.datasetKey)));
        this.dataSource = new MatTableDataSource(this.dataset.data);
      })
    ).subscribe(datasets => {
      setTimeout(() => {
        this.dataSource.sort = this.sort;
      })
    });
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  onAdd() {
    this.dataset.data.push({
      imageType: DatumImageType.Upload,
      imageData: undefined,
      imageURL: undefined,
      label: '',
    });
    this.dataSource = new MatTableDataSource(this.dataset.data);
    this.dataSource.sort = this.sort;
  }

  onFileClick(fileInput) {
    fileInput.click();
  }

  onFileChange(fileInput, id) {
    if (fileInput.files.length > 0) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.dataset.data[id].imageData = e.target.result;
      }
      reader.readAsDataURL(fileInput.files[0]);
    }
  }

  onURLClick(id) {
    this.dataset.data[id].imageType = DatumImageType.URL;
  }

  onCanvasClick(id) {
    this.dataset.data[id].imageType = DatumImageType.Canvas;
    const canvasRef = this.dialog.open(DatasetCanvasDialogComponent, {
      disableClose: true,
    });
    canvasRef.beforeClosed().subscribe(data => {
      if (data)
        this.dataset.data[id].imageData = data;
    });
  }

  onWebcamClick(id) {
    this.dataset.data[id].imageType = DatumImageType.Webcam;
    const canvasRef = this.dialog.open(DatasetWebcamDialogComponent, {
      disableClose: true,
    });
    canvasRef.beforeClosed().subscribe(data => {
      if (data)
        this.dataset.data[id].imageData = data;
    });
  }

  onSave() {
    try {
      this.datasetService.updateDataset(this.data.datasetKey, this.dataset.title, this.dataset.key, this.dataset.data);
      this.dialogRef.close();
    } catch (e) {
      this.dialog.open(DialogComponent, {
        width: '300px',
        data: {
          loremipsum: 'Exceeded browser local storage memory limit',
          actions: {
            ok: true
          }
        }
      });
    }
  }

  onTrash(i) {
    this.dataset.data.splice(i, 1);
    this.dataSource = new MatTableDataSource(this.dataset.data);
    this.dataSource.sort = this.sort;
  }

  onBulkUpload(files: FileList) {
    Array.from(files).map(file => {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.dataset.data.push({
          imageType: DatumImageType.Upload,
          imageData: event.target.result,
          imageURL: undefined,
          label: '',
        });
        this.dataSource = new MatTableDataSource(this.dataset.data);
        this.dataSource.sort = this.sort;
      };

      reader.readAsDataURL(file);
    });
  }

  onBulkWebcam() {
    const canvasRef = this.dialog.open(DatasetWebcamMultipleDialogComponent, {
      disableClose: true,
    });
    canvasRef.beforeClosed().subscribe(data => {
      if (data) {
        data.images.map(image => {
          this.dataset.data.push({
            imageType: DatumImageType.Webcam,
            imageData: image,
            imageURL: undefined,
            label: data.label,
          });
          this.dataSource = new MatTableDataSource(this.dataset.data);
          this.dataSource.sort = this.sort;
        })
      }
    });
  }

  onExport() {
    const toExport = JSON.stringify(this.dataset);
    const blob = new Blob([toExport], { type: 'text/plain' });
    saveAs(blob, `dataset-${this.dataset.title}.json`);
  }
}
