import { FaceapiImage } from '../../block/face-api/example/faceapi-image';
import { FaceapiWebcam } from '../../block/face-api/example/faceapi-webcam';
import { FaceapiPost } from '../../block/face-api/example/faceapi-post';
import { TensorCars } from '../../block/tfjs/example/tensor-cars';
import { TensorMnist } from '../../block/tfjs/example/tensor-mnist';
import { TensorKnn } from '../../block/tfjs/example/tensor-knn';
import { TensorIris } from '../../block/tfjs/example/tensor-iris';
import { HandtrackExample } from '../../block/handtrack/example/handtrack';
import { ObjectDetectionExample } from 'src/app/block/object-detection/example/object-detection';
import { TeachableExample } from '../../block/teachable/example';
import { PoseDetectionExample } from 'src/app/block/pose-detection/example/pose-detection';

export enum Example {
  Tensor2D,
  TensorMnist,
  TensorKnn,
  TensorIris,
  FaceImage,
  FaceVideo,
  FacePost,
  Handtrack,
  ObjectDetection,
  Teachable,
  PoseDetection,
}

export const ExampleMap = {
  [Example.Tensor2D]: TensorCars,
  [Example.TensorMnist]: TensorMnist,
  [Example.TensorKnn]: TensorKnn,
  [Example.TensorIris]: TensorIris,
  [Example.FaceImage]: FaceapiImage,
  [Example.FaceVideo]: FaceapiWebcam,
  [Example.FacePost]: FaceapiPost,
  [Example.Handtrack]: HandtrackExample,
  [Example.ObjectDetection]: ObjectDetectionExample,
  [Example.Teachable]: TeachableExample,
  [Example.PoseDetection]: PoseDetectionExample,
};
