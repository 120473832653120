import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly';
import { outdent } from 'outdent';

export class LoadJsonBlock implements CustomBlock {
  type = 'tfjs_load_json';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_DATASET_LOAD_JSON_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'DATA',
          variable: 'data'
        },

        {
          type: 'field_input',
          name: 'URL',
          text: 'https://storage.googleapis.com/tfjs-tutorials/carsData.json',
          spellcheck: false
        }
      ],
      previousStatement: null,
      nextStatement: null
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const url = block.getFieldValue('URL');
    const data = block.getField('DATA').getText();
    const code = outdent`
    const request = await fetch('${url}');
    ${data} = await request.json();
    `;

    return code;
  }
}
