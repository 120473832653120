import { CustomBlock } from '../custom-block';
import outdent from 'outdent';
import * as Blockly from 'blockly/core';

export class TensorDatasyncBlock implements CustomBlock {
  type = 'tfjs_tensor_datasync';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_UTILITIES_DATASYNC_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'TENSOR',
          variable: 'tensor',
        },
      ],
      output: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const tensor = block.getField('TENSOR').getText();
    return [`${tensor}.dataSync()`, Blockly.JavaScript.ORDER_NONE];
  }
}
