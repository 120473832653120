import { CustomBlock } from '../custom-block';
import { ApiEndpoint } from './const';

export class FetchGetBlock implements CustomBlock {
  type = 'request_fetch_get';
  
  defineBlock(block) {
    const blockJson = {
      style: 'request_blocks',
      message0: '%1',
      args0: [
        {
          type: 'field_image',
          src: 'http://cocorobo.hk/cocoblockly/blockly/media/blockly-easymode_cococloud.png',
          width: 180,
          height: 40,
          alt: '*'
        }
      ],
      message1: '%{BKY_REQUEST_GET_MESSAGE_1}',
      args1 : [{
        type: 'field_variable',
        name: 'RESPONSE',
        variable: 'response',
      }],
      message2: '%{BKY_REQUEST_GET_MESSAGE_2}',
      args2: [{
        type: 'field_input',
        name: 'API_KEY',
      }],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const response = block.getField('RESPONSE').getText();
    const apiKey = block.getFieldValue('API_KEY');
    return `
    ${response} = await ai.fetch.getCocorobo('${apiKey}');
    `;
  }
}
