import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dataset-webcam-multiple-dialog',
  templateUrl: './dataset-webcam-multiple-dialog.component.html',
  styleUrls: ['./dataset-webcam-multiple-dialog.component.scss']
})
export class DatasetWebcamMultipleDialogComponent implements OnInit, OnDestroy {

  capturing = false;
  label = '';
  images = [];
  timer;
  interval = 150;
  @ViewChild('video') video: ElementRef;
  @ViewChild('rightDiv') rightDiv: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<DatasetWebcamMultipleDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const stream = navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: 'user'
        }
      });
    stream.then((source) => {
      this.video.nativeElement.srcObject = source;
    })
    this.video.nativeElement.onloadedmetadata = () => {
      this.video.nativeElement.play();
      this.timer = requestAnimationFrame(this.frame.bind(this));
    }
  }

  ngOnDestroy() {
    (this.video.nativeElement.srcObject as MediaStream)?.getTracks().map(track => track.stop());
    this.video.nativeElement.srcObject = null;
    cancelAnimationFrame(this.timer);
  }

  onSave() {
    this.dialogRef.close({
      label: this.label,
      images: this.images,
    });
  }

  onMouseUp() {
    this.capturing = false;
  }

  onMouseDown() {
    this.capturing = true;
  }

  async frame() {
    if (this.capturing) {
      const vid = this.video.nativeElement;
      const temp = document.createElement('canvas');
      const tCtx = temp.getContext('2d');
      temp.width = 227;
      temp.height = 227;
      tCtx.drawImage(vid, 0, 0, 227, 227);
      this.images.push(temp.toDataURL('image/jpeg'));
      setTimeout(() => {
        if (this.rightDiv) {
          const el = this.rightDiv.nativeElement;
          el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
        }
      })
      await new Promise(resolve => setTimeout(resolve, this.interval));
    }
    this.timer = requestAnimationFrame(this.frame.bind(this));
  }

}
