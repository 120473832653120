import { CustomBlock } from '../custom-block';
import outdent from 'outdent';
import * as Blockly from 'blockly/core';

export class PrintBarBlock implements CustomBlock {
  type = 'print_bar';
  
  defineBlock(block) {
    const blockJson = {
      style: 'text_blocks',
      message0: '%{BKY_OUTPUT_BAR_MESSAGE_0}',
      args0: [{
        type: 'field_variable',
        name: 'ARRAY',
        variable: 'results',
      }],
      message1: '%{BKY_OUTPUT_BAR_MESSAGE_1}',
      args1: [
        {
          type: 'field_input',
          name: 'LABEL',
          text: 'label',
        },
        {
          type: 'field_input',
          name: 'PROB',
          text: 'probability',
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const array = block.getField('ARRAY').getText();
    const label = block.getFieldValue('LABEL');
    const prob = block.getFieldValue('PROB');
    return outdent`
    ai.console.logProbability(${array}, '${label}', '${prob}');
    `;
  }
}
