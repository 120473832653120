<h1 mat-dialog-title>{{'Dataset' | translate}}</h1>
<div mat-dialog-content>
  <div class="top">
    <mat-form-field appearance="fill">
      <mat-label>{{'Title' | translate}}</mat-label>
      <input matInput name="title" [(ngModel)]="dataset.title"/>
    </mat-form-field>
    <div class="spacer"></div>
    <button mat-raised-button color="accent" (click)="onBulkWebcam()">{{'Bulk Capture Webcam Snapshot' | translate}}</button>
    <input type="file" accept="image/*" (change)="onBulkUpload(bulkUpload.files)" multiple #bulkUpload/>
    <button mat-raised-button color="accent" (click)="bulkUpload.click()">{{'Bulk Image Upload' | translate}}</button>
    <button mat-raised-button color="accent" (click)="onAdd()">{{'Add Data' | translate}}</button>
  </div>
  <table mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>{{'Type' | translate}}</th>
      <td mat-cell *matCellDef="let row; let i = index">
        <div class="type">
          <input type="file" accept="image/x-png,image/gif,image/jpeg" (change)="onFileChange(file, i)" #file/>
          <button mat-button class="icon-button" (click)="onFileClick(file)" color="accent" matTooltip="'Upload File' | translate"><fa-icon icon="file-upload"></fa-icon></button>
          <button mat-button class="icon-button" (click)="onURLClick(i)" color="accent" matTooltip="URL"><fa-icon icon="globe"></fa-icon></button>
          <button mat-button class="icon-button" (click)="onCanvasClick(i)" color="accent" matTooltip="'Draw on Canvas' | translate"><fa-icon icon="pen"></fa-icon></button>
          <button mat-button class="icon-button" (click)="onWebcamClick(i)" color="accent" matTooltip="'Take Snapshot with Webcam' | translate"><fa-icon icon="camera"></fa-icon></button>
          <mat-form-field *ngIf="row.imageType == datumImageTypes.URL">
            <mat-label>URL</mat-label>
            <input matInput name="url" [(ngModel)]="row.imageURL"/>
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef>{{'Image' | translate}}</th>
      <td mat-cell *matCellDef="let row; let i = index">
        <ng-container *ngIf="row.imageData || row.imageURL">
          <img [src]="(row.imageType == datumImageTypes.URL) ? row.imageURL : row.imageData"/>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="label">{{'Label' | translate}}</th>
      <td mat-cell *matCellDef="let row; let i = index">
        <mat-form-field>
          <input matInput [(ngModel)]="row.label" [name]="'label'+i"/>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="trash">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row; let i = index">
        <button mat-button class="icon-button" (click)="onTrash(i)" matTooltip="Remove"><fa-icon icon="trash"></fa-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onSave()" [disabled]="!dataset.title || dataset.data.length == 0">{{'Save' | translate}}</button>
  <button mat-button (click)="onExport()">{{'Export' | translate}}</button>
  <div class="spacer"></div>
  <button mat-button mat-dialog-close>{{'Close' | translate}}</button>
</div>
