import { Component, OnInit, OnDestroy, HostListener, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Mode } from '../blockly.models';
import { CodeService, LogType, LogService } from '../../shared';
import { Subscription } from 'rxjs';
import randomColor from 'randomcolor';
import { CardCodeComponent } from '../card-code/card-code.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fas } from '@fortawesome/free-solid-svg-icons';

declare var window: any;

@Component({
  selector: 'app-card-console',
  templateUrl: './card-console.component.html',
  styleUrls: ['./card-console.component.scss']
})
export class CardConsoleComponent implements OnInit, OnDestroy {

  s: Subscription[] = [];
  @Input() mode: Mode;
  @Input() disabled = false;
  @Output() zoom: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(CardCodeComponent) cardCode: CardCodeComponent;
  @ViewChild('loggerDiv') loggerDiv: ElementRef;
  Math = Math;
  Mode = Mode;
  LogType = LogType;
  selectedIndex = 0;

  newLog = false;
  logger = [];
  probabilityColors = {};
  isOnGoing = false;
  boards = "";
  open = false;
  connectShow = true;
  connct = false;
  message = "";
  @HostListener('window:pushLog', ['$event'])
  logListener(event) {
    this.logService.pushLog(event.detail.message, event.detail.level ?? 'LOG');
  }
  @HostListener('window:pushLogProbability', ['$event'])
  logProbabilityListener(event) {
    event.detail.array.map(item => {
      if (!(item[event.detail.labelKey] in this.probabilityColors)) {
        this.probabilityColors[item[event.detail.labelKey]] = randomColor({
          luminosity: 'light',
        })
      }
    });
    this.logService.pushLogProbability(event.detail);

  }

  constructor(
    private codeService: CodeService,
    private logService: LogService,
    private translateService: TranslateService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    window.socket && window.socket.emit('command', { cmd: 'list' })
    window.socket && window.socket.on('list', data => {
      // this.list(data)
      this.boards = data && data.List && data.List.boards.length > 0 && (data.List.boards[0].comName || data.List.boards[0].path)
    });
    this.s.push(
      this.logService.log$.subscribe(logger => {
        if (this.selectedIndex !== 1 && logger.length > 0)
          this.newLog = true;
        this.logger = logger;

        if (logger.length > 0 && this.open) {
          window.socket && window.socket.emit('command', {
            cmd: 'send',
            option: { msg: `SOF|${logger[logger.length - 1].message}|`, postfix: "blc" }
          });
        }
        setTimeout(() => {
          if (this.loggerDiv) {
            const el = this.loggerDiv.nativeElement;
            el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
          }
        })
      }),
      this.codeService.onGoing$.subscribe(onGoing => {
        this.isOnGoing = onGoing;
      })
    );

  }

  list(datas) {
    window.socket && window.socket.emit('command', {
      cmd: 'open',
      option: { port: datas, baud: 9600 }
    });
    window.socket.on('communicate', data => {
      this.open = data.Open;
      if (data.Open) {
        console.log('连接成功');
        this.message = 'Connect success';
        this.connectShow = false;
      } else {
        console.log('连接失败', this.connectShow);
        if (this.connct) {
          this.message = 'DisConnect';
          // this.connct = false;
        } else {
          this.message = 'Connect fail';
        }
        this.connectShow = true;
      }


    });
  }

  ngOnDestroy() {
    this.s.map(sub => sub.unsubscribe());
  }

  onChange() {
    if (this.selectedIndex == 1)
      this.newLog = false;
  }

  onZoomClick() {
    this.zoom.emit();
  }

  onCopyCode() {
    this.cardCode.copyCode();
  }

  onClearClick() {
    this.logService.clearLog();
  }

  onEnterPress(event) {
    window.consoleTextInput = event.target.value;

    console.log(event.target.value)

    if (window.open) {
      window.socket && window.socket.emit('command', {
        cmd: 'send',
        option: { msg: `SOF|${event.target.value}|`, postfix: "blc" }
      });
    } else {
      window.socket && window.socket.emit('command', {
        cmd: 'open',
        option: { port: window.boards, baud: 9600 }
      });
      setTimeout(() => {
        window.socket && window.socket.emit('command', {
          cmd: 'send',
          option: { msg: `SOF|${event.target.value}|`, postfix: "blc" }
        });
      }, 1000)
    }

    this.logService.pushLog(event.target.value, 'LOG', true);
    event.target.value = '';
    if (window.detection) {
      window.detection();
    }
  }

  // socket 连接
  onConnect() {
    this.list(this.boards);
    setTimeout(() => {
      this.translateService.get(this.message).subscribe((resource: string) => {
        this.snackbar.open(resource, null, { horizontalPosition: 'center', verticalPosition: "top", duration: 1000 });
      })
    }, 1000)
  }

  // 断开socket 连接
  onDisConnect() {
    this.connct = true;
    window.socket && window.socket.emit('command', { cmd: 'close' })

    setTimeout(() => {
      this.translateService.get(this.message).subscribe((resource: string) => {
        this.snackbar.open(resource, null, { horizontalPosition: 'center', verticalPosition: "top", duration: 1000, });
      })
    }, 1000)
  }
}
