import * as Blockly from 'blockly/core';
import { Block } from 'blockly';
import { CustomBlock } from '../custom-block';

import { ExportType } from './const';
import outdent from 'outdent';

export class ExportBlock implements CustomBlock {
  type = 'faceapi_export';

  defineBlock(block) {
    const blockJson = {
      style: 'faceapi_blocks',
      message0: '%{BKY_FACE_DETECTION_EXPORT_TITLE}',

      args0: [
        {
          type: 'field_variable',
          name: 'faceapiDetection',
          variable: 'faceapiDetection',
        },
      ],
      message1: '%{BKY_FACE_DETECTION_BASIC_INFORMATION} %1',
      args1: [
        {
          type: 'field_variable',
          name: 'faceapiBasicInfo',
          variable: 'faceapiBasicInfo'
        }
      ],
      message2: '%{BKY_FACE_DETECTION_FACE_EXPRESSIONS} %1',
      args2: [
        {
          type: 'field_variable',
          name: 'faceapiExpressions',
          variable: 'faceapiExpressions'
        }
      ],
      message3: '%{BKY_FACE_DETECTION_AGE} %1',
      args3: [
        {
          type: 'field_variable',
          name: 'faceapiAge',
          variable: 'faceapiAge'
        }
      ],
      message4: '%{BKY_FACE_DETECTION_GENDER} %1',
      args4: [
        {
          type: 'field_variable',
          name: 'faceapiGender',
          variable: 'faceapiGender'
        }
      ],

      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const detection = block.getField('faceapiDetection').getVariable().name;
    const code = outdent`
      let tempDetection = Array.isArray(${detection}) ? ${detection} : [${detection}];
      ${block.getField('faceapiBasicInfo').getVariable().name} = tempDetection.map(detect => ({
        score: detect.detection ? detect.detection.score : detect.score,
      }));
      ${block.getField('faceapiExpressions').getVariable().name} = tempDetection.filter(detect => detect.expressions).map(detect => detect.expressions);
      ${block.getField('faceapiAge').getVariable().name} = tempDetection.filter(detect => detect.age).map(detect => detect.age);
      ${block.getField('faceapiGender').getVariable().name} = tempDetection.filter(detect => detect.gender).map(detect => detect.gender);
    `;
    return code;
  }
}
