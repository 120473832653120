import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class CreateTensorCanvasBlock implements CustomBlock {
  type = 'tfjs_create_tensor_canvas';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_CREATE_TENSOR_CANVAS_MESSAGE_0}',
      args0: [
        {
          type: 'field_variable',
          name: 'TENSOR',
          variable: 'tensor'
        },
      ],
      message1: '%{BKY_TFJS_CREATE_TENSOR_CANVAS_MESSAGE_1}',
      args1: [
        {
          type: 'field_number',
          name: 'RESIZE_WIDTH',
          value: 0,
        },
        {
          type: 'field_number',
          name: 'RESIZE_HEIGHT',
          value: 0,
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const tensor = block.getField('TENSOR').getText();
    const resizeWidth = block.getFieldValue('RESIZE_WIDTH');
    const resizeHeight = block.getFieldValue('RESIZE_HEIGHT');
    return outdent`
    ${tensor} = tf.browser.fromPixels(input)
      .resizeNearestNeighbor([${resizeHeight}, ${resizeWidth}])
      .mean(2)
      .expandDims(2)
      .expandDims()
      .toFloat()
      .div(255.0);
    `;

  }
}
