import { CustomBlock } from '../custom-block';
import outdent from 'outdent';
import * as Blockly from 'blockly/core';

export class ObjectJsonBlock implements CustomBlock {
  type = 'object_json';
  
  defineBlock(block) {
    let blockJson = {
      style: 'list_blocks',
      message0: '%{BKY_OBJECT_JSON_TITLE}',
      args0: [
        {
          type: 'input_value',
          name: 'OBJECT',
        },
      ],
      output: 'String',
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const obj = Blockly.JavaScript.valueToCode(block, 'OBJECT', Blockly.JavaScript.ORDER_NONE) || '{}';
    return [`JSON.stringify(${obj})`, Blockly.JavaScript.ORDER_NONE];
  }
}
