import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators';
import ResizeObserver from 'resize-observer-polyfill';

declare var window;

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent implements OnInit {

  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  @ViewChild('drawHere', { static: true }) drawHere: ElementRef;
  @Input() disabled = false;

  private cx: CanvasRenderingContext2D;

  minX = Infinity;
  maxX = 0;
  minY = Infinity;
  maxY = 0;
  emptyCanvas = true;

  constructor(
    private thisElement: ElementRef,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');

    setTimeout(() => {
      canvasEl.width = this.thisElement.nativeElement.offsetWidth;
      canvasEl.height = this.thisElement.nativeElement.offsetHeight;

      this.drawPlaceholder();
      this.cx.lineWidth = 10;
      this.cx.lineCap = 'round';
      this.cx.strokeStyle = '#00008b';
    });

    this.captureEvents(canvasEl);

    const ro = new ResizeObserver((entries, observer) => {
      const newWidth = this.thisElement.nativeElement.offsetWidth;
      const newHeight = this.thisElement.nativeElement.offsetHeight;
      if (newWidth > 200 && newHeight > 200) {
        canvasEl.width = newWidth;
        canvasEl.height = newHeight;
        this.cx = canvasEl.getContext('2d');
        this.cx.lineWidth = 10;
        this.cx.lineCap = 'round';
        this.cx.strokeStyle = '#00008b';
        this.drawPlaceholder();
      }
    })
    ro.observe(this.thisElement.nativeElement);
  }

  drawPlaceholderIfClean() {
    if (this.emptyCanvas)
      this.drawPlaceholder();
  }

  private drawPlaceholder() {
    const baseWidth = 300 * 0.7;
    const baseHeight = 180 * 0.7;
    const scale = Math.min(baseWidth / 500, baseHeight / 300);
    this.cx.drawImage(
      this.drawHere.nativeElement,
      (this.thisElement.nativeElement.offsetWidth - baseWidth) * 0.5, //dx
      (this.thisElement.nativeElement.offsetHeight - baseHeight) * 0.5, //dy
      baseWidth, //dWidth
      baseHeight //dHeight
    );
  }

  private clearCanvas() {
    this.cx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
    fromEvent(canvasEl, 'mousedown').pipe(
      switchMap((e) => {
        return fromEvent(canvasEl, 'mousemove').pipe(
          takeUntil(fromEvent(canvasEl, 'mouseup')),
          pairwise(),
        );
      }),
    ).subscribe((res: [MouseEvent, MouseEvent]) => {
      if (this.emptyCanvas) {
        this.clearCanvas();
        this.emptyCanvas = false;
      }

      const rect = canvasEl.getBoundingClientRect();

      const prevPos = {
        x: res[0].clientX - rect.left,
        y: res[0].clientY - rect.top
      };

      const currentPos = {
        x: res[1].clientX - rect.left,
        y: res[1].clientY - rect.top
      };

      this.drawOnCanvas(prevPos, currentPos);
    });

    fromEvent(canvasEl, 'touchstart', { passive: true }).pipe(
      switchMap((e) => {
        return fromEvent(canvasEl, 'touchmove', { passive: true }).pipe(
          takeUntil(fromEvent(canvasEl, 'touchend')),
          pairwise(),
        );
      }),
    ).subscribe((res: [TouchEvent, TouchEvent]) => {
      const rect = canvasEl.getBoundingClientRect();

      const prevPos = {
        x: res[0].touches[0].clientX - rect.left,
        y: res[0].touches[0].clientY - rect.top
      };

      const currentPos = {
        x: res[1].touches[0].clientX - rect.left,
        y: res[1].touches[0].clientY - rect.top
      };

      this.drawOnCanvas(prevPos, currentPos);
    });
  }

  private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
    if (!this.cx) { return; }

    this.minX = Math.min(this.minX, prevPos.x, currentPos.x);
    this.maxX = Math.max(this.maxX, prevPos.x, currentPos.x);
    this.minY = Math.min(this.minY, prevPos.y, currentPos.y);
    this.maxY = Math.max(this.maxY, prevPos.y, currentPos.y);

    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }
  }

  onDetectClick() {
    if (window.detection) {
      window.detection();
    }
  }

  onClearClick() {
    if (!this.cx) {
      return;
    }
    this.emptyCanvas = true;
    this.clearCanvas();
    this.drawPlaceholder();
  }

  fullCanvas(width: number, height: number) {
    const temp = document.createElement('canvas');
    const tCtx = temp.getContext('2d');
    temp.width = width;
    temp.height = height;
    tCtx.drawImage(this.canvas.nativeElement, 0, 0,
      width, height,
      0, 0, width, height);
    return temp;
  }

  cropCanvas() {
    const temp = document.createElement('canvas');
    const tCtx = temp.getContext('2d');
    temp.width = this.maxX - this.minX + 40;
    temp.height = this.maxY - this.minY + 40;
    tCtx.drawImage(this.canvas.nativeElement, this.minX - 20, this.minY - 20,
      this.maxX - this.minX + 40, this.maxY - this.minY + 40,
      0, 0, this.maxX - this.minX + 40, this.maxY - this.minY + 40);
    return temp;
  }

}
