import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class DialogData {
  loremipsum: string;
  actions?: {
    cancel?: boolean,
    ok?: boolean,
    confirm?: boolean,
  };
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit() {
  }

  onCancelClick() {
    this.dialogRef.close('cancel');
  }

  onOkClick() {
    this.dialogRef.close('ok');
  }

  onConfirmClick() {
    this.dialogRef.close('confirm');
  }
}
