import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly';
import { outdent } from 'outdent';

export class LoadIrisCycle implements CustomBlock {
  type = 'tfjs_load_cycle';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_DATASET_LOAD_IRIS_CYCLE_MESSAGE_0}',
      args0: [
        {
          type: 'field_dropdown',
          name: 'CYCLE',
          variable: '',
          options: [
            ['%{BKY_TFJS_DATASET_LOAD_IRIS_MODEL_MESSAGE_0}', '1'],
          ],
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    return outdent`
    resultTensor = model.predict(tensor);
    result = Array.from(resultTensor.dataSync());
    `;
  }
}
