import { CustomBlock } from '../custom-block';

export class PoseDetectionBlock implements CustomBlock {
  type = 'pose_detection';

  defineBlock(block) {
    const blockJson = {
      style: 'pose_detection_blocks',
      message0: '%{BKY_POSE_DETECTION_POSE_DETECTION_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'DETECTION',
          variable: 'poseDetection'
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };
    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const detection = block.getField('DETECTION').getVariable().name;
    return `
      ${detection} = await ai.pose.detect(model, input);
    `;
  }
}
