<mat-tab-group #tabGroup [(selectedIndex)]="selectedIndex" (selectedTabChange)="onChange()">
  <mat-tab>
    <ng-template mat-tab-label>
      {{'Code' | translate}}
    </ng-template>
    <app-card-code></app-card-code>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      {{'Console' | translate}}
      <fa-icon icon="asterisk" *ngIf="newLog"></fa-icon>
    </ng-template>
    <div class="logger">
      <div #loggerDiv class="logger-list" [ngClass]="{allowOverflow: !isOnGoing}" *ngIf="logger?.length > 0">
        <ng-container *ngFor="let log of logger; let last = isLast">
          <p class="log-row" [ngClass]="log.level | lowercase"
            *ngIf="log.type == LogType.INPUT || log.type == LogType.OUTPUT">
            <fa-icon *ngIf="log.type == LogType.INPUT" [icon]="'angle-right'" [fixedWidth]="true"></fa-icon>
            <i class="fa-fw" *ngIf="log.type !== LogType.INPUT"></i>
            <ng-container *ngIf="log.isJSON; else plainOutput">{{log.message | json}}</ng-container>
            <ng-template #plainOutput>{{log.message}}</ng-template>
          </p>
          <p class="probability-bar" *ngIf="log.type == LogType.PROBABILITY">
            <span *ngFor="let item of log.message" [style.width.%]="item.value*100"
              [style.backgroundColor]="probabilityColors[item.label]"
              [attr.data-label]="item.label + ': ' + Math.round(item.value*100) + '%'"
              [matTooltip]="!isOnGoing ? (item.label + ': ' + item.value*100 + '%') : null">
            </span>
          </p>
          <mat-divider *ngIf="!last"></mat-divider>
        </ng-container>
      </div>
      <div class="console-input">
        <fa-icon icon="angle-right" [fixedWidth]="true"></fa-icon>
        <input [placeholder]="'Press Enter to input' | translate" type="text" (keyup.enter)="onEnterPress($event)" />
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      {{'Connect' | translate}}
    </ng-template>
    <div style="padding: 10px;display: flex;justify-content: center;position: absolute;top: calc(50% - 28px);left: calc(50% - 149px);">
      <div style="display: inline-block;width: 200px;line-height: 36px;">
        <span>{{"Port" | translate}}:</span>
        <span>{{boards|| ""}}</span>
      </div>
      <button [style.display]="connectShow ? 'inline-block' : 'none'" [style.background]="boards ? '#2c4fcd' : '#ccc'"
        mat-button (click)="onConnect()" class="connect">
        {{'Connects' | translate}}
      </button>
      <button [style.display]="!connectShow ? 'inline-block' : 'none'" [style.background]="boards ? '#2c4fcd' : '#ccc'"
        mat-button (click)="onDisConnect()" class="connect">
        {{'DisConnects' | translate}}
      </button>
    </div>
  </mat-tab>
</mat-tab-group>
<div class="header-buttons">
  <ng-container [ngSwitch]="selectedIndex">
    <ng-container *ngSwitchCase="0">
      <button mat-button class="icon-button" (click)="onCopyCode()" [matTooltip]="'Copy' | translate"
        [disabled]="disabled">
        <fa-icon icon="copy"></fa-icon>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <button mat-button (click)="onClearClick()">
        {{'Clear' | translate}}
      </button>
    </ng-container>
  </ng-container>
  <mat-divider [vertical]="true" class="mx-1"></mat-divider>
  <button mat-button class="icon-button" (click)="onZoomClick()"
    [matTooltip]="((mode != Mode.CODE) ? 'Full Screen' : 'Compact') | translate" [disabled]="disabled">
    <fa-icon icon="expand" *ngIf="mode != Mode.CODE"></fa-icon>
    <fa-icon icon="compress" *ngIf="mode == Mode.CODE"></fa-icon>
  </button>
</div>