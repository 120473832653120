import { CustomBlock } from '../custom-block';
import outdent from 'outdent';
import * as Blockly from 'blockly/core';

export class TextLogBlock implements CustomBlock {
  type = 'text_log';
  
  defineBlock(block) {
    const blockJson = {
      style: 'text_blocks',
      message0: '%{BKY_OUTPUT_TEXT_TITLE}',
      args0: [{
        type: 'input_value',
        name: 'TOSTRING',
      }],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const text = Blockly.JavaScript.valueToCode(block, 'TOSTRING', Blockly.JavaScript.ORDER_NONE) || '\'\'';
    return outdent`
    ai.console.log(${text});
    `;
  }
}
