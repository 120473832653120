import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly';
import { outdent } from 'outdent';

export class CreateTensorBlock implements CustomBlock {
  type = 'tfjs_create_tensor';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_CREATE_TENSOR_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'TENSOR',
          variable: 'tensor'
        },
        {
          type: 'field_variable',
          name: 'DATA',
          variable: 'data'
        },
        {
          type: 'field_input',
          name: 'LABEL',
          text: 'label',
          spellcheck: false
        }
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const tensor = block.getField('TENSOR').getText();
    const data = block.getField('DATA').getText();
    const label = block.getFieldValue('LABEL');
    return outdent`
    ${tensor} = tf.tensor2d(${data}.map(data => data.${label}), [${data}.map(data => data.${label}).length, 1]);
    `;

  }
}
