import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as Blockly from 'blockly/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private locale = new BehaviorSubject<string>('en');
  locale$ = this.locale.asObservable();

  constructor(
    private translateService: TranslateService
  ) {
    translateService.setDefaultLang('en');

    const locale = localStorage.getItem('locale');
    if (locale !== null) {
      this.updateLocale(locale);
    }
   }

  updateLocale(locale: string) {
    this.locale.next(locale);
    this.translateService.use(locale);
    localStorage.setItem('locale', locale);

  }
}
