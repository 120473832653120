import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class CreateTensor2Block implements CustomBlock {
  type = 'tfjs_create_tensor_2';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_CREATE_TENSOR_2_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'TENSOR',
          variable: 'tensor'
        },
        {
          type: 'input_value',
          name: 'DATA',
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const tensor = block.getField('TENSOR').getText();
    const data = Blockly.JavaScript.valueToCode(block, 'DATA', Blockly.JavaScript.ORDER_NONE) || '[]';
    return outdent`
    ${tensor} = tf.tensor2d([${data}.map(v => parseFloat(v))], [1, ${data}.length]);
    `;

  }
}
