import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Subject, combineLatest } from 'rxjs';
import { delay, filter, withLatestFrom } from 'rxjs/operators';
import { ClassicTheme } from '../theme/classic';
import { DialogComponent, CodeService, StorageService, Example, ExampleMap, DatasetService } from '../../shared';
import { Mode } from '../blockly.models';
import * as Blocks from '../../block';
import { ToolboxConfig } from './toolbox';
import { WorkspaceComponent } from '../workspace/workspace.component';
import { CustomBlock } from '../../block';

import * as Blockly from 'blockly/core';
import { BlocklyOptions, Workspace, Block } from 'blockly';
import { outdent } from 'outdent';
import { TranslateService } from '@ngx-translate/core';
import { workers } from 'cluster';

@Component({
  selector: 'app-card-blockly',
  templateUrl: './card-blockly.component.html',
  styleUrls: ['./card-blockly.component.scss']
})
export class CardBlocklyComponent implements OnInit, OnDestroy, AfterViewInit {

  s: Subscription[] = [];
  @Input() disabled: boolean = false;
  @Input() mode: Mode;
  @Output() zoom: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('blockly', { static: true }) blockly: WorkspaceComponent;
  Mode = Mode;

  options: BlocklyOptions = {
    toolbox: ToolboxConfig,
    theme: ClassicTheme.createBlocklyTheme(),
    trashcan: true,
    zoom: {
      controls: true,
      wheel: true,
      startScale: 1.0,
      maxScale: 3,
      minScale: 0.3,
      scaleSpeed: 1.2
    }
  };
  
  
  

  modal = false

  customBlocks: CustomBlock[] = [
    new Blocks.InteractionInputBlock(),
    new Blocks.TextLogBlock(),
    new Blocks.PrintBarBlock(),

    new Blocks.ListsMinmaxBlock(),

    new Blocks.CreateObjectBlock(),
    new Blocks.CreateObjectBaseBlock(),
    new Blocks.CreateObjectItemBlock(),
    new Blocks.ObjectPropertyBlock(),
    new Blocks.ObjectJsonBlock(),

    new Blocks.LoadJsonBlock(),
    new Blocks.LoadMnistBlock(),
    new Blocks.LoadIrisBlock(),
    new Blocks.LoadIrisBlock1(),
    new Blocks.LoadIrisCopyData(),
    new Blocks.LoadIrisInitialize(),
    new Blocks.LoadIrisTrait(),
    new Blocks.LoadIrisCycle(),
    new Blocks.LoadIrisType(),
    new Blocks.IrisAttribute(),
    new Blocks.LoadIrisClick(),
    new Blocks.MnistDataBatchBlock(),
    new Blocks.CreateTensorBlock(),
    new Blocks.CreateTensor2Block(),
    new Blocks.CreateTensorCanvasBlock(),
    new Blocks.CreateSequentialModelBlock(),
    new Blocks.AddInputLayerBlock(),
    new Blocks.AddDenseLayerBlock(),
    new Blocks.AddConv2DLayerBlock(),
    new Blocks.AddMaxPool2DLayerBlock(),
    new Blocks.AddFlattenLayerBlock(),
    new Blocks.LayerActivationBlock(),
    new Blocks.KernelInitializerBlock(),
    new Blocks.CompileModelBlock(),
    new Blocks.FitModelBlock(),
    new Blocks.TensorNormalBlock(),
    new Blocks.TensorUnnormalBlock(),
    new Blocks.TensorMinBlock(),
    new Blocks.TensorMaxBlock(),
    new Blocks.TensorDatasyncBlock(),
    new Blocks.DrawScatterplotBlock(),
    new Blocks.DrawModelSummaryBlock(),
    new Blocks.SaveModelBlock(),
    new Blocks.LoadModelBlock(),
    new Blocks.ActivateCanvasBlock(),
    new Blocks.TrainKnnBlock(),
    new Blocks.DetectKnnBlock(),
    new Blocks.DrawIrisConfusionMatrixBlock(),
    new Blocks.PredictOPBlock(),
    new Blocks.TidyBlock(),

    new Blocks.FetchGetBlock(),
    new Blocks.FetchGet2Block(),
    new Blocks.FetchPostBlock(),
    new Blocks.FetchPost2Block(),
    new Blocks.DelayBlock(),
    new Blocks.SetTimeIntervalBlock(),

    new Blocks.LoadFaceDetectorBlock(),
    new Blocks.LoadNetBlock(),
    new Blocks.DetectFaceBlock(),
    new Blocks.DrawDetectionBlock(),
    new Blocks.ExportBlock(),

    new Blocks.HandtrackLoadModelBlock(),
    new Blocks.DetectHandBlock(),
    new Blocks.DrawHandDetectionBlock(),
    new Blocks.HandtrackXCenterBlock(),
    new Blocks.HandtrackYCenterBlock(),
    new Blocks.HandtrackCountBlock(),
    new Blocks.HandtrackScoreBlock(),

    new Blocks.ObjectLoadModelBlock(),
    new Blocks.ObjectDetectionBlock(),
    new Blocks.DrawObjectDetectionBlock(),
    new Blocks.ObjectXCenterBlock(),
    new Blocks.ObjectYCenterBlock(),
    new Blocks.ObjectCountBlock(),
    new Blocks.ObjectScoreBlock(),
    new Blocks.ObjectClassBlock(),

    new Blocks.PoseLoadModelBlock(),
    new Blocks.PoseDetectionBlock(),
    new Blocks.DrawPoseDetectionBlock(),
    new Blocks.PoseCountBlock(),
    new Blocks.PoseKeypointXPositionBlock(),
    new Blocks.PoseKeypointYPositionBlock(),

    new Blocks.CreateTeachableModelBlock(),
    new Blocks.DetectTeachableBlock(),
  ];

  constructor(
    private dialog: MatDialog,
    private codeService: CodeService,
    private storageService: StorageService,
    private datasetService: DatasetService,
    private translateService: TranslateService,
  ) {
    
   }

  ngOnInit() {
    this.s.push(
      this.storageService.importXml$.pipe(
        filter(xml => xml !== '')
      ).subscribe(
        xml => {
          try {
            const dom = Blockly.Xml.textToDom(xml);
            Blockly.Xml.domToWorkspace(dom, this.blockly.workspace);
          } catch (error) {
            alert(error);
          }
        }
      ),

      this.codeService.example$.pipe(
        withLatestFrom(this.translateService.stream('样例加载中...'))
      )
        .subscribe(([exampleType, resource]: [Example, string]) => {
          this.modal = true;
          this.blockly.workspace.clear();
          const dom = Blockly.Xml.textToDom(ExampleMap[exampleType]);
          Blockly.Xml.domToWorkspace(dom, this.blockly.workspace);
          localStorage.setItem('BLOCKLY_XML', ExampleMap[exampleType])
          setTimeout(() => {
            this.modal = false;
          }, 1000)

        }),

      this.datasetService.datasets$.subscribe(datasets => {
        this.blockly.updateBlock(Blocks.TrainKnnBlockType);
      })
    );
  }

  ngOnDestroy() {
    this.s.map(sub => sub.unsubscribe());
  }

  ngAfterViewInit() {
    
  
  }

  onJavascriptCode(code: string) {
    if (code) {
      this.codeService.changeCode(code);
      const xml = Blockly.Xml.workspaceToDom(this.blockly.workspace);
      const xmlString = new XMLSerializer().serializeToString(xml);
      this.codeService.changeSyntaxError(this.validateWorkspace(this.blockly.workspace));
      this.storageService.updateExportXml(xmlString);
    } else {
      document.getElementsByTagName('code')[0].textContent = ""
      const xml = Blockly.Xml.workspaceToDom(this.blockly.workspace);
      const xmlString = new XMLSerializer().serializeToString(xml);
      this.codeService.changeSyntaxError(this.validateWorkspace(this.blockly.workspace));
      this.storageService.updateExportXml(xmlString);
    }
  }
  

  validateWorkspace(workspace: Workspace): string {
    let error = '';
    const drawDetection: any = {};
    workspace.getBlocksByType('faceapi_draw_detection', true).map(block => {
      Blocks.FaceDetectionType.filter(detection => block.getFieldValue(detection) === 'TRUE')
        .map(detection => {
          drawDetection[detection] = true;
        });
    });

    const detectFace: any = {};
    workspace.getBlocksByType('faceapi_detect_face', true).map(block => {
      Blocks.FaceDetectionType.filter(detection => block.getFieldValue(detection) === 'TRUE')
        .map(detection => {
          detectFace[detection] = true;
        });
    });

    const loadNet: any = {};
    workspace.getBlocksByType('faceapi_load_net', true).map(block => {
      Blocks.NetType.filter(net => block.getFieldValue(net) === 'TRUE')
        .map(net => {
          loadNet[net] = true;
        });
    });

    const detectionRequirement = {
      faceLandmarks: ['faceLandmark68Net', 'faceLandmark68TinyNet'],
      faceExpressions: ['faceExpressionNet'],
      ageAndGender: ['ageGenderNet'],
      faceDescriptor: ['faceRecognitionNet'],
    };

    error += Object.keys(drawDetection).filter(type => !detectFace[type])
      .reduce((error, type) =>
        error + `- ${type}, detect block!\n`,
        ''
      );

    error += Object.keys(detectFace)
      .map(detection => detectionRequirement[detection])
      .filter(requirements =>
        !requirements.some(requirement => Object.keys(loadNet).includes(requirement))
      )
      .reduce((error, requirements) =>
        error + `- ${requirements}, loading block!\n`
        ,
        ''
      );

    if (error !== '') {
      error = outdent`
      Missing necessary block(s) in the workspace area:
      ${error}
      Please check your block and run again.
      `;
    }

    return error;
  }

  onZoomClick() {
    this.zoom.emit();
  }

  onEraserClick() {
    this.blockly.workspace.clear();
    this.onJavascriptCode('')
    localStorage.setItem('BLOCKLY_XML', '')
  }
}
