import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly';
import { outdent } from 'outdent';

export class AddInputLayerBlock implements CustomBlock {
  type = 'tfjs_add_input_layer';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_LAYER_ADD_INPUT_LAYER_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'MODEL',
          variable: 'model'
        },
        {
          type: 'field_input',
          name: 'DIMENSION',
          text: '256x256',
          spellcheck: false
        },
        {
          type: 'field_number',
          name: 'UNITS',
          value: 1,
          precision: 1
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const model = block.getField('MODEL').getText();
    const units = parseInt(block.getFieldValue('UNITS'));
    const dimension = block.getFieldValue('DIMENSION').toLowerCase().split('x');
    
    return outdent`
    ${model}.add(tf.layers.dense({
      inputShape: [${dimension}],
      units: ${units}
    }));
    `;

  }
}
