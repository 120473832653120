import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class TidyBlock implements CustomBlock {
  type = 'tfjs_tidy';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      inputsInline: false,
      message0: '%{BKY_TFJS_UTILITIES_TIDY_TITLE}',
      message1: '%1',
      args1: [{
        type: 'input_statement',
        name: 'DO'
      }],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const statementCode = Blockly.JavaScript.statementToCode(block, 'DO');
    return outdent`
      tf.tidy(() => {
        ${statementCode}
      });
    `;
  }
}
