import { BlockStyles, CategoryStyles, Theme, CategoryStyle } from './theme';

export const blockStyles: BlockStyles = {
  logic_blocks: {
    colourPrimary: '#4c97ff',
  },
  loop_blocks: {
    colourPrimary: '#9c64fd',
  },
  math_blocks: {
    colourPrimary: '#5471ea',
  },
  variable_blocks: {
    colourPrimary: '#ffab30',
  } ,
  variable_dynamic_blocks: {
    colourPrimary: '#ffab30',
  },
  tfjs_blocks: {
    colourPrimary: '#ff6f00'
  },
  request_blocks: {
    colourPrimary: '#5da5f9',
  },
  text_blocks: {
    colourPrimary: '#31cc99',
  },
  list_blocks: {
    colourPrimary: '#3ec0e4',
  },
  colour_blocks: {
    colourPrimary: '20',
  },
  procedure_blocks: {
    colourPrimary: '#ff6181',
  },
  faceapi_blocks: {
    colourPrimary: '#C47AC0'
  },
  handtrack_blocks: {
    colourPrimary: '#fa8c9e',
  },
  object_detection_blocks: {
    colourPrimary: '#8fdd2d',
  },
  pose_detection_blocks: {
    colourPrimary: '#f2b141',
  },
  time_blocks: {
    colourPrimary: '#e5ca44'
  },
  teachable_blocks: {
    colourPrimary: '#f951a5', // this is a randomly picked color
  },
};

export const categoryStyles: CategoryStyles = {
  logic_category: {
    colour: '#4c97ff',
  },
  loop_category: {
    colour: '#9c64fd',
  },
  math_category: {
    colour: '#5471ea',
  },
  variable_category: {
    colour: '#ffab30',
  },
  variable_dynamic_category: {
    colour: '#ffab30',
  },
  request_category: {
    colour: '#5da5f9',
  },
  text_category: {
    colour: '#31cc99',
  },
  list_category: {
    colour: '#3ec0e4',
  },
  colour_category: {
    colour: '20'
  },
  procedure_category: {
    colour: '#ff6181',
  },
  faceapi_category: {
    colour: '#C47AC0'
  },
  handtrack_category: {
    colour: '#fa8c9e',
  },
  object_detection_category: {
    colour: '#8fdd2d',
  },
  pose_detection_category: {
    colour: '#f2b141',
  },
  time_category: {
    colour: '#ffd62c'
  },
  output_category: {
    colour: '#31cc99',
  },
  tfjs_category: {
    colour: '#ff6f00'
  },
  libraries_category: {
    colour: '#d53638', // this is a randomly picked color
  },
  teachable_category: {
    colour: '#f951a5', // this is a randomly picked color
  },
};

export const ClassicTheme: Theme = new Theme (
  'classic',
  blockStyles,
  categoryStyles
);
