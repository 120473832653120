import * as faceapi from 'face-api.js';

export function drawDetection(input, detections, options: string[]) {
  const output = document.getElementById('output') as HTMLCanvasElement;
  const dimension = faceapi.matchDimensions(output, input, true);
  const renderer = {
    ageAndGender: drawAgeAndGender,
    faceExpressions: faceapi.draw.drawFaceExpressions,
    faceLandmarks: faceapi.draw.drawFaceLandmarks
  };

  if (dimension.width > 0 && dimension.height > 0) {
    detections = faceapi.resizeResults(detections, dimension);
    faceapi.draw.drawDetections(output, detections);
    options.filter(option => option in renderer)
      .map(option => renderer[option](output, detections));
  }
}

function drawAgeAndGender(output, detections) {
  detections.map(result => {
    const { age, gender, genderProbability } = result;
    new faceapi.draw.DrawTextField(
      [
        age.toFixed(0).toString() + ' years',
        gender + ' (' + genderProbability.toFixed(2) + ')'
      ],
      result.detection.box.bottomRight
    ).draw(output);
  });
}