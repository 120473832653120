<h1 mat-dialog-title>Draw on Canvas</h1>
<div mat-dialog-content>
  <div class="top">
    <div class="spacer"></div>
    <button mat-raised-button color="accent" (click)="onClear()">Clear Canvas</button>
  </div>
  <app-canvas></app-canvas>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onSave()">Save Image</button>
  <div class="spacer"></div>
  <button mat-button mat-dialog-close>Cancel</button>
</div>
