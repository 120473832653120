import * as cocoSsd from '@tensorflow-models/coco-ssd';

export async function load() {
  return await cocoSsd.load();
}

export async function detect(model: cocoSsd.ObjectDetection, input: HTMLImageElement | HTMLVideoElement):
  Promise<cocoSsd.DetectedObject[]> {
  return await model.detect(input);
}

export function renderPredictions(predictions: cocoSsd.DetectedObject[], input: HTMLImageElement | HTMLVideoElement) {
  const canvas = document.getElementById('output') as HTMLCanvasElement;
  const context = canvas.getContext('2d');

  context.clearRect(0, 0, canvas.width, canvas.height);
  canvas.width = input.width;
  canvas.height = input.height;

  context.save();
  context.drawImage(input, 0, 0, input.width, input.height);
  context.restore();

  context.font = '24px Arial';
  predictions.forEach(prediction => {
    context.strokeStyle = '#333333';
    context.fillStyle = '#333333';
    context.rect(...prediction.bbox);
    context.fillText(
      prediction.score.toFixed(3) + ' ' + prediction.class,
      prediction.bbox[0] + 5,
      prediction.bbox[1] > 10 ? prediction.bbox[1] - 5 : 10
    );
    context.stroke();
  });
}
