import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class LayerActivationBlock implements CustomBlock {
  type = 'tfjs_layer_activation';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%1',
      args0: [
        {
          type: 'field_dropdown',
          name: 'ACTIVATION',
          options: [
            ['relu', 'relu'],
            ['sigmoid', 'sigmoid'],
            ['softmax', 'softmax'],
            ['tanh', 'tanh'],
            ['None', '']
          ]
        }
      ],
      output: 'string',
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const activation = block.getFieldValue('ACTIVATION');
    return [`'${activation}'`, Blockly.JavaScript.ORDER_NONE];
  }
}
