import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CanvasComponent } from '../shared/canvas/canvas.component';

declare var window: any;

@Component({
  selector: 'app-dataset-canvas-dialog',
  templateUrl: './dataset-canvas-dialog.component.html',
  styleUrls: ['./dataset-canvas-dialog.component.scss']
})
export class DatasetCanvasDialogComponent implements OnInit {

  @ViewChild(CanvasComponent) canvas: CanvasComponent;

  constructor(
    private dialogRef: MatDialogRef<DatasetCanvasDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  onSave() {
    const canvasCropped = this.canvas.fullCanvas(227, 227);
    this.dialogRef.close(canvasCropped.toDataURL());

  }

  onClear() {
    this.canvas.onClearClick();
  }

}
