import * as Blockly from 'blockly/core';
import { CustomBlock } from '../custom-block';
import outdent from 'outdent';
import * as changecase from 'change-case';

export class DetectHandBlock implements CustomBlock {
  type = 'handtrack_detect_hand';
  
  defineBlock(block) {
    const blockJson = {
      style: 'handtrack_blocks',
      message0: '%{BKY_HAND_DETECTION_DETECT_HAND_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'DETECTION',
          variable: 'handtrackDetection'
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const detection = block.getField('DETECTION').getVariable().name;
    const code = outdent`
      ${detection} = await model.detect(input);
    `;
    return code;
  }
}
