import { CustomBlock } from '../custom-block';
import outdent from 'outdent';
import * as Blockly from 'blockly/core';

export class ObjectPropertyBlock implements CustomBlock {
  type = 'object_property';
  
  defineBlock(block) {
    let blockJson = {
      style: 'list_blocks',
      message0: '%1 . %2',
      args0: [
        {
          type: 'input_value',
          name: 'OBJECT',
        },
        {
          type: 'field_input',
          name: 'PROPERTY',
        },
      ],
      output: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const obj = Blockly.JavaScript.valueToCode(block, 'OBJECT', Blockly.JavaScript.ORDER_NONE) || '{}';
    const prop = block.getFieldValue('PROPERTY');
    return [`${obj}.${prop}`, Blockly.JavaScript.ORDER_NONE];
  }
}
