import { CustomBlock } from '../custom-block';
import { Block } from 'blockly';
import { outdent } from 'outdent';

export class DetectTeachableBlock implements CustomBlock {
  type = 'detect_teachable';
  
  defineBlock(block) {
    let blockJson = {
      style: 'teachable_blocks',
      message0: '%{BKY_TEACHABLE_DETECT_MESSAGE_0}',
      args0: [
        {
          type: 'field_variable',
          name: 'MODEL',
          variable: 'model',
        },
      ],
      message1: '%{BKY_TEACHABLE_DETECT_MESSAGE_1}',
      args1: [
        {
          type: 'field_number',
          name: 'K',
          value: 3,
        },
      ],
      message2: '%{BKY_TEACHABLE_DETECT_MESSAGE_2}',
      args2: [
        {
          type: 'field_variable',
          name: 'DETECTION',
          variable: 'results'
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const model = block.getField('MODEL').getText();
    const k = block.getFieldValue('K');
    const detection = block.getField('DETECTION').getText();
    return outdent`
    ${detection} = await ${model}.predictTopK(input, ${k});
    `;
  }
}
