import { CustomBlock } from '../custom-block';
import outdent from 'outdent';
import * as Blockly from 'blockly/core';

export class ListsMinmaxBlock implements CustomBlock {
  type = 'lists_minmax';
  
  defineBlock(block) {
    let blockJson = {
      style: 'list_blocks',
      message0: 'in list %1 find %2 value',
      args0: [
        {
          type: 'input_value',
          name: 'LIST',
        },
        {
          type: 'field_dropdown',
          name: 'MATH',
          options: [
            ['minimum', 'min'],
            ['maximum', 'max']
          ]
        },
      ],
      output: 'Number',
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const list = Blockly.JavaScript.valueToCode(block, 'LIST', Blockly.JavaScript.ORDER_NONE) || '[]';
    const math = block.getFieldValue('MATH');
    return [`Math.${math}.apply(Math, ${list})`, Blockly.JavaScript.ORDER_NONE];
  }
}
