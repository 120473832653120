import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class LoadIrisBlock1 implements CustomBlock {
  type = 'tfjs_load_iris1';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_DATASET_LOAD_IRIS1_MESSAGE_0}',
      args0: [
        {
          type: 'input_value',
          name: 'DATA',
        },
        {
          type: 'field_dropdown',
          name: 'TYPE',
          variable: '',
          options: [
            ['%{BKY_TFJS_DATASET_LOAD_IRIS_LONG_MESSAGE_0}', '1'],
            ['%{BKY_TFJS_DATASET_LOAD_IRIS_WIDE_MESSAGE_0}', '2'],
            ['%{BKY_TFJS_DATASET_LOAD_IRIS_SPECIES_MESSAGE_0}', '3'],
            // ['%{BKY_TFJS_DATASET_LOAD_IRIS_COPY_DATA_COL_MESSAGE_0}', 'column'],
        ],
        }
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const data = Blockly.JavaScript.valueToCode(block, 'DATA', Blockly.JavaScript.ORDER_NONE) || '[]';
    const type = block.getField('TYPE').getText();
    return `var ${type} = ${data};`;
  }
}
