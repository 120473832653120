<mat-tab-group #tabGroup [(selectedIndex)]="selectedTab">
  <mat-tab label="">
    <ng-template mat-tab-label>
      {{'Upload File' | translate}}
    </ng-template>
    <app-input-upload></app-input-upload>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      {{'Canvas' | translate}}
    </ng-template>
    <ng-template matTabContent>
      <app-canvas [disabled]="disabled"></app-canvas>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<div class="header-buttons">
  <ng-container [ngSwitch]="tabGroup.selectedIndex">
    <ng-container *ngSwitchCase="0">
      <button mat-button (click)="onUploadDetect()">
        {{'Detect' | translate}}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <button mat-button (click)="onCanvasDetect()">
        {{'Detect' | translate}}
      </button>
      <button mat-button class="icon-button" (click)="onCanvasClear()" [matTooltip]="'Clear Canvas' | translate">
        <fa-icon icon="eraser"></fa-icon>
      </button>
    </ng-container>
  </ng-container>
  <mat-divider [vertical]="true" class="mx-1"></mat-divider>
  <button mat-button class="icon-button" (click)="onZoomClick()"
    [matTooltip]="((mode != Mode.INPUTS) ? 'Full Screen' : 'Compact') | translate" [disabled]="disabled">
    <fa-icon icon="expand" *ngIf="mode != Mode.INPUTS"></fa-icon>
    <fa-icon icon="compress" *ngIf="mode == Mode.INPUTS"></fa-icon>
  </button>
</div>