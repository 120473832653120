import * as Blockly from 'blockly/core';
import { CustomBlock } from '../custom-block';
import outdent from 'outdent';

export class PoseKeypointYPositionBlock implements CustomBlock {
  type = 'pose_keypoint_y_position';

  defineBlock(block) {
    const blockJson = {
      style: 'pose_detection_blocks',
      message0: '%{BKY_POSE_DETECTION_Y_CENTER_TITLE}',
      args0: [
        {
          type: 'field_dropdown',
          name: 'KEYPOINT_ID',
          options: [
            ['%{BKY_POSE_DETECTION_NOSE}', '0'],
            ['%{BKY_POSE_DETECTION_LEFT_EYE}', '1'],
            ['%{BKY_POSE_DETECTION_RIGHT_EYE}', '2'],
            ['%{BKY_POSE_DETECTION_LEFT_EAR}', '3'],
            ['%{BKY_POSE_DETECTION_RIGHT_EAR}', '4'],
            ['%{BKY_POSE_DETECTION_LEFT_SHOULDER}', '5'],
            ['%{BKY_POSE_DETECTION_RIGHT_SHOULDER}', '6'],
            ['%{BKY_POSE_DETECTION_LEFT_ELBOW}', '7'],
            ['%{BKY_POSE_DETECTION_RIGHT_ELBOW}', '8'],
            ['%{BKY_POSE_DETECTION_LEFT_WRIST}', '9'],
            ['%{BKY_POSE_DETECTION_RIGHT_WRIST}', '10'],
            ['%{BKY_POSE_DETECTION_LEFT_HIP}', '11'],
            ['%{BKY_POSE_DETECTION_RIGHT_HIP}', '12'],
            ['%{BKY_POSE_DETECTION_LEFT_KNEE}', '13'],
            ['%{BKY_POSE_DETECTION_RIGHT_KNEE}', '14'],
            ['%{BKY_POSE_DETECTION_LEFT_ANKLE}', '15'],
            ['%{BKY_POSE_DETECTION_RIGHT_ANKLE}', '16']
          ]
        },
        {
          type: 'field_variable',
          name: 'DETECTION',
          variable: 'detection',
        },
      ],
      output: 'Number',
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const keypointId: number = parseInt(block.getFieldValue('KEYPOINT_ID'), 10);
    const detection = block.getField('DETECTION').getVariable().name;
    const code = `${detection}.keypoints[${keypointId}].position.y`;
    return [code, Blockly.JavaScript.ORDER_NONE];
  }
}
