import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class DrawScatterplotBlock implements CustomBlock {
  type = 'tfjs_draw_scatterplot';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_0}',
      message1: '%{BKY_TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_1}',
      args1: [
        {
          type: 'field_input',
          name: 'TITLE',
          text: 'Chart Title'
        },
      ],
      message2: '%{BKY_TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_2}',
      args2: [
        {
          type: 'input_value',
          name: 'DATA',
        },
      ],
      message3: '%{BKY_TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_3}',
      args3: [
        {
          type: 'field_input',
          name: 'X_AXIS',
          text: 'asdf',
        },
      ],
      message4: '%{BKY_TFJS_UTILITIES_DRAW_SCATTER_PLOT_MESSAGE_4}',
      args4: [
        {
          type: 'field_input',
          name: 'Y_AXIS',
          text: 'qwerty',
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const title = block.getFieldValue('TITLE');
    const xAxis = block.getFieldValue('X_AXIS');
    const yAxis = block.getFieldValue('Y_AXIS');
    const data = Blockly.JavaScript.valueToCode(block, 'DATA', Blockly.JavaScript.ORDER_NONE) || '{}';
    
    return `tfvis.render.scatterplot(
      {name: '${title}'},
      ${data},
      {
        xLabel: '${xAxis}',
        yLabel: '${yAxis}',
        height: 300,
      }
    );`;
  }
}
