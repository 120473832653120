import { CustomBlock } from '../custom-block';
import { Block } from 'blockly';
import { outdent } from 'outdent';

export class SaveModelBlock implements CustomBlock {
  type = 'tfjs_save_model';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_MODEL_SAVE_MODEL_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'MODEL',
          variable: 'model',
        },
        {
          type: 'field_input',
          name: 'NAME',
          text: `model-name-${new Date().toISOString()}`,
        },
      ],

      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Block) {
    const model = block.getField('MODEL').getText();
    const name = block.getFieldValue('NAME');
    return outdent`await ${model}.save('localstorage://${name}');`;
  }
}
