import * as Blockly from 'blockly/core';
import { CustomBlock } from '../custom-block';

export class CreateObjectBaseBlock implements CustomBlock {
  type = 'object_create_object_base';
  
  defineBlock(block) {
    let blockJson = {
      style: 'list_blocks',
      message0: 'object',
      message1: '%1',
      args1: [
        {
          type: 'input_statement',
          name: 'STACK'
        },
      ],
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    return ''
  }
  
}
