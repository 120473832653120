import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class LoadIrisClick implements CustomBlock {
    type = 'tfjs_load_click_data';

    defineBlock(block) {
        let blockJson = {
            style: 'tfjs_blocks',
            message0: '%{BKY_TFJS_DATASET_LOAD_CLICK_MESSAGE_0}',
            args0: [
                {
                    type: 'field_dropdown',
                    name: 'TYPE',
                    variable: '',
                    options: [
                        ['%{BKY_TFJS_DATASET_LOAD_CLICK_MESSAGE_2}', '1'],
                        ['%{BKY_TFJS_DATASET_LOAD_CLICK_MESSAGE_1}', '2'],
                        // ['%{BKY_TFJS_DATASET_LOAD_IRIS_COPY_DATA_COL_MESSAGE_0}', 'column'],
                    ],
                }
            ],
            previousStatement: null,
            nextStatement: null,
        };

        block.jsonInit(blockJson);
    }

    clickFunction(){
        console.log('111111111')
    }
    
    toJavaScriptCode(block: Blockly.Block) {
        Blockly.registerButtonCallback('myFirstButtonPressed', this.clickFunction());
        

        return `var`;
    }
}
