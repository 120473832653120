import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent, DatasetService } from '../shared';
import { DatasetDetailDialogComponent } from '../dataset-detail-dialog/dataset-detail-dialog.component';

@Component({
  selector: 'app-dataset-dialog',
  templateUrl: './dataset-dialog.component.html',
  styleUrls: ['./dataset-dialog.component.scss']
})
export class DatasetDialogComponent implements OnInit {

  datasets$;
  currentLang;

  constructor(
    private dialog: MatDialog,
    private datasetService: DatasetService,
    private translate: TranslateService,
  ) {
    this.currentLang = translate.currentLang;
  }

  ngOnInit(): void {
    this.datasets$ = this.datasetService.datasets$;
  }

  onClick(datasetKey: string) {
    this.dialog.open(DatasetDetailDialogComponent, {
      width: '90%',
      disableClose: true,
      data: {
        datasetKey,
      },
    });
  }

  onAdd() {
    try {
      this.datasetService.addDataset();
    } catch (e) {
      this.dialog.open(DialogComponent, {
        width: '300px',
        data: {
          loremipsum: 'Exceeded browser local storage memory limit',
          actions: {
            ok: true
          }
        }
      });
    }
  }

  onTrash(datasetKey: string, event) {
    event.stopPropagation();
    this.datasetService.removeDataset(datasetKey);
  }

  onImport(file) {
    file.click();
  }
  onImportChange(fileInput) {
    if (fileInput.files.length == 1) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.datasetService.importDataset(JSON.parse(reader.result.toString()));
      };
      reader.readAsText(fileInput.files[0]);
    }
  }

}
