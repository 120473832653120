import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class DrawIrisConfusionMatrixBlock implements CustomBlock {
  type = 'tfjs_draw_iris_confusion_matrix';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_0}',
      message1: '%{BKY_TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_1}',
      args1: [
        {
          type: 'field_variable',
          name: 'MODEL',
          variable: 'model'
        }
      ],
      message2: '%{BKY_TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_2}',
      args2: [
        {
          type: 'field_input',
          name: 'TITLE',
          text: 'Chart Title'
        },
      ],
      message3: '%{BKY_TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_3}',
      args3: [
        {
          type: 'field_variable',
          name: 'X',
          variable: 'irisFeaturesTest',
        },
      ],
      message4: '%{BKY_TFJS_UTILITIES_DRAW_CONFUSION_MATRIX_MESSAGE_4}',
      args4: [
        {
          type: 'field_variable',
          name: 'Y',
          variable: 'irisClassTest'
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const title = block.getFieldValue('TITLE');
    const model = block.getField('MODEL').getText();
    const xValues = block.getField('X').getText();
    const yValues = block.getField('Y').getText();
    
    return outdent`
    const [confusionPreds, confusionLabels] = tf.tidy(() => {
      return [${model}.predict(${xValues}).argMax(-1), ${yValues}.argMax(-1)];
    });

    const confusionMatrixData = await tfvis.metrics.confusionMatrix(confusionLabels, confusionPreds);
    tfvis.render.confusionMatrix(
      {name: '${title}'},
      {values: confusionMatrixData, labels: IrisData.IRIS_CLASSES}
    );
    tf.dispose([confusionLabels, confusionPreds]);`;
  }
}
