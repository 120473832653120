import { CustomBlock } from '../custom-block';
import { MODEL_URL, NetType } from './const';

import * as Blockly from 'blockly/core';

export class LoadNetBlock implements CustomBlock {
  type = 'faceapi_load_net';

  blockJson = {
    style: 'faceapi_blocks',
    message0: '%{BKY_FACE_DETECTION_LOAD_NET_TITLE}',
    previousStatement: null,
    nextStatement: null,
  };

  defineBlock(block) {
    NetType.map(net => {
      block.appendDummyInput(net)
        .appendField(new Blockly.FieldCheckbox(), net)
        .appendField(net);
    });
  }

  toJavaScriptCode(block) {
    return NetType.filter(net => block.getFieldValue(net) === 'TRUE')
      .reduce((code, net) =>
        code + `await faceapi.nets.${net}.loadFromUri('${MODEL_URL}');\n`,
        ''
      );
  }
}
