import { CustomBlock } from '../custom-block';
import { Block } from 'blockly';
import { outdent } from 'outdent';

export class DetectKnnBlock implements CustomBlock {
  type = 'tfjs_detect_knn';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_DATASET_DETECT_KNN_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'DETECTION',
          variable: 'knnDetection'
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const detection = block.getField('DETECTION').getVariable().name;
    return outdent`const pixels = tf.browser.fromPixels(input);
    const logits = infer(pixels);
    const result = await knn.predictClass(logits, 10);
    ${detection} = Object.entries(result.confidences).map(([key, value]) => {
      return {
        label: key,
        confidence: value,
      };
    }).sort(({label: aL, confidence: aV}, {label:bL, confidence: bV}) => bV - aV);
    pixels.dispose();
    logits.dispose();
    `;
  }
}
