import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class DrawModelSummaryBlock implements CustomBlock {
  type = 'tfjs_draw_model_summary';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_UTILITIES_DRAW_MODEL_SUMMARY_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'MODEL',
          variable: 'model',
        }
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const model = block.getField('MODEL').getText();
    
    return `tfvis.show.modelSummary(
      {name: 'Model Architecture'},
      ${model}
    );`;
  }
}
