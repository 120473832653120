<h1 mat-dialog-title>Webcam</h1>
<div mat-dialog-content>
  <div class="top">
    <mat-form-field appearance="fill">
      <mat-label>Label</mat-label>
      <input matInput name="label" [(ngModel)]="label"/>
    </mat-form-field>
    <div class="spacer"></div>
    <div class="interval">
      <span>Snapshot Interval ({{interval}}ms)</span>
      <mat-slider [(ngModel)]="interval" min="50" max="300" step="10" [thumbLabel]="true" tickInterval="5"></mat-slider>
    </div>
    <button mat-raised-button color="accent" (mousedown)="onMouseDown()" (mouseup)="onMouseUp()">Press to Add</button>
  </div>
  <div>
    <div class="left">
      <video #video autoplay></video>
    </div>
    <div #rightDiv class="right" *ngIf="images.length > 0; else noImages">
      <img *ngFor="let image of images"
         [src]="image"/>
    </div>
    <ng-template #noImages>
      <div class="right">
        <span>No Images</span>
      </div>
    </ng-template>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onSave()" [disabled]="!label || images.length == 0">Insert to Dataset</button>
  <div class="spacer"></div>
  <button mat-button mat-dialog-close>Cancel</button>
</div>
