<mat-card id="cardBlockly" class="mat-elevation-z0">
  <blockly-workspace #blockly [options]="options" [customBlocks]="customBlocks" (codeUpdate)="onJavascriptCode($event)">
  </blockly-workspace>
  <div class="white-overlay" *ngIf="disabled"></div>
  <div class="button-container">
    <button mat-button class="icon-button" (click)="onEraserClick()" [matTooltip]="'Remove All'| translate"
      [disabled]="disabled">
      <fa-icon icon="eraser"></fa-icon>
    </button>
    <mat-divider [vertical]="true" class="mx-1"></mat-divider>
    <button mat-button class="icon-button" (click)="onZoomClick()"
      [matTooltip]="((mode != Mode.BLOCKLY) ? 'Full Screen' : 'Compact') | translate" [disabled]="disabled">
      <fa-icon icon="expand" *ngIf="mode != Mode.BLOCKLY"></fa-icon>
      <fa-icon icon="compress" *ngIf="mode == Mode.BLOCKLY"></fa-icon>
    </button>
  </div>
  <div class="card_modal" *ngIf="modal">
    <div class="card_modal_div">
      <img src="../../../assets/icons/loading.gif" alt="">
      <p>{{'Example Loading' | translate}}</p>
    </div>
  </div>
</mat-card>