export const MODEL_URL = '/assets/blockly/model';

export const NetType: string[] = [
  'ageGenderNet',
  'faceExpressionNet',
  'faceLandmark68Net',
  'faceLandmark68TinyNet',
  'faceRecognitionNet',
];

export const FaceDetectionType: string[] = [
  'faceLandmarks',
  'faceExpressions',
  'ageAndGender',

  /** Disable faceDescriptor until we need to compare faces */
  // 'faceDescriptor'
];

export const ExportType: any[] = [
  {
    title: 'Basic Information',
    variable: 'faceapiBasicInfo',
  },
  {
    title: 'Expressions',
    variable: 'faceapiExpressions',
  },
  {
    title: 'Age',
    variable: 'faceapiAge',
  },
  {
    title: 'Gender',
    variable: 'faceapiGender',
  }
];

export const FaceDetectorType: string[] = [
  'ssdMobilenetv1',
  'tinyFaceDetector',
  'mtcnn'
];
