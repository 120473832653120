import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CodeService, BlocklyService } from '../shared';
import { CardBlocklyComponent } from './card-blockly/card-blockly.component';
import { Mode } from './blockly.models';
import { Subscription, combineLatest } from 'rxjs';

@Component({
  selector: 'app-blockly',
  templateUrl: './blockly.component.html',
  styleUrls: ['./blockly.component.scss'],
})
export class BlocklyComponent implements OnInit, OnDestroy {

  @ViewChild(CardBlocklyComponent, { static: true }) blockly: CardBlocklyComponent;
  private _mode: Mode = Mode.NORMAL;
  get mode() { return this._mode; }
  set mode(value: Mode) {
    this._mode = value;
  }
  Mode = Mode;
  disabled: boolean = false;

  subscription: Subscription[] = [];

  constructor(
    private blocklyService: BlocklyService,
    private codeService: CodeService,
  ) { }

  ngOnInit() {
    this.subscription.push(
      combineLatest([
        this.codeService.runCode$,
        this.codeService.onGoing$
      ]).subscribe(([isCodeRun, isCodeOngoing]) => this.disabled = isCodeRun || isCodeOngoing),
    );
  }

  ngOnDestroy() {
    this.subscription.map(sub => sub.unsubscribe());
  }

  // please allow the 0.001px hack as fxFlex will not apply max-w/h=0 to make transition happen
  flexBlockly() {
    if (this.mode === Mode.NORMAL) { return '60%'; }
    else if (this.mode === Mode.BLOCKLY) { return '100%'; }
    else if (this.mode === Mode.CODE) { return '0.001px'; }
    else if (this.mode === Mode.INPUTS) { return '0.001px'; }
    else if (this.mode === Mode.RESULT) { return '0.001px'; }
  }

  flexRight() {
    if (this.mode === Mode.NORMAL) { return '40%'; }
    else if (this.mode === Mode.BLOCKLY) { return '0.001px'; }
    else if (this.mode === Mode.CODE) { return '100%'; }
    else if (this.mode === Mode.INPUTS) { return '100%'; }
    else if (this.mode === Mode.RESULT) { return '100%'; }
  }

  flexInputs() {
    if (this.mode === Mode.NORMAL) { return 40; }
    else if (this.mode === Mode.BLOCKLY) { return 40; }
    else if (this.mode === Mode.CODE) { return 0; }
    else if (this.mode === Mode.INPUTS) { return 100; }
    else if (this.mode === Mode.RESULT) { return 0; }
  }

  flexCode() {
    if (this.mode === Mode.NORMAL) { return 20; }
    else if (this.mode === Mode.BLOCKLY) { return 20; }
    else if (this.mode === Mode.CODE) { return 100; }
    else if (this.mode === Mode.INPUTS) { return 0; }
    else if (this.mode === Mode.RESULT) { return 0; }
  }

  flexResult() {
    if (this.mode === Mode.NORMAL) { return 40; }
    else if (this.mode === Mode.BLOCKLY) { return 40; }
    else if (this.mode === Mode.CODE) { return 0; }
    else if (this.mode === Mode.INPUTS) { return 0; }
    else if (this.mode === Mode.RESULT) { return 100; }
  }

  zoom(mode: Mode) {
    this.mode = (this.mode === mode) ? Mode.NORMAL : mode;
  }
}
