import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class TensorUnnormalBlock implements CustomBlock {
  type = 'tfjs_tensor_unnormal';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_TENSOR_UNNORMAL_MESSAGE_0}',
      args0: [
        {
          type: 'field_variable',
          name: 'TENSOR',
          variable: 'newTensor',
        },
      ],
      message1: '%{BKY_TFJS_TENSOR_UNNORMAL_MESSAGE_1}',
      args1: [
        {
          type: 'field_variable',
          name: 'NTENSOR',
          variable: 'normalizedTensor'
        },
      ],
      message2: '%{BKY_TFJS_TENSOR_UNNORMAL_MESSAGE_2}',
      args2: [
        {
          type: 'input_value',
          name: 'MIN',
        },
      ],
      message3: '%{BKY_TFJS_TENSOR_UNNORMAL_MESSAGE_3}',
      args3: [
        {
          type: 'input_value',
          name: 'MAX',
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const tensor = block.getField('TENSOR').getText();
    const ntensor = block.getField('NTENSOR').getText();
    const min = Blockly.JavaScript.valueToCode(block, 'MIN', Blockly.JavaScript.ORDER_NONE) || '0';
    const max = Blockly.JavaScript.valueToCode(block, 'MAX', Blockly.JavaScript.ORDER_NONE) || '1';
    
    return `${tensor} = ${ntensor}.mul(${max}.sub(${min})).add(${min});`;
  }
}
