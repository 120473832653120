import outdent from 'outdent';

export const ToolboxConfig: string = outdent`
<xml id="toolbox" style="display: none">
  <category name="%{BKY_LOGIC_TITLE}" categorystyle="logic_category">
    <block type="controls_if"></block>
    <block type="logic_compare"></block>
    <block type="logic_operation"></block>
    <block type="logic_negate"></block>
    <block type="logic_boolean"></block>
    <block type="logic_null"></block>
    <block type="logic_ternary"></block>
  </category>
  <category name="%{BKY_CONTROLS_TITLE}" categorystyle="loop_category">
    <block type="controls_repeat_ext"></block>
    <block type="controls_whileUntil"></block>
    <block type="controls_for"></block>
    <block type="controls_forEach"></block>
    <block type="controls_flow_statements"></block>
  </category>
  <category name="%{BKY_MATH_TITLE}" categorystyle="math_category">
    <block type="math_number"></block>
    <block type="math_arithmetic"></block>
    <block type="math_single"></block>
    <block type="math_trig"></block>
    <block type="math_constant"></block>
    <block type="math_number_property"></block>
    <block type="math_round"></block>
    <block type="math_on_list"></block>
    <block type="math_modulo"></block>
    <block type="math_constrain"></block>
    <block type="math_random_int"></block>
    <block type="math_random_float"></block>
    <block type="math_atan2"></block>
  </category>
  <category name="%{BKY_TEXT_TITLE}" categorystyle="text_category">
    <block type="text"></block>
    <block type="text_join"></block>
    <block type="text_append"></block>
    <block type="text_length"></block>
    <block type="text_isEmpty"></block>
    <block type="text_indexOf"></block>
    <block type="text_charAt"></block>
    <block type="text_getSubstring"></block>
    <block type="text_changeCase"></block>
    <block type="text_trim"></block>
    <!--block type="text_print"></block-->
    <block type="text_prompt_ext"></block>
  </category>
  <category name="%{BKY_LISTS_TITLE}" categorystyle="list_category">
    <block type="lists_create_with">
      <mutation items="0"></mutation>
    </block>
    <block type="lists_create_with"></block>
    <block type="lists_repeat"></block>
    <block type="lists_length"></block>
    <block type="lists_isEmpty"></block>
    <block type="lists_indexOf"></block>
    <block type="lists_getIndex"></block>
    <block type="lists_setIndex"></block>
    <block type="lists_getSublist"></block>
    <block type="lists_split"></block>
    <block type="lists_sort"></block>
    <block type="lists_minmax"></block>
  </category>
  <category name="%{BKY_OBJECTS_TITLE}" categorystyle="list_category">
    <block type="object_create_object">
      <mutation items="2" keys='["key1", "key2"]'></mutation>
    </block>
    <block type="object_property"></block>
    <block type="object_json"></block>
  </category>
  <category name="%{BKY_COLOUR_TITLE}" categorystyle="colour_category">
    <block type="colour_picker"></block>
    <block type="colour_random"></block>
    <block type="colour_rgb"></block>
    <block type="colour_blend"></block>
  </category>
  <category name="%{BKY_TIME_TITLE}" categorystyle="time_category">
    <block type="time_delay"></block>
    <block type="time_interval"></block>
  </category>
  <category name="%{BKY_VARIABLES_TITLE}" categorystyle="variable_category" custom="VARIABLE"></category>
  <category name="%{BKY_REQUESTS_TITLE}" categorystyle="request_category">
    <block type="request_fetch_get">
      <field name="API_KEY">22e04f0aea1a2f880f0973fd9513dd33</field>
    </block>
    <block type="request_fetch_post">
      <field name="API_KEY">22e04f0aea1a2f880f0973fd9513dd33</field>
    </block>
    <block type="request_fetch_get_2">
      <value name="URL">
        <block type="text"></block>
      </value>
    </block>
    <block type="request_fetch_post_2">
      <value name="URL">
        <block type="text"></block>
      </value>
      <value name="HEADERS">
        <block type="object_create_object">
          <mutation items="1" keys='["Content-Type"]'></mutation>
          <value name="ADD0">
            <block type="text">
              <field name="TEXT">application/json</field>
            </block>
          </value>
        </block>
      </value>
    </block>
  </category>
  <category name="%{BKY_OUTPUT_TITLE}" categorystyle="output_category">
    <block type="text_log"></block>
    <block type="print_bar"></block>
  </category>
  <category name="%{BKY_FUNCTIONS_TITLE}" categorystyle="procedure_category" custom="PROCEDURE"></category>
  <category name="%{BKY_TENSORFLOW_TITLE}" categorystyle="tfjs_category">
    <category name="%{BKY_TENSORS_TITLE}" categorystyle="tfjs_category">
      <block type="tfjs_create_tensor"></block>
      <block type="tfjs_create_tensor_2"></block>
      <block type="tfjs_create_tensor_canvas"></block>
      <block type="tfjs_tensor_normal">
        <value name="MIN"><block type="tfjs_tensor_min"></block></value>
        <value name="MAX"><block type="tfjs_tensor_max"></block></value>
      </block>
      <block type="tfjs_tensor_unnormal">
        <value name="MIN"><block type="tfjs_tensor_min"></block></value>
        <value name="MAX"><block type="tfjs_tensor_max"></block></value>
      </block>
      <block type="tfjs_mnist_data_batch"></block>
    </category>
    <category name="%{BKY_DATASETS_TITLE}" categorystyle="tfjs_category">
      <block type="tfjs_load_json"></block>
      <block type="tfjs_load_mnist"></block>
      <block type="tfjs_load_iris"></block>
      <block type="iris_attribute"></block>
      <block type="tfjs_load_iris1"></block>
      <block type="tfjs_load_copy_data"></block>
      <block type="tfjs_load_initailize"></block>
      <block type="tfjs_load_trait"></block>
      <block type="tfjs_load_data_type"></block>
      <block type="tfjs_load_cycle"></block>
      <block type="tfjs_train_knn"></block>
      <block type="tfjs_detect_knn"></block>
    </category>
    <category name="%{BKY_LAYERS_TITLE}" categorystyle="tfjs_category">
      <block type="tfjs_add_input_layer"></block>
      <block type="tfjs_add_dense_layer">
        <value name="OPTIONS">
          <block type="object_create_object">
            <mutation items="3" keys='["activation", "units", "kernelInitializer"]'></mutation>
            <value name="ADD0">
              <block type="tfjs_layer_activation"><field name="ACTIVATION">relu</field></block>
            </value>
            <value name="ADD1">
              <block type="math_number"><field name="NUM">1</field></block>
            </value>
            <value name="ADD2">
              <block type="tfjs_kernel_initializer"><field name="KERNEL">varianceScaling</field></block>
            </value>
          </block>
        </value>
      </block>
      <block type="tfjs_add_conv_2d_layer">
        <value name="OPTIONS">
          <block type="object_create_object">
            <mutation items="6" keys='["activation", "inputShape", "kernelSize", "filters", "strides", "kernelInitializer"]'></mutation>
            <value name="ADD0">
              <block type="tfjs_layer_activation"><field name="ACTIVATION">relu</field></block>
            </value>
            <value name="ADD1">
              <block type="lists_create_with">
                <mutation items="3"/>
                <value name="ADD0"><block type="math_number"><field name="NUM">0</field></block></value>
                <value name="ADD1"><block type="math_number"><field name="NUM">0</field></block></value>
                <value name="ADD2"><block type="math_number"><field name="NUM">0</field></block></value>
              </block>
            </value>
            <value name="ADD2">
              <block type="math_number"><field name="NUM">0</field></block>
            </value>
            <value name="ADD3">
              <block type="math_number"><field name="NUM">0</field></block>
            </value>
            <value name="ADD4">
              <block type="math_number"><field name="NUM">1</field></block>
            </value>
            <value name="ADD5">
              <block type="tfjs_kernel_initializer"><field name="KERNEL">varianceScaling</field></block>
            </value>
          </block>
        </value>
      </block>
      <block type="tfjs_add_max_pool_2d_layer">
        <value name="OPTIONS">
          <block type="object_create_object">
            <mutation items="2" keys='["poolSize", "strides"]'></mutation>
            <value name="ADD0">
              <block type="lists_create_with">
                <mutation items="2"/>
                <value name="ADD0"><block type="math_number"><field name="NUM">0</field></block></value>
                <value name="ADD1"><block type="math_number"><field name="NUM">0</field></block></value>
              </block>
            </value>
            <value name="ADD1">
              <block type="lists_create_with">
                <mutation items="2"/>
                <value name="ADD0"><block type="math_number"><field name="NUM">1</field></block></value>
                <value name="ADD1"><block type="math_number"><field name="NUM">1</field></block></value>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="tfjs_add_flatten_layer"></block>
      <block type="tfjs_layer_activation"></block>
      <block type="tfjs_kernel_initializer"></block>
    </category>
    <category name="%{BKY_MODELS_TITLE}" categorystyle="tfjs_category">
      <block type="tfjs_create_sequential_model"></block>
      <block type="tfjs_compile_model"></block>
      <block type="tfjs_fit_model"></block>
      <block type="tfjs_predict_OP"></block>
      <block type="tfjs_save_model"></block>
      <block type="tfjs_load_model"></block>
    </category>
    <category name="%{BKY_INTERACTIONS_TITLE}" categorystyle="tfjs_category">
      <block type="tfjs_activate_canvas"></block>
      <block type="interaction_input"></block>
    </category>
    <category name="%{BKY_UTILITIES_TITLE}" categorystyle="tfjs_category">
      <block type="tfjs_tensor_min"></block>
      <block type="tfjs_tensor_max"></block>
      <block type="tfjs_tensor_datasync"></block>
      <block type="tfjs_draw_scatterplot"></block>
      <block type="tfjs_draw_model_summary"></block>
      <block type="tfjs_draw_iris_confusion_matrix"></block>
      <block type="tfjs_tidy"></block>
    </category>
  </category>
  <category name="%{BKY_LIBRARIES_TITLE}" categorystyle="libraries_category">
    <category name="%{BKY_FACE_DETECTION_TITLE}" categorystyle="faceapi_category">
      <block type="faceapi_load_face_detector"></block>
      <block type="faceapi_load_net"></block>
      <block type="faceapi_detect_face"></block>
      <block type="faceapi_draw_detection"></block>
      <block type="faceapi_export"></block>
    </category>
    <category name="%{BKY_HAND_DETECTION_TITLE}" categorystyle="handtrack_category">
      <block type="handtrack_load_model"></block>
      <block type="handtrack_detect_hand"></block>
      <block type="handtrack_draw_detection"></block>
      <block type="handtrack_x_center"></block>
      <block type="handtrack_y_center"></block>
      <block type="handtrack_score"></block>
      <block type="handtrack_count"></block>
    </category>
    <category name="%{BKY_OBJECT_DETECTION_TITLE}" categorystyle="object_detection_category">
      <block type="object_load_model"></block>
      <block type="object_detection"></block>
      <block type="object_draw_detection"></block>
      <block type="object_x_center"></block>
      <block type="object_y_center"></block>
      <block type="object_score"></block>
      <block type="object_count"></block>
      <block type="object_class"></block>
    </category>
    <category name="%{BKY_POSE_DETECTION_TITLE}" categorystyle="pose_detection_category">
      <block type="pose_detection_load_model"></block>
      <block type="pose_detection"></block>
      <block type="pose_detection_draw_detection"></block>
      <block type="pose_count"></block>
      <block type="pose_keypoint_x_position"></block>
      <block type="pose_keypoint_y_position"></block>
    </category>
    <category name="%{BKY_TEACHABLE_MACHINE_TITLE}" categorystyle="teachable_category">
      <block type="create_teachable_model"></block>
      <block type="detect_teachable"></block>
    </category>
  </category>
</xml>
`;
