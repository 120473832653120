import { CustomBlock } from '../custom-block';
import { ApiEndpoint } from './const';

import * as Blockly from 'blockly/core';

export class FetchPost2Block implements CustomBlock {
  type = 'request_fetch_post_2';
  
  defineBlock(block) {
    const blockJson = {
      style: 'request_blocks',
      message0: '%{BKY_REQUEST_POST_2_MESSAGE_0}',
      args0: [{
        type: 'field_variable',
        name: 'RESPONSE',
        variable: 'response',
      }],
      message1: '%{BKY_REQUEST_POST_2_MESSAGE_1}',
      args1: [{
        type: 'input_value',
        name: 'URL',
        check: 'String'
      }],
      /*message2: '%{BKY_REQUEST_POST_2_MESSAGE_2}',
      args2: [{
        type: 'input_value',
        name: 'PARAMS',
        check: 'Object',
      }],*/
      message2: '%{BKY_REQUEST_POST_2_MESSAGE_3}',
      args2: [{
        type: 'input_value',
        name: 'HEADERS',
        check: 'Object',
      }],
      message3: '%{BKY_REQUEST_POST_2_MESSAGE_4}',
      args3: [{
        type: 'input_value',
        name: 'DATA',
      }],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const response = block.getField('RESPONSE').getText();
    const url = Blockly.JavaScript.valueToCode(block, 'URL', Blockly.JavaScript.ORDER_NONE) || `''`;
    //const params = Blockly.JavaScript.valueToCode(block, 'PARAMS', Blockly.JavaScript.ORDER_NONE) || '{}';
    const headers = Blockly.JavaScript.valueToCode(block, 'HEADERS', Blockly.JavaScript.ORDER_NONE) || '{}';
    const data = Blockly.JavaScript.valueToCode(block, 'DATA', Blockly.JavaScript.ORDER_NONE) || '{}';
    return `
    ${response} = await ai.fetch.post(${url}, ${headers}, ${data});
    `;
  }
}
