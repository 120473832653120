import { from } from 'rxjs';

export * from './load-json';
export * from './load-mnist';
export * from './create-tensor';
export * from './create-tensor-2';
export * from './create-tensor-canvas';
export * from './create-sequential-model';
export * from './add-input-layer';
export * from './add-dense-layer';
export * from './add-conv-2d-layer';
export * from './add-max-pool-2d-layer';
export * from './add-flatten-layer';
export * from './compile-model';
export * from './fit-model';
export * from './mnist-data';
export * from './tensor-min';
export * from './tensor-max';
export * from './tensor-normal';
export * from './tensor-unnormal';
export * from './draw-scatterplot';
export * from './save-model';
export * from './load-model';
export * from './layer-activation';
export * from './kernel-initializer';
export * from './mnist-data-batch';
export * from './draw-model-summary';
export * from './activate-canvas';
export * from './train-knn';
export * from './detect-knn';
export * from './iris-data';
export * from './load-iris';
export * from './draw-confusion-matrix';
export * from './predict-OP';
export * from './tidy';
export * from './tensor-datasync';
export * from './load-iris_copy';
export * from './load_iris_copy_date'
export * from './iris_Initialize'
export * from './iris_trait'
export * from './iris_cycle_data'
export * from './iris_data_type'
export * from './iris_attribute'
export * from './iris_click_data'