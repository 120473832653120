<mat-card id="cardResult" class="mat-elevation-z0">
  <mat-card-header>
    <mat-card-title>
      {{'Result' | translate}}</mat-card-title>
    <div fxFlex></div>
    <mat-spinner diameter="36" *ngIf="codeInit || fakeStop"></mat-spinner>
    <button mat-button class="ml-1" *ngIf="!onGoing" (click)="onRunClick()" [disabled]="codeInit || fakeStop">
      <fa-icon icon="play"></fa-icon> {{'Run' | translate}}
    </button>
    <button mat-button class="ml-1" *ngIf="onGoing" (click)="onStopClick()" [disabled]="codeInit || fakeStop">
      <fa-icon icon="stop"></fa-icon> {{'Stop' | translate}}
    </button>
    <mat-divider [vertical]="true" class="mx-1"></mat-divider>
    <button mat-button class="icon-button" (click)="onZoomClick()" [matTooltip]="((mode != Mode.RESULT) ? 'Full Screen' : 'Compact') | translate" [disabled]="codeInit">
      <fa-icon icon="expand" *ngIf="mode != Mode.RESULT"></fa-icon>
      <fa-icon icon="compress" *ngIf="mode == Mode.RESULT"></fa-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <div class="scrollable" [ngClass]="{center: !spaceZoom}" #space>
      <img #inputImage id="inputImage" [ngStyle]="{'transform': 'scale('+spaceScale+')'}">
      <video #inputVideo id="inputVideo" autoplay [ngStyle]="{'transform': 'scale('+spaceScale+')'}"></video>
      <canvas #output id="output" [ngStyle]="{'transform': 'scale('+spaceScale+')'}"></canvas>
    </div>
    <div class="white-overlay" *ngIf="codeInit"></div>
    <div class="float-buttons">
      <button mat-button class="icon-button" [disabled]="spaceZoom" (click)="onZoomPlusClick()">
        <fa-icon size="lg" icon="search-plus"></fa-icon>
      </button>
      <button mat-button class="icon-button" [disabled]="!spaceZoom" (click)="onZoomMinusClick()">
        <fa-icon size="lg" icon="search-minus"></fa-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
