import { Component, OnInit, AfterViewInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Mode } from '../blockly.models';
import { CodeService, DialogService, LogService } from '../../shared';
import { Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import ResizeObserver from 'resize-observer-polyfill';

import * as faceapi from 'face-api.js';
import * as handTrack from 'handtrackjs';
import * as tfjs from '@tensorflow/tfjs';
import * as tfvis from '@tensorflow/tfjs-vis';
import * as mobilenet from '@tensorflow-models/mobilenet';
import * as knn from '@tensorflow-models/knn-classifier';
import * as teachable from '@teachablemachine/image';
import { NeuralNetwork } from 'face-api.js';
import { MnistData, IRIS_CLASSES, getIrisData } from '../../block';

import * as ai from '../../module/ai';
import { TranslateService } from '@ngx-translate/core';

declare var window: any;

@Component({
  selector: 'app-card-result',
  templateUrl: './card-result.component.html',
  styleUrls: ['./card-result.component.scss']
})
export class CardResultComponent implements OnInit, AfterViewInit, OnDestroy {

  s: Subscription[] = [];
  @Input() mode: Mode;
  @Output() zoom: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('space', { static: true }) space: ElementRef;
  @ViewChild('inputImage', { static: true }) inputImage: ElementRef;
  @ViewChild('inputVideo', { static: true }) inputVideo: ElementRef;
  @ViewChild('output', { static: true }) output: ElementRef;
  private _spaceScale: number = 1;
  get spaceScale(): number {
    return this.spaceZoom ? Math.max(this._spaceScale, 1) : Math.min(this._spaceScale, 1);
  }
  spaceZoom = false;
  resizeObserver = new ResizeObserver((entries, observer) => {
    this.calculateNewScale(entries[0].contentRect);
  });
  snackbarRef: MatSnackBarRef<any>;

  codeInit = false;
  onGoing = false;
  fakeStop = false;
  Mode = Mode;

  syntaxError = '';

  @HostListener('window:hasOnGoing')
  windowHasOnGoing() {
    this.codeService.changeOnGoing(true);
  }

  constructor(
    private snackbar: MatSnackBar,
    private codeService: CodeService,
    private dialogService: DialogService,
    private logService: LogService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.s.push(
      this.codeService.syntaxError$.subscribe(
        error => this.syntaxError = error
      ),

      this.codeService.runCode$.subscribe(
        codeInit => {
          this.codeInit = codeInit;
          if (!codeInit) {
            this.afterCodeInit();
          }
        }
      ),

      this.codeService.onGoing$.subscribe(
        hasOnGoing => this.onGoing = hasOnGoing
      ),
    );

    window.faceapi = faceapi;
    window.handTrack = handTrack;
    window.tf = tfjs;
    window.tfvis = tfvis;
    window.knnModule = knn;
    window.mobilenetModule = mobilenet;
    window.MnistData = MnistData;
    window.ai = ai;
    window.IrisData = {
      getIrisData: () => getIrisData(0.15),
      IRIS_CLASSES: IRIS_CLASSES,
    };
    window.teachableMachine = {
      create: () => teachable.createTeachable({}),
    };
  }

  ngAfterViewInit() {
    this.resizeObserver.observe(this.space.nativeElement);
  }

  ngOnDestroy() {
    this.s.map(sub => sub.unsubscribe());
  }

  showSyntaxError(msg) {
    this.dialogService.showError(msg);
  }

  onZoomClick() {
    this.zoom.emit();
  }

  afterCodeInit() {
    if (this.snackbarRef) {
      this.snackbarRef.dismiss();
    }
    this.calculateNewScale(this.space.nativeElement.getBoundingClientRect());
  }

  onRunClick() {
    this.clear();
    this.logService.clearLog();

    if (this.syntaxError !== '') {
      this.showSyntaxError(this.syntaxError);
      return;
    }

    const message = 'Executing your code, please be patient. Your browser tab might run slower than usual.';
    this.translateService.get(message).subscribe((resource: string) => {
      this.snackbarRef = this.snackbar.open(resource, null, { horizontalPosition: 'left' });
    });

    try {
      this.codeService.executeCode();
    } catch (e) {
      this.showSyntaxError(e.message);
    }
  }

  onStopClick() {
    this.clear();
    this.fakeStop = true;
    setTimeout(() => this.fakeStop = false, 1400 + Math.floor(Math.random() * 200));
  }

  clear() {
    this.codeService.changeOnGoing(false);
    window.detection = () => { };
    this.clearImage();
    this.clearVideo();
    this.clearCanvas();
    this.disposeAllNets();
    this.codeService.stopExecution();
  }

  clearImage() {
    const image: HTMLImageElement = this.inputImage.nativeElement;
    if (image) {
      image.src = '';
    }
  }

  clearVideo() {
    const video: HTMLVideoElement = this.inputVideo.nativeElement;
    if (video) {
      const stream = video.srcObject as MediaStream;
      stream?.getTracks().map(track => track.stop());
      video.width = 0;
      video.height = 0;
      video.srcObject = null;
    }
  }

  clearCanvas() {
    const canvas: HTMLCanvasElement = this.output.nativeElement;
    if (canvas) {
      const cxt = canvas.getContext('2d');
      cxt.clearRect(0, 0, cxt.canvas.width, cxt.canvas.height);
      canvas.width = 0;
      canvas.height = 0;
    }
  }

  disposeAllNets() {
    Object.values(faceapi.nets).filter(net => net.isLoaded).map(net => net.dispose());
  }

  calculateNewScale(rect: { width: number, height: number }) {
    const maxWidth = rect.width;
    const maxHeight = rect.height;
    let srcWidth: number;
    let srcHeight: number;

    if (this.inputImage.nativeElement.height) {
      srcWidth = this.inputImage.nativeElement.width;
      srcHeight = this.inputImage.nativeElement.height;
    } else if (this.inputVideo) {
      srcWidth = this.inputVideo.nativeElement.videoWidth;
      srcHeight = this.inputVideo.nativeElement.videoHeight;
    }

    this._spaceScale = Math.min(maxHeight / srcHeight, maxWidth / srcWidth);
  }

  onZoomPlusClick() {
    this.spaceZoom = true;
  }
  onZoomMinusClick() {
    this.spaceZoom = false;
  }
}
