import * as Blockly from 'blockly/core';
import { CustomBlock } from '../custom-block';
import outdent from 'outdent';

export class ObjectScoreBlock implements CustomBlock {
  type = 'object_score';
  
  defineBlock(block) {
    const blockJson = {
      style: 'object_detection_blocks',
      message0: '%{BKY_OBJECT_DETECTION_SCORE_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'DETECTION',
          variable: 'detection',
        },
      ],
      output: 'Number',
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const detection = block.getField('DETECTION').getVariable().name;
    const code = `${detection}.score`;
    return [code, Blockly.JavaScript.ORDER_NONE];
  }
}
