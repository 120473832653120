import * as Blockly from 'blockly/core';
import { Block } from 'blockly';
import { CustomBlock } from '../custom-block';

import { FaceDetectionType } from './const';
import * as changecase from 'change-case';
import outdent from 'outdent';

export class DrawDetectionBlock implements CustomBlock {
  type = 'faceapi_draw_detection';

  blockJson = {
    style: 'faceapi_blocks',
    message0: '%{BKY_FACE_DETECTION_DRAW_DETECTION_TITLE}',
    args0: [
      {
        type: 'field_variable',
        name: 'faceapiDetection',
        variable: 'faceapiDetection',
      },
    ],
    message1: '%1 %{BKY_FACE_DETECTION_FACE_LANDMARKS}',
    args1: [
      {
        type: 'field_checkbox',
        name: 'faceLandmarks',
      },
    ],
    message2: '%1 %{BKY_FACE_DETECTION_FACE_EXPRESSIONS}',
    args2: [
      {
        type: 'field_checkbox',
        name: 'faceExpressions',
      },
    ],
    message3: '%1 %{BKY_FACE_DETECTION_AGE_AND_GENDER}',
    args3: [
      {
        type: 'field_checkbox',
        name: 'ageAndGender',
      },
    ],
    previousStatement: null,
    nextStatement: null,
  };

  defineBlock(block) {
  }

  toJavaScriptCode(block) {
    const detection = Blockly.JavaScript.variableDB_.getName(block.getFieldValue('faceapiDetection'), Blockly.Variables.NAME_TYPE);
    const options = FaceDetectionType.filter(type => block.getFieldValue(type) === 'TRUE');
    const code = outdent({ trimTrailingNewline: false })`
      ai.face.drawDetection(input, ${detection}, ['${options.join('\', \'')}']);
    `;
    return code;
  }
}
