import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class LoadIrisInitialize implements CustomBlock {
  type = 'tfjs_load_initailize';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_DATASET_LOAD_IRIS_INITIALIZE_MESSAGE_0}',
      args0: [
        {
          type: 'field_dropdown',
          name: 'INITIALIZE',
          variable: '',
          options: [
              ['%{BKY_TFJS_DATASET_LOAD_IRIS_MODEL_MESSAGE_0}', '1'],
              // ['%{BKY_TFJS_DATASET_LOAD_IRIS_COPY_DATA_COL_MESSAGE_0}', 'column'],
          ],
        },
        {
          type: 'input_value',
          name: 'ONE',
          text: '',
          spellcheck: false
        }
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const initializer = block.getField('INITIALIZE').getText()
    console.log(block)
    const one = Blockly.JavaScript.valueToCode(block, 'ONE', Blockly.JavaScript.ORDER_NONE) || '[]';
    var code = `var irisFeatures, irisClass, irisFeaturesTest, irisClassTest, irisConstantClass, input, model, tensor, resultTensor, result;
    [irisFeatures, irisClass, irisFeaturesTest, irisClassTest] = IrisData.getIrisData();
    irisConstantClass = IrisData.IRIS_CLASSES;
    model = tf.sequential();
    model.add(tf.layers.dense({
      'activation': 'sigmoid',
      'units': 10,
      'inputShape': [(irisFeatures.shape)[1]],
    }));
    model.add(tf.layers.dense({
      'activation': 'softmax',
      'units': 3,
    }));
    model.compile({
      optimizer: tf.train.adam(0.01),
      loss: 'categoricalCrossentropy',
      metrics: ['accuracy'],
    });
    await model.fit(irisFeatures, irisClass, {
      'shuffle': true,
      'epochs': 40,
      'validationData': [irisFeaturesTest, irisClassTest],
      callbacks: tfvis.show.fitCallbacks({
          name: 'Training Performance'
        },
        ['loss', 'val_loss', 'acc', 'val_acc'], {
          height: 200,
          callbacks: ['onEpochEnd']
        }
      )
    });
    const [confusionPreds, confusionLabels] = tf.tidy(() => {
      return [model.predict(irisFeaturesTest).argMax(-1), irisClassTest.argMax(-1)];
    });
    const confusionMatrixData = await tfvis.metrics.confusionMatrix(confusionLabels, confusionPreds);
    tfvis.render.confusionMatrix({
      name: 'Validation'
    }, {
      values: confusionMatrixData,
      labels: IrisData.IRIS_CLASSES
    });
    tf.dispose([confusionLabels, confusionPreds]);
   `
    return outdent`
    ${code}
    `;
  }
}
