const ApiEndpoint = 'http://api.cocorobo.hk/iot/data/eventAPIKey';
import { log } from './console';

export async function postCocorobo(apiKey: string, data: object) {
  const init = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(`${ApiEndpoint}/${apiKey}`, init);
  return await response.text();
}

export async function post(url: string, headers: any, data: any) {
  const init = {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  };
  const response = await fetch(`${url}`, init);
  return await response.text();
}

export async function getCocorobo(apiKey: string) {
  const response = await fetch(`${ApiEndpoint}/${apiKey}`);
  return await response.text();
}

export async function get(url: string) {
  const response = await fetch(`${url}`);
  return await response.text();
}
