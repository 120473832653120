import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class LoadIrisTrait implements CustomBlock {
  type = 'tfjs_load_trait';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_DATASET_LOAD_IRIS_TRAIT_MESSAGE_0}',
      args0: [
        {
          type: 'input_value',
          name: 'TRAIT',
          variable: '',
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const trait = Blockly.JavaScript.valueToCode(block, 'TRAIT', Blockly.JavaScript.ORDER_NONE) || '[]' 
    console.log(trait.slice(1,trait.length-1).replace(/\)/g,'').replace(/\（/g,'').split(','))
    let arr = trait.slice(1,trait.length-1).replace(/\)/g,'')
    let arr1 = arr.replace(/\(/g,'').split(',')
    // for (let i=0;i<trait.length;i++){
    //   arr.push(trait[i])
    // }
    let data = [5.1,3.5].concat(arr1)
    console.log(data)
    return outdent`
    window.detection = async () => {
      if ([${data}]) {
        tensor = tf.tensor2d([[${data}].map(v => parseFloat(v))], [1, [${data}].length]);
      }
    }
    `;
  }
}
