import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class FitModelBlock implements CustomBlock {
  type = 'tfjs_fit_model';

  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_MODEL_FIT_MODEL_MESSAGE_0}',
      args0: [
        {
          type: 'field_variable',
          name: 'MODEL',
          variable: 'model'
        },
        {
          type: 'field_variable',
          name: 'INPUT',
          variable: 'input'
        },
        {
          type: 'field_variable',
          name: 'LABEL',
          variable: 'label'
        }
      ],
      message1: '%{BKY_TFJS_MODEL_FIT_MODEL_MESSAGE_1}',
      args1: [
        {
          type: 'input_value',
          name: 'OPTIONS',
        }
      ],
      /*message1: 'batch size %1, epoch %2',
      args1: [
        {
          type: 'field_number',
          name: 'BATCH',
          value: 32,
        },
        {
          type: 'field_number',
          name: 'EPOCH',
          value: 50,
        },
      ],*/
      message2: '%{BKY_TFJS_MODEL_FIT_MODEL_MESSAGE_2}',
      args2: [
        {
          type: 'input_value',
          name: 'METRICS',
        },
      ],
      previousStatement: null,
      nextStatement: null,
      inputsInline: false,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const model = block.getField('MODEL').getText();
    const input = block.getField('INPUT').getText();
    const label = block.getField('LABEL').getText();
    let options = Blockly.JavaScript.valueToCode(block, 'OPTIONS', Blockly.JavaScript.ORDER_NONE) || `{}`; 
    const metrics = Blockly.JavaScript.valueToCode(block, 'METRICS', Blockly.JavaScript.ORDER_NONE) || `[]`; 
    if (options !== '{}') {
      options = `${options.slice(0, -1)}
        callbacks: tfvis.show.fitCallbacks(
          { name: 'Training Performance' },
          ${metrics}, 
          { height: 200, callbacks: ['onEpochEnd'] }
        )
      }`
    } else {
      options = `{
        callbacks: tfvis.show.fitCallbacks(
          { name: 'Training Performance' },
          ${metrics}, 
          { height: 200, callbacks: ['onEpochEnd'] }
        )
      }`;
    }

    return outdent`
    await ${model}.fit(${input}, ${label}, ${options});
    `;

  }
}
