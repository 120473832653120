declare var window: any;

export function consoleTextInput() {
  return window.consoleTextInput;
}

export async function enableImage(imageUri) {
  const image = document.getElementById('inputImage') as HTMLImageElement;

  image.crossOrigin = 'anonymous';
  image.src = imageUri;
  await new Promise((resolve, reject) => {
    image.onload = () => {
      resolve();
    };
  });

  return image;
}

export async function enableUploadImage() {
  const uploadImage = document.getElementById('inputUploadImage') as HTMLImageElement;
  const image = document.getElementById('inputImage') as HTMLImageElement;

  image.crossOrigin = 'anonymous';
  image.src = uploadImage.src;
  await new Promise((resolve, reject) => {
    image.onload = () => {
      resolve();
    };
  });

  return image;
}

export async function enableVideo() {
  const video = document.getElementById('inputVideo') as HTMLVideoElement;
  window.dispatchEvent(new CustomEvent('hasOnGoing'));
  video.width = video.width || 640;
  video.height = video.height || video.width * (3 / 4);

  const stream = await navigator.mediaDevices
    .getUserMedia({
      audio: false,
      video: {
        facingMode: 'user'
      }
    });
  video.srcObject = stream;
  await new Promise((resolve, reject) => {
    video.onloadedmetadata = () => {
      video.play();
      resolve();
    };
  });

  return video;
}

export function showInput(type: string) {
  window.dispatchEvent(new CustomEvent('showInput', { detail: { type } }));
}
