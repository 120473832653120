import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

declare var window: any;

export enum DatumImageType {
  Upload, URL, Canvas, Webcam
}
export interface Datum {
  imageType: DatumImageType;
  imageData?: any;
  imageURL?: string;
  label: string;
}
export interface Dataset {
  title: string;
  key: string;
  data: Datum[];
}

@Injectable({
  providedIn: 'root'
})
export class DatasetService {

  private _datasets: Dataset[] = [];
  private datasets = new BehaviorSubject(this._datasets);
  datasets$ = this.datasets.asObservable();

  constructor(
  ) {
    if (window.localStorage.getItem('CUSTOM_DATA_SETS')) {
      this._datasets = JSON.parse(window.localStorage.getItem('CUSTOM_DATA_SETS'));
      this.updateUniversalSet();
    }
  }

  importDataset(dataset: any) {
    dataset.title = 'Import - '+dataset.title;
    if (this._datasets.find(s => s.key == dataset.key) !== undefined) {
      dataset.key = uuidv4();
    }
    this._datasets.push(dataset);
    this.updateUniversalSet();
  }

  addDataset() {
    this._datasets.push({
      title: 'Untitled',
      key: uuidv4(),
      data: [],
    });
    this.updateUniversalSet();
  }

  updateDataset(datasetKey: string, newTitle: string, newKey: string, newData: Datum[]) {
    this._datasets.find(s => s.key == datasetKey).data = newData;
    this._datasets.find(s => s.key == datasetKey).title = newTitle;
    this._datasets.find(s => s.key == datasetKey).key = newKey;
    this.updateUniversalSet();
  }

  removeDataset(datasetKey: string) {
    this._datasets = this._datasets.filter(s => s.key != datasetKey);
    this.updateUniversalSet();
  }

  private updateUniversalSet() {
    this.datasets.next(this._datasets);
    window.customDatasets = this._datasets;
    try {
      window.localStorage.setItem('CUSTOM_DATA_SETS', JSON.stringify(this._datasets));
    } catch (e) {
      throw e;
    }
  }

  // For testing the error dialog
    /*private updateUniversalSet() {
    for (let i = 0, data = 'm'; i < 40; i++) {
      try {
        window.localStorage.setItem('TEST', data);
        data += data;
      } catch (e) {
        console.log(`LIMIT: ${i}, ${JSON.stringify(window.localStorage).length/1024/2}`);
        throw e;
      }
    }
  }*/
}
