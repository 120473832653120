import * as Blockly from 'blockly/core';
import { CustomBlock } from '../custom-block';

export class CreateObjectItemBlock implements CustomBlock {
  type = 'object_create_object_item';
  
  defineBlock(block) {
    let blockJson = {
      style: 'list_blocks',
      message0: 'key %1 value pair',
      args0: [
        {
          type: 'field_input',
          name: 'KEY',
          text: 'key',
        }
      ],
      previousStatement: true,
      nextStatement: true,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    return '';
  }
}
