import * as Blockly from 'blockly/core';
import { CustomBlock } from '../custom-block';
import outdent from 'outdent';

export class CreateObjectBlock implements CustomBlock {
  type = 'object_create_object';

  defineBlock(block) {
    const mixin = {
      mutationToDom() {
        const container = document.createElement('mutation');
        container.setAttribute('items', this.itemCount_);
        container.setAttribute('keys', JSON.stringify(this.keys_));
        return container;
      },

      domToMutation(xml) {
        this.itemCount_ = parseInt(xml.getAttribute('items'), 10);
        this.keys_ = JSON.parse(xml.getAttribute('keys') === 'undefined' ? '[]' : xml.getAttribute('keys'));
        this.updateShape_();
      },

      compose(topBlock) {
        let itemBlock = topBlock.getInputTargetBlock('STACK');
        const connections = [];
        this.keys_ = [];
        while (itemBlock) {
          connections.push(itemBlock.valueConnection_);
          this.keys_.push(itemBlock.inputList[0].fieldRow[1].value_);
          itemBlock = itemBlock.nextConnection && itemBlock.nextConnection.targetBlock();
        }
        for (let i = 0; i < this.itemCount_; i++) {
          const connection = this.getInput('ADD' + i).connection.targetConnection;
          if (connection && connections.indexOf(connection) === -1) {
            connection.disconnect();
          }
        }
        this.itemCount_ = connections.length;
        this.updateShape_();
        for (let i = 0; i < this.itemCount_; i++) {
          Blockly.Mutator.reconnect(connections[i], this, 'ADD' + i);
        }
      },

      decompose(workspace) {
        const topBlock = workspace.newBlock('object_create_object_base');
        topBlock.initSvg();
        let connection = topBlock.getInput('STACK').connection;
        for (let i = 0; i < this.itemCount_; i++) {
          const itemBlock = workspace.newBlock('object_create_object_item');
          itemBlock.setFieldValue(this.keys_[i], 'KEY');
          itemBlock.initSvg();
          connection.connect(itemBlock.previousConnection);
          connection = itemBlock.nextConnection;
        }
        return topBlock;
      },

      saveConnections(topBlock) {
        let itemBlock = topBlock.getInputTargetBlock('STACK');
        let i = 0;
        while (itemBlock) {
          const input = this.getInput('ADD' + i);
          itemBlock.valueConnection_ = input && input.connection.targetConnection;
          i++;
          itemBlock = itemBlock.nextConnection && itemBlock.nextConnection.targetBlock();
        }
      },

      updateShape_() {
        if (this.itemCount_ && this.getInput('EMPTY')) {
          this.removeInput('EMPTY');
        } else if (!this.itemCount_ && !this.getInput('EMPTY')) {
          this.appendDummyInput('EMPTY').appendField(Blockly.Msg.OBJECT_CREATE_EMPTY_OBJECT);
        }
        let i;
        for (i = 0; i < this.itemCount_; i++) {
          if (!this.getInput('ADD' + i)) {
            const input = this.appendValueInput('ADD' + i);
            if (i === 0 && this.keys_) {
              input.appendField(Blockly.Msg.OBJECT_CREATE_OBJECT_WITH).appendField(this.keys_[i], 'key');
            } else if (i > 0 && this.keys_) {
              input.appendField(this.keys_[i], 'key').setAlign(Blockly.ALIGN_RIGHT);
            }
          } else {
            this.getInput('ADD' + i).removeField('key');
            this.getInput('ADD' + i).appendField(this.keys_[i], 'key').setAlign(Blockly.ALIGN_RIGHT);
          }
        }
        while (this.getInput('ADD' + i)) {
          this.removeInput('ADD' + i);
          i++;
        }
      }
    };

    if (!('object_create_object_mutator' in Blockly.Extensions.ALL_)) {
      Blockly.Extensions.registerMutator('object_create_object_mutator', mixin, null,
        ['object_create_object_item']);
    }

    const blockJson = {
      style: 'list_blocks',
      mutator: 'object_create_object_mutator',
      output: 'Object',
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    let obj = `{`;
    for (let i = 0; i < block.itemCount_; i++) {
      obj += `'${block.keys_[i]}': ${Blockly.JavaScript.valueToCode(block, 'ADD' + i, Blockly.JavaScript.ORDER_NONE) || 'null'},`;
    }
    obj += `}`;
    return [obj, Blockly.JavaScript.ORDER_NONE];
  }
}
