import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class ActivateCanvasBlock implements CustomBlock {
  type = 'tfjs_activate_canvas';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_INTERACTION_CANVAS_TITLE}',
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    return outdent`ai.io.showInput('canvas');`;
  }
}
