export function log(message: string, level: string = 'LOG') {
  console.log(message);
  window.dispatchEvent(
    new CustomEvent('pushLog', { detail: { message, level } })
  );
}

export function logProbability(array: any[], labelKey: string, probKey: string) {
  window.dispatchEvent(
    new CustomEvent('pushLogProbability', { detail: { array, labelKey, probKey } })
  );
}
