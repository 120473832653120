import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly';
import { outdent } from 'outdent';

export class CreateSequentialModelBlock implements CustomBlock {
  type = 'tfjs_create_sequential_model';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_MODEL_CREATE_SEQUENTIAL_MODEL_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'MODEL',
          variable: 'model'
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const model = block.getField('MODEL').getText();
    return outdent`
    ${model} = tf.sequential();
    `;

  }
}
