import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';
import { outdent } from 'outdent';

export class TensorNormalBlock implements CustomBlock {
  type = 'tfjs_tensor_normal';
  
  defineBlock(block) {
    let blockJson = {
      style: 'tfjs_blocks',
      message0: '%{BKY_TFJS_TENSOR_NORMAL_MESSAGE_0}',
      args0: [
        {
          type: 'field_variable',
          name: 'NTENSOR',
          variable: 'normalizedTensor',
        },
      ],
      message1: '%{BKY_TFJS_TENSOR_NORMAL_MESSAGE_1}',
      args1: [
        {
          type: 'field_variable',
          name: 'TENSOR',
          variable: 'tensor'
        },
      ],
      message2: '%{BKY_TFJS_TENSOR_NORMAL_MESSAGE_2}',
      args2: [
        {
          type: 'input_value',
          name: 'MIN',
        },
      ],
      message3: '%{BKY_TFJS_TENSOR_NORMAL_MESSAGE_3}',
      args3: [
        {
          type: 'input_value',
          name: 'MAX',
        },
      ],
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block: Blockly.Block) {
    const ntensor = block.getField('NTENSOR').getText();
    const tensor = block.getField('TENSOR').getText();
    const min = Blockly.JavaScript.valueToCode(block, 'MIN', Blockly.JavaScript.ORDER_NONE) || '0';
    const max = Blockly.JavaScript.valueToCode(block, 'MAX', Blockly.JavaScript.ORDER_NONE) || '1';
    
    return `${ntensor} = ${tensor}.sub(${min}).div(${max}.sub(${min}));`;
  }
}
