import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';

export class PoseLoadModelBlock implements CustomBlock {
  type = 'pose_detection_load_model';

  defineBlock(block) {
    const blockJson = {
      style: 'pose_detection_blocks',
      message0: '%{BKY_POSE_DETECTION_LOAD_MODEL_TITLE}',
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    return `
    const model = await ai.pose.load();
    `;
  }
}
