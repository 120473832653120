import * as Blockly from 'blockly/core';
import { CustomBlock } from '../custom-block';
import outdent from 'outdent';

export class HandtrackXCenterBlock implements CustomBlock {
  type = 'handtrack_x_center';
  
  defineBlock(block) {
    const blockJson = {
      style: 'handtrack_blocks',
      message0: '%{BKY_HAND_DETECTION_X_CENTER_TITLE}',
      args0: [
        {
          type: 'field_variable',
          name: 'DETECTION',
          variable: 'aDetection',
        },
      ],
      output: 'Number',
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    const detection = block.getField('DETECTION').getVariable().name;
    const code = `(${detection}.bbox[0] + (${detection}.bbox[2] / 2))`;
    return [code, Blockly.JavaScript.ORDER_NONE];
  }
}
