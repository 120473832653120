import { CustomBlock } from '../custom-block';
import * as Blockly from 'blockly/core';

export class HandtrackLoadModelBlock implements CustomBlock {
  type = 'handtrack_load_model';

  defineBlock(block) {
    const blockJson = {
      style: 'handtrack_blocks',
      message0: '%{BKY_HAND_DETECTION_LOAD_MODEL_TITLE}',
      previousStatement: null,
      nextStatement: null,
    };

    block.jsonInit(blockJson);
  }

  toJavaScriptCode(block) {
    return `
    const model = await handTrack.load({
      maxNumBoxes: 20,
      scoreThreshold: 0.5,
    });
    `;
  }
}
