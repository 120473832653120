import * as Blockly from 'blockly/core';
import { CustomBlock } from '../custom-block';

import { FaceDetectionType } from './const';
import * as changecase from 'change-case';

export class DetectFaceBlock implements CustomBlock {
  type = 'faceapi_detect_face';

  blockJson = {
    style: 'faceapi_blocks',
    message0: '%{BKY_FACE_DETECTION_DETECT_FACE_TITLE}',
    args0: [
      {
        type: 'field_dropdown',
        name: 'DETECT_TYPE',
        options: [
          ['%{BKY_FACE_DETECTION_DETECT_FACE_ALL_FACES}', 'detectAllFaces'],
          ['%{BYK_FACE_DETECTION_DETECT_FACE_SINGLE_FACE}', 'detectSingleFace']
        ]
      },
      {
        type: 'field_variable',
        name: 'faceapiDetection',
        variable: 'faceapiDetection'
      },
    ],
    message1: '%1 %{BKY_FACE_DETECTION_FACE_LANDMARKS}',
    args1: [
      {
        type: 'field_checkbox',
        name: 'faceLandmarks',
      },
    ],
    message2: '%1 %{BKY_FACE_DETECTION_FACE_EXPRESSIONS}',
    args2: [
      {
        type: 'field_checkbox',
        name: 'faceExpressions',
      },
    ],
    message3: '%1 %{BKY_FACE_DETECTION_AGE_AND_GENDER}',
    args3: [
      {
        type: 'field_checkbox',
        name: 'ageAndGender',
      },
    ],
    previousStatement: null,
    nextStatement: null,
  };

  defineBlock(block) {
  }

  toJavaScriptCode(block) {
    const detectType = block.getFieldValue('DETECT_TYPE');
    let code = `${Blockly.JavaScript.variableDB_.getName(block.getFieldValue('faceapiDetection'), Blockly.Variables.NAME_TYPE)} = await faceapi.${detectType}(input, options)`;
    code = FaceDetectionType.filter(fieldName =>
      block.getFieldValue(fieldName) === 'TRUE'
    ).map(fieldName => {
      let method = 'with' + changecase.pascalCase(fieldName);
      if (fieldName === 'faceDescriptor' && detectType === 'detectAllFaces') {
        method += 's';
      }
      return method;
    }).reduce(
      (code, methodName) => code + `\n  .${methodName}()`,
      code
    );
    code += ';\n\n';
    return code;
  }
}
