import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { CustomBlock } from '../../block';
import ResizeObserver from 'resize-observer-polyfill';

import * as Blockly from 'blockly/core';
import { BlocklyOptions, WorkspaceSvg } from 'blockly';
import 'blockly/blocks';
import 'blockly/javascript';


import { Subscription } from 'rxjs';
import { LocaleService } from 'src/app/shared/locale/locale.service';
import { BlocklyLocale } from 'src/app/block/locales';

@Component({
  selector: 'blockly-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss']
})
export class WorkspaceComponent implements OnInit, AfterViewInit {
  @Input() customBlocks: CustomBlock[] = [];
  @Input() options: BlocklyOptions = {};
  @Output() codeUpdate = new EventEmitter();

  workspace: WorkspaceSvg;
  subscription: Subscription[] = [];
  locales = new BlocklyLocale();
  blocklyTreeRowObserver: MutationObserver;

  constructor(
    private localeService: LocaleService
  ) { }

  ngOnInit() {
    this.customBlocks.map(block => {
      Blockly.Blocks[block.type] = {
        init() {
          if (block.blockJson !== undefined) {
            this.jsonInit(block.blockJson);
          }

          block.defineBlock(this);
        }
      };
      Blockly.JavaScript[block.type] = function () {
        return block.toJavaScriptCode(this);
      };
    });

    const resizeObserver = new ResizeObserver(() => Blockly.svgResize(this.workspace));
    resizeObserver.observe(document.getElementById('blockly'));

    this.subscription.push(
      this.localeService.locale$.subscribe(locale => {
        Blockly.setLocale(this.locales.blocklyLocales[locale]);
        this.workspace?.updateToolbox(this.options.toolbox);
        this.updateObserver();
      }),
    );
  }

  updateBlock(type) {
    const block = this.customBlocks.find(customBlock => customBlock.type === type);
    if (block) {
      Blockly.Blocks[block.type] = {
        init() {
          if (block.blockJson !== undefined) {
            this.jsonInit(block.blockJson);
          }

          block.defineBlock(this);
        }
      };
      Blockly.JavaScript[block.type] = function () {
        return block.toJavaScriptCode(this);
      };
      const xml = Blockly.Xml.workspaceToDom(this.workspace);
      this.workspace.clear();
      Blockly.Xml.domToWorkspace(xml, this.workspace);
    }
  }

  updateBlocklyToolboxStyle() {
    document.querySelectorAll('.blocklyTreeRoot > div > :last-child > div:last-child > .blocklyTreeRow').forEach((row: HTMLElement) => {
      row.style['background-color'] = row.style['border-left-color'];
    })
    document.querySelectorAll('.blocklyTreeRoot > div > :last-child > div:nth-last-child(2) > .blocklyTreeRow').forEach((row: HTMLElement) => {
      row.style['background-color'] = row.style['border-left-color'];
    })
  }

  ngAfterViewInit(): void {
    this.workspace = Blockly.inject('blockly', this.options);
    this.workspace.addChangeListener(event => {
      const code = Blockly.JavaScript.workspaceToCode(this.workspace);
      this.codeUpdate.emit(code);
    });
    this.blocklyTreeRowObserver = new MutationObserver(mutationList => {
      this.updateBlocklyToolboxStyle();
    });
    this.updateObserver();
  }

  updateObserver() {
    if (!this.blocklyTreeRowObserver) return;

    this.blocklyTreeRowObserver.disconnect();
    this.blocklyTreeRowObserver.observe(document.querySelector('.blocklyTreeRoot > div > :last-child'), { attributes: true, attributeFilter: ['style'], subtree: true });
    this.updateBlocklyToolboxStyle();
  }
}
